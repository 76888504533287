import React from "react";
import { Col, Row } from "react-bootstrap";
import IconSvg from "../../../../AppMenu/Components/IconSvg";

const PdfFooter = ({ children, pageNumber }) => {
  return (
    <Row className={"pdf-header-footer-text justify-content-center"} style={{ height: "25px" }}>
      <Col>
        <IconSvg icon={"tp-logo-blue"} height={25} width={25}></IconSvg>
      </Col>
      <Col className={"align-content-center text-center"}>
        <Row className={"justify-content-center"} style={{ fontSize: "14px" }}>
          {pageNumber}
        </Row>
      </Col>
      <Col className="pdf-copyright-text">
        <Row className={"justify-content-end"}>© Talent-Turk 2024. All rights reserved</Row>
      </Col>
    </Row>
  );
};
export default PdfFooter;