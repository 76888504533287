import React, { useEffect } from "react";

import TopNavigation from "./TopNavigation";
import MenuButton from "./MenuButton";
import { useDispatch, useSelector } from "react-redux";
import ApiHelper from "../../../Helpers/ApiHelper";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import IconSvg from "./IconSvg";
import Dropdown from "react-bootstrap/Dropdown";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { SetScreenSizeCssClass, SetSelectedPage, SetSquadModalIsShown } from "../Store";
import SquadModal from "./SquadModal";
// TODO: translate these to english

export default function AppMenu(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedPage = useSelector((state) => state.appMenu.selectedPage);
  const pagesArray = useSelector((state) => state.appMenu.pages);
  const user = useSelector((state) => state.auth.user);
  const windowSizeCssClass = useSelector((state) => state.appMenu.screenSize);
  const squads = useSelector((state) => state.appMenu.squads);

  const companyName = user.companyUser?.company?.companyName;
  const companyLogo = user.companyUser?.company?.logoPath;
  const companyIcon = user.companyUser?.company?.iconPath;

  useEffect(() => {
    let landingPageUrl = "";

    if (user.userRole === "User") landingPageUrl = "/userdash";
    if (user.userRole === "Admin") landingPageUrl = "/dash";
    if (user.userRole === "SuperAdmin") landingPageUrl = "/sdash";

    dispatch(SetSelectedPage({ url: landingPageUrl }));
  }, [user.userRole]);

  let HandleLogOut = () => {
    localStorage.clear();
    //todo remove reduxUser setAuth(null);
    window.location.href = window.location.origin;
  };

  let handleLanguageChange = (lang) => {
    i18next.changeLanguage(lang);
  };

  const HandleSideMenuButtonClick = (page) => {
    dispatch(SetSelectedPage({ url: page.url }));
  };

  return (
    <>
      <SquadModal></SquadModal>
      <div className="top-bar">
        <TopNavigation pageName={selectedPage} userName={props.userName} />
      </div>

      <div className={`side-bar ${windowSizeCssClass} d-flex flex-column`}>
        <div className={"side-bar-menu position-relative"}>
          <div
            className={"position-absolute  text-color-gray-dark"}
            style={{ top: "8px", right: `-${windowSizeCssClass === "small-screen" ? "20" : "10"}px` }}
          >
            {windowSizeCssClass === "large-screen" ? (
              <div
                style={{ height: "30px", width: "30px" }}
                className={"flex-center rounded-circle hover-bg-darken"}
                onClick={() => {
                  dispatch(SetScreenSizeCssClass("small-screen"));
                }}
              >
                <IconSvg icon={"carousel-prev"} fill={"#303030"}></IconSvg>
              </div>
            ) : (
              <div
                style={{ height: "30px", width: "30px" }}
                className={"flex-center rounded-circle hover-bg-lighten"}
                onClick={() => {
                  dispatch(SetScreenSizeCssClass("large-screen"));
                }}
              >
                <IconSvg icon={"carousel-next"} fill={"#303030"}></IconSvg>
              </div>
            )}
          </div>
          <div className={`side-bar-company-icon ${windowSizeCssClass} justify-content-center`}>
            {companyIcon && (
              <img
                height="50"
                alt={"Company Icon"}
                src={ApiHelper.baseURL + "wwwroot/logo/" + companyIcon}
                width="50"
              />
            )}
          </div>
          <div className={`side-bar-company-logo ${windowSizeCssClass} justify-content-center`}>
            {companyLogo && (
              <img
                height="60"
                alt={"Company Logo"}
                src={ApiHelper.baseURL + "wwwroot/logo/" + companyLogo}
                width="200"
              />
            )}
          </div>
          {windowSizeCssClass === "large-screen" && (
            <div className={"text-14 fw-medium text-color-gray-light mb-3 side-bar-non-menu-content"}>
              <Row>
                <Col xs={"auto"}>ANA MENÜ</Col>
              </Row>
            </div>
          )}

          <div>
            {pagesArray.map((page, i) => {
              if (page.userRole === user.userRole)
                return (
                  <MenuButton
                    key={i + "page"}
                    page={page}
                    selected={selectedPage}
                    HandleSideMenuButtonClick={HandleSideMenuButtonClick}
                  />
                );
            })}
          </div>
          {windowSizeCssClass === "large-screen" && user.userRole === "Admin" && (
            <div
              className={
                "text-14 fw-medium text-color-gray-light mt-5 side-bar-non-menu-content squads hover-bg-darken"
              }
              onClick={() => {
                dispatch(SetSquadModalIsShown(true));
              }}
            >
              <Row className={"mb-2"}>
                <Col xs={"auto"}>EKİPLER</Col>
              </Row>

              {/*TODO: get these dynamically*/}

              {squads.map((squad, i) => {
                return (
                  <Row key={i} className={"text-16 text-color-gray-dark "}>
                    <Col xs={"auto"}>
                      <IconSvg icon={"succes-dot"} fill={squad.squadColor}></IconSvg>
                    </Col>
                    <Col xs={"auto"} className={"px-0"}>
                      {squad.squadName}
                    </Col>
                  </Row>
                );
              })}
            </div>
          )}
        </div>
        <div
          className={`mt-auto mb-5 text-20 text-color-gray-light fw-medium side-bar-non-menu-content ${windowSizeCssClass}`}
        >
          <Dropdown drop={"up-centered"}>
            <Dropdown.Toggle as={"div"} className={"dropdown-toggle no-caret"}>
              <Row className={"hover-bg-darken mb-4"}>
                <Col xs={"auto"}>
                  <IconSvg icon={"settings"}></IconSvg>
                </Col>
                {windowSizeCssClass === "large-screen" && (
                  <Col xs={"auto"} className={"ps-0"}>
                    <div>Ayarlar</div>
                  </Col>
                )}
              </Row>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item style={{ backgroundColor: i18next.language === "tr" ? "#dcdcdc" : "" }}>
                <Row onClick={() => handleLanguageChange("tr")}>
                  <Col>{t("turkish")}</Col>
                  <Col xs={3}>
                    <IconSvg icon="flag-tr" height={24} width={24} />
                  </Col>
                </Row>
              </Dropdown.Item>

              <Dropdown.Item style={{ backgroundColor: i18next.language === "en" ? "#dcdcdc" : "" }}>
                <Row onClick={() => handleLanguageChange("en")}>
                  <Col>{t("english")}</Col>
                  <Col xs={3}>
                    <IconSvg icon="flag-uk" height={24} width={24} />
                  </Col>
                </Row>
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item>
                <Row onClick={HandleLogOut}>
                  <Col>{t("logout")}</Col>
                  <Col xs={3}>
                    <IconSvg icon="on-off" height={24} width={24} />
                  </Col>
                </Row>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Row className={"hover-bg-darken"} onClick={HandleLogOut}>
            <Col xs={"auto"}>
              <IconSvg icon={"exit"}></IconSvg>
            </Col>
            {windowSizeCssClass === "large-screen" && (
              <Col xs={"auto"} className={"ps-0"}>
                <div>Çıkış</div>
              </Col>
            )}
          </Row>
        </div>
      </div>
    </>
  );
}
