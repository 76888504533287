import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  createBasicGraphDetail,
  deleteBasicGraphDetail,
  getBasicGraphDetails,
  updateBasicGraphDetail,
} from "../Store/Thunk";

import { setIsDual } from "../../../ScoringScale/Store";
import ReportItem from "../../ReportItem/Components/ReportItem";
import { updateBasicGraphDetailState } from "../Store";
import { Col, Row } from "react-bootstrap";
import WysiwygEditor from "../../../../../../UI/WysiwygEditor";

export default function BasicGraphDetail() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const basicGraphDetailState = useSelector((state) => state.basicGraphDetail);

  useEffect(() => {
    dispatch(setIsDual(false));
    let isSub = state.subScope ? true : false;
    let scopeId = state.subScope ? state.subScope.id : state.scope.id;
    dispatch(getBasicGraphDetails({ id: scopeId, isSub }));
    dispatch(updateBasicGraphDetailState({ scopeId, isSub }));
  }, []);

  const CreatePostObj = (val) => {
    val.lowSideText = basicGraphDetailState.lowSideText;
    val.highSideText = basicGraphDetailState.highSideText;
    val.generalInformationText = basicGraphDetailState.generalInformationText;
    return val;
  };

  const CreateBasicGraphDetail = (val) => {
    dispatch(createBasicGraphDetail(CreatePostObj(val)));
  };

  const UpdateBasicGraphDetail = (val) => {
    dispatch(updateBasicGraphDetail(CreatePostObj(val)));
  };

  const UpdateRelatedField = (name, val) => {
    let dispatchObj = {};
    dispatchObj[name] = val;
    dispatch(updateBasicGraphDetailState(dispatchObj));
  };
  const UpdateBasicGraphStateFromOutside = (entity) => {
    let dispatchObj = {};
    dispatchObj.lowSideText_ = entity.lowSideText;
    dispatchObj.highSideText_ = entity.highSideText;
    dispatchObj.generalInformationText_ = entity.generalInformationText;
    dispatch(updateBasicGraphDetailState(dispatchObj));
  };

  const relatedComponent = (
    <Row>
      <Col xs="12" md="4">
        <h5> Sol ölçek açıklaması </h5>
        <WysiwygEditor
          editorValue={basicGraphDetailState.lowSideText_}
          onChange={(html) => UpdateRelatedField("lowSideText", html)}
        />
      </Col>
      <Col xs="12" md="4">
        <h5> Açıklama </h5>

        <WysiwygEditor
          editorValue={basicGraphDetailState.generalInformationText_}
          onChange={(html) => UpdateRelatedField("generalInformationText", html)}
        />
      </Col>
      <Col xs="12" md="4">
        <h5> Sağ ölçek açıklaması </h5>

        <WysiwygEditor
          onChange={(html) => UpdateRelatedField("highSideText", html)}
          editorValue={basicGraphDetailState.highSideText_}
        />
      </Col>
    </Row>
  );
  const CleanEditors = () => {
    let dispatchObj = {};
    dispatchObj.lowSideText = "";
    dispatchObj.highSideText = "";
    dispatchObj.generalInformationText = "";
    dispatchObj.lowSideText_ = "";
    dispatchObj.highSideText_ = "";
    dispatchObj.generalInformationText_ = "";
    dispatch(updateBasicGraphDetailState(dispatchObj));
  };
  return (
    <div>
      <ReportItem
        keyword="Detail"
        state={state}
        pageName={t("createBasicGraphDetail")}
        CreateFunction={CreateBasicGraphDetail}
        UpdateFunction={UpdateBasicGraphDetail}
        DeleteFunction={deleteBasicGraphDetail}
        entityList={basicGraphDetailState.basicGraphDetailEntities}
        isLoading={basicGraphDetailState.isLoading}
        relatedComponent={relatedComponent}
        UpdateRelatedState={UpdateBasicGraphStateFromOutside}
        CleanIfNecessary={CleanEditors}
      />
    </div>
  );
}
