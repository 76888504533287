import React from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { UpdateCreditHistoryModalData, UpdateTotalCreditAmount } from "../Store";
import { useTranslation } from "react-i18next";
import Form from "react-bootstrap/Form";
import { changeCreditIsActive } from "../Store/Thunk";

export default function AssessmentList() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const creditsList = useSelector((state) => state.credits.creditsList);

  const HandleShowHistoryButton = (credit) => {
    dispatch(UpdateTotalCreditAmount(credit.creditAmount));
    dispatch(
      UpdateCreditHistoryModalData({
        creditId: credit.id,
        assessmentName: credit.assessmentName,
        isShown: true,
      })
    );
  };

  return (
    creditsList &&
    creditsList.map((credit, i) => (
      <Card className="mb-3" key={"assess" + i}>
        <Card.Body>
          <Row className={"justify-content-between"}>
            <Col xs="auto">
              <Row>
                <Col xs={"auto"}>{credit.id}</Col>
                <Col xs={"auto"}>{credit.assessmentName}</Col>
              </Row>
            </Col>
            <Col xs={"auto"}>
              <Row className="justify-content-md-end h-100">
                <Col xs={"auto"} className={"align-content-center"}>
                  <Form>
                    <Form.Check
                      type={"switch"}
                      onChange={() => {
                        dispatch(changeCreditIsActive({ creditId: credit.id, isActive: !credit.isActive }));
                      }}
                      checked={credit.isActive ? credit.isActive : false}
                    ></Form.Check>
                  </Form>
                </Col>
                <Col xs={"auto"} className={"align-content-center"}>
                  <Button size="sm" variant="success" onClick={() => HandleShowHistoryButton(credit)}>
                    {t("historyButtonLabel")}
                  </Button>
                </Col>
                <Col xs={"auto"} className={"align-content-center"}>
                  <Card style={{ width: "120px" }} className={"py-1 px-3 text-center"}>
                    {credit.creditAmount}
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    ))
  );
}
