import React from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { deleteCompanyUser } from "../Store/Thunk";
import { companyUserEnum, GetKeyByValue } from "../../../../../Helpers/EnumHelper";
import { FetchSelectedCompanyUserData, updateChangePasswordModalData } from "../Store";
import { useTranslation } from "react-i18next";

export default function CompanyUserList({ setSelectedCompanyUserName }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const companyUserList = useSelector((state) => state.companyUser.companyUserList);

  const HandleDeleteButton = (companyUserId, userId) => {
    dispatch(deleteCompanyUser({ companyUserId: companyUserId, userId: userId }));
  };

  const HandleUpdateButton = (companyUser) => {
    setSelectedCompanyUserName(companyUser.name);
    dispatch(FetchSelectedCompanyUserData({ companyUser: companyUser }));
  };

  const HandleChangePasswordButton = (email) => {
    dispatch(
      updateChangePasswordModalData({
        variableName: "isShown",
        value: true,
      })
    );
    dispatch(
      updateChangePasswordModalData({
        variableName: "email",
        value: email,
      })
    );
  };

  return (
    companyUserList &&
    companyUserList.map((companyUser, i) => (
      <Card className="mb-3" key={"assess" + i}>
        <Card.Body>
          <Row xs="auto" className={"justify-content-between"}>
            <Col xs={12} md={8} className={"align-content-center"}>
              <Row>
                <Col xs={6} md={1}>
                  {companyUser.id}
                </Col>
                <Col xs={6} md={true}>
                  {companyUser.name}
                </Col>
                <Col xs={6} md={true}>
                  {companyUser.email}
                </Col>
                <Col xs={6} md={true}>
                  {companyUser.phoneNumber}
                </Col>
                <Col xs={6} md={true}>
                  {GetKeyByValue(companyUser.companyUserTypeEnum, companyUserEnum)}
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={"auto"} className={"align-content-center"}>
              <Row className="justify-content-md-end">
                <Col>
                  <Button size="sm" variant="warning" onClick={() => HandleChangePasswordButton(companyUser.email)}>
                    {t("changePasswordButtonLabel")}
                  </Button>
                </Col>
                <Col xs={"auto"}>
                  <Button size="sm" variant="success" onClick={() => HandleUpdateButton(companyUser)}>
                    {t("updateButtonLabel")}
                  </Button>
                </Col>
                <Col xs={"auto"}>
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={() => HandleDeleteButton(companyUser.id, companyUser.applicationUserId)}
                  >
                    {t("deleteButtonLabel")}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    ))
  );
}
