import { createSlice } from "@reduxjs/toolkit";
import { dummyUrl } from "./Thunk";

const PAGESarray = [
  { pageName: "Dashboard", userRole: "Admin", url: "/dash" },
  // { pageName: "Projects", userRole: "Admin", url: "/projects" },
  // { pageName: "Results", userRole: "Admin", url: "/results" },
  // { pageName: "Settings", userRole: "Admin", url: "/settings" },
  { pageName: "Uygulamalar", userRole: "User", url: "/userdash" },
  { pageName: "Results", userRole: "User", url: "/myresults" },
  { pageName: "Dashboard", userRole: "SuperAdmin", url: "/sdash" },
  { pageName: "Assessment", userRole: "SuperAdmin", url: "/assessment/" },
  { pageName: "Inventory", userRole: "SuperAdmin", url: "/inventory" },
  { pageName: "Test", userRole: "SuperAdmin", url: "/test" },
  { pageName: "Survey", userRole: "SuperAdmin", url: "/Survey/" },
  { pageName: "Results", userRole: "SuperAdmin", url: "/ApplicationResults/" },
  { pageName: "Company", userRole: "SuperAdmin", url: "/Company/" },
  { pageName: "Position", userRole: "Admin", url: "/createPosition/" },
  { pageName: "Candidate", userRole: "Admin", url: "/createCandidate/" },
  { pageName: "Project", userRole: "Admin", url: "/project/" },
  { pageName: "Company", userRole: "Admin", url: "/adminCompanyInfo/" },
];

export const appMenu = createSlice({
  name: "appMenu",
  initialState: {
    id: "",
    pages: PAGESarray,
    selectedPage: {},
    screenSize: "large-screen",

    squads: [
      {
        squadColor: "#9155fd",
        squadName: "ekip 1",
        admins: [{ adminName: "ahmet" }, { adminName: "mehmet" }, { adminName: "ali" }],
      },
      {
        squadColor: "#56ca00",
        squadName: "ekip 2",
        admins: [{ adminName: "ayşe" }, { adminName: "melisa" }, { adminName: "dilara" }],
      },
    ],
    squadsModalIsShown: false,

    isLoading: false,
  },
  reducers: {
    SetSelectedPage(state, action) {
      const selectedPage = PAGESarray.find((page) => page.url === action.payload.url) || null;
      state.selectedPage = selectedPage;
      window.location.href = window.location.origin + "/#" + selectedPage.url;
    },
    SetScreenSizeCssClass(state, action) {
      state.screenSize = action.payload;
    },

    SetSquadModalIsShown(state, action) {
      state.squadModalIsShown = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(dummyUrl.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(dummyUrl.fulfilled, (state, action) => {
      state.isLoading = false;
    });
  },
});

export const { SetSelectedPage, SetScreenSizeCssClass, SetSquadModalIsShown } = appMenu.actions;

export default appMenu.reducer;
