import React from "react";

const DottedLineUi = ({ type, chainAmount }) => {
  const generateDots = () => {
    const dots = [];
    for (let i = 0; i < chainAmount; i++) {
      // For primary type
      if (type === "primary") {
        dots.push(
          <React.Fragment key={i}>
            {i === 0 && (
              <>
                <div
                  className={"bg-color-accent px-0 rounded-bottom-pill"}
                  style={{ height: "6px", width: "4px" }}
                ></div>
                <div className={"bg-white px-0"} style={{ height: "3px", width: "4px" }}></div>
              </>
            )}
            {i !== 0 && i !== chainAmount - 1 && (
              <>
                <div className={"bg-color-accent px-0 rounded-pill"} style={{ height: "6px", width: "4px" }}></div>
                <div className={"bg-white px-0"} style={{ height: "3px", width: "4px" }}></div>
              </>
            )}
            {i === chainAmount - 1 && (
              <div className={"bg-color-accent px-0 rounded-top-pill"} style={{ height: "6px", width: "4px" }}></div>
            )}
          </React.Fragment>
        );
      }

      // For secondary type
      if (type === "secondary") {
        dots.push(
          <React.Fragment key={i}>
            {i === 0 && (
              <>
                <div
                  className={"bg-color-gray-light px-0 rounded-bottom-pill"}
                  style={{ height: "3px", width: "3px" }}
                ></div>
                <div className={"bg-white px-0"} style={{ height: "8px", width: "4px" }}></div>
              </>
            )}
            {i !== 0 && i !== chainAmount - 1 && (
              <>
                <div className={"bg-color-gray-light px-0 rounded-pill"} style={{ height: "3px", width: "3px" }}></div>
                <div className={"bg-white px-0"} style={{ height: "8px", width: "4px" }}></div>
              </>
            )}
            {i === chainAmount - 1 && (
              <div
                className={"bg-color-gray-light px-0 rounded-top-pill"}
                style={{ height: "3px", width: "3px" }}
              ></div>
            )}
          </React.Fragment>
        );
      }
    }
    return dots;
  };

  return <div className={"p-0 m-0"}>{generateDots()}</div>;
};

export default DottedLineUi;
