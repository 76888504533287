export const AppMenuApiUrls = {
  dummyUrl: "appMenu/dummyUrl",
};

export default class appMenuAPI {
  constructor(httpsClient, cache) {
    this.httpsClient = httpsClient;
    this.cache = cache;
  }

  async get(url, data, token, eAlert, sAlert) {
    return await this.httpsClient.get(url, data, token, eAlert, sAlert);
  }

  async post(url, data, token, eAlert, sAlert) {
    this.cache.remove("tests");

    return await this.httpsClient.post(url, data, token, eAlert, sAlert);
  }
}
