import { createSlice } from "@reduxjs/toolkit";
import {
  changeCreditIsActive,
  getB2bAssessments,
  getCreditsByCompanyId,
  getUploadRecordsByCreditId,
  upsertCredit,
} from "./Thunk";

export const credits = createSlice({
  name: "credits",
  initialState: {
    id: "",
    name: "",
    assessmentList: [],
    creditsList: [],
    companyId: "",
    uploadRecords: [],
    creditHistoryModalData: { creditId: null, assessmentName: "", isShown: false },
    totalCreditAmount: 0,
    isLoading: false,
  },
  reducers: {
    UpdateCreditHistoryModalData(state, action) {
      state.creditHistoryModalData = {
        creditId: action.payload.creditId,
        assessmentName: action.payload.assessmentName,
        isShown: action.payload.isShown,
      };
    },
    UpdateCompanyId(state, action) {
      state.companyId = action.payload.companyId;
    },
    UpdateTotalCreditAmount(state, action) {
      state.totalCreditAmount = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(upsertCredit.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(upsertCredit.fulfilled, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(getB2bAssessments.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getB2bAssessments.fulfilled, (state, action) => {
      state.assessmentList = action.payload;
      state.isLoading = false;
    });

    builder.addCase(getUploadRecordsByCreditId.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getUploadRecordsByCreditId.fulfilled, (state, action) => {
      state.uploadRecords = action.payload;
      state.isLoading = false;
    });

    builder.addCase(getCreditsByCompanyId.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getCreditsByCompanyId.fulfilled, (state, action) => {
      state.creditsList = action.payload;
      state.isLoading = false;
    });

    builder.addCase(changeCreditIsActive.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(changeCreditIsActive.fulfilled, (state, action) => {
      state.isLoading = false;
    });
  },
});

export const { UpdateCreditHistoryModalData, UpdateCompanyId, UpdateTotalCreditAmount } = credits.actions;

export default credits.reducer;
