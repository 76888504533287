import React from "react";
import { Col, Row } from "react-bootstrap";

export default function Detail_4({ item, isSub }) {
  let elementScore = item.normScore ? Number(item.normScore) : item.score;
  let hitRange = item.scale.ranges.find((range) => range.isHit === true);

  return (
    <Row className={"mb-3 mx-0"} style={{ marginBottom: "6px" }}>
      {!isSub && (
        <Row className={"justify-content-center jakarta-sans"} style={{ fontSize: 22, fontWeight: 800 }}>
          {item.scopeName}
        </Row>
      )}

      <div className={"py-3 rounded-4"} style={{ backgroundColor: isSub ? "white" : "#0048aa" }}>
        {isSub && (
          <Row className={"justify-content-center detail-scope-name-blurred-smaller-wrap-up mb-1"}>
            {item.scopeName}
          </Row>
        )}

        <Row className={"justify-content-between"}>
          <Col xs={4} className={"_scale-detail-text-new-wrap-up"}>
            <div style={{ color: !isSub ? "white" : "" }} dangerouslySetInnerHTML={{ __html: item.lowSideText }}></div>
          </Col>

          <Col xs={4} className={"px-3"}>
            <Row className={"justify-content-center"}>
              <Col xs={"auto"}>
                <Row className={"m-0 justify-content-center"}>
                  {item.scale.isHtml ? (
                    <div
                      className="z-1 _flex-center"
                      style={{
                        backgroundColor: isSub ? "#007aff" : "#fff5ce",
                        color: isSub ? "white" : "#00428a",
                      }}
                      dangerouslySetInnerHTML={{ __html: item.monoSpaceHtml }}
                    />
                  ) : (
                    <div
                      className={"z-1 detail-hit-range-name-above-wrap-up px-0"}
                      style={{
                        backgroundColor: isSub ? "#007aff" : "#fff5ce",
                        color: isSub ? "white" : "#00428a",
                      }}
                    >
                      {hitRange?.text}
                    </div>
                  )}
                  <div className={"px-0 position-relative justify-content-center"} style={{ height: 9, width: 15 }}>
                    <div
                      className={"z-0 rotated-div-cube position-absolute"}
                      style={{ top: -8, height: 15, width: 15, backgroundColor: isSub ? "#007aff" : "#fff5ce" }}
                    ></div>
                  </div>
                </Row>
              </Col>
            </Row>

            <Row
              className={"justify-content-between round-30px border border-2 px-2"}
              style={{ borderColor: "#A6D1FF" }}
            >
              {item.scale.ranges.map((range, i) => {
                if (range.isHit === true) {
                  return (
                    <Col key={i} xs={"auto"} className={"align-content-center"}>
                      <Row className={"hit-scale-style-variable-opacity-wrap-up-round-smaller _flex-center"}>
                        {elementScore / 10}
                      </Row>
                    </Col>
                  );
                } else {
                  return (
                    <Col key={i} xs={"auto"} className={"align-content-center p-0"}>
                      <div
                        style={{
                          backgroundColor: "white",
                          width: "fit-content",
                          height: "fit-content",
                          borderRadius: "50%",
                          padding: 0,
                        }}
                      >
                        <div
                          className={"scale-style-variable-opacity-wrap-up-round-smaller"}
                          style={{ opacity: (i + 1) * (1 / item.scale.ranges.length) }}
                        ></div>
                      </div>
                    </Col>
                  );
                }
              })}
            </Row>
          </Col>

          <Col xs={4} className={"_scale-detail-text-new-wrap-up"}>
            <div style={{ color: !isSub ? "white" : "" }} dangerouslySetInnerHTML={{ __html: item.highSideText }}></div>
          </Col>
        </Row>
      </div>
    </Row>
  );
}
