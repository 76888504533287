import { createSlice } from "@reduxjs/toolkit";
import { finishSession } from "./Thunk";

export const answeringApplication = createSlice({
  name: "answeringApplication",
  initialState: {
    isLoading: false,
    sessionId: null,
  },
  reducers: {
    SetSessionId(state, action) {
      state.sessionId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(finishSession.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(finishSession.fulfilled, (state, action) => {
      state.sessionId = null;
      state.isLoading = false;
    });
  },
});

export const { SetSessionId } = answeringApplication.actions;

export default answeringApplication.reducer;
