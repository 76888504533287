export const ProjectApiUrls = {
  getCompanyCreateProjectData: "project/GetCompanyCreateProjectData",
  getLastTenOrderRecords: "project/GetLastTenOrderRecords",
  createTickets: "ticket/createTickets",
};

export default class projectAPI {
  constructor(httpsClient, cache) {
    this.httpsClient = httpsClient;
    this.cache = cache;
  }

  async getCompanyCreateProjectData(data, token) {
    return this.get(ProjectApiUrls.getCompanyCreateProjectData, data, token);
  }

  async getLastTenOrderRecords(data, token) {
    return this.get(ProjectApiUrls.getLastTenOrderRecords, data, token);
  }

  async createTickets(data, token) {
    return this.post(ProjectApiUrls.createTickets, data, token);
  }

  async get(url, data, token, eAlert, sAlert) {
    return await this.httpsClient.get(url, data, token, eAlert, sAlert);
  }

  async post(url, data, token, eAlert, sAlert) {
    this.cache.remove("tests");

    return await this.httpsClient.post(url, data, token, eAlert, sAlert);
  }
}
