export const UserDashApiUrls = {
  getUserDashData: "ticket/GetUserDashboardData",
};

export default class UserDashAPI {
  constructor(httpsClient, cache) {
    this.httpsClient = httpsClient;
    this.cache = cache;
  }

  async getUserDashData(data, token) {
    return this.get(UserDashApiUrls.getUserDashData, data, token);
  }

  async get(url, data, token) {
    return await this.httpsClient.get(url, data, token);
  }

  async post(url, data, token) {
    return await this.httpsClient.post(url, data, token);
  }
}
