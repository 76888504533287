import Modal from "react-bootstrap/Modal";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { ParseDate } from "../../../../../../Helpers/DateHelper";
import { SetSelectedResult } from "../../../Store";
import ApiHelper from "../../../../../../Helpers/ApiHelper";
import ScrollableCard from "../../../../../SharedComponents/ScrollableCard";
import DottedLineUi from "./DottedLineUi";

const SessionsModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedResult = useSelector((state) => state.candidate.selectedResult);
  const windowSizeCssClass = useSelector((state) => state.appMenu.screenSize);
  const [imageArray, setImageArray] = useState([]);

  const isEndDateValid = (date) => {
    const endDate = new Date(date);
    return endDate.getFullYear() >= 1900;
  };

  useEffect(() => {
    if (selectedResult?.jsonJpgNames) {
      setImageArray(JSON.parse(selectedResult?.jsonJpgNames));
    } else {
      setImageArray([]);
    }
  }, [selectedResult]);

  return (
    <Modal
      size={"xl"}
      dialogClassName={`custom-modal-dialog ${windowSizeCssClass}`}
      contentClassName={"custom-modal-content"}
      show={selectedResult}
      onHide={() => {
        dispatch(SetSelectedResult(null));
      }}
    >
      <div className={"bordered"}>
        <Modal.Header closeButton> AssessmentSessions</Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={"auto"}>
              {selectedResult?.sessions?.map((session, i) => {
                const lastItem = i === selectedResult?.sessions?.length - 1;
                return (
                  <>
                    <Row key={i}>
                      <Col>
                        <Row>
                          <Col xs={"auto"}>
                            <div className={"rounded-circle bg-color-accent"} style={{ height: "24px", width: "24px" }}>
                              <Row className={"justify-content-center h-100"}>
                                <Col xs={"auto"} className={"align-content-center"}>
                                  <div
                                    className={"rounded-circle bg-white"}
                                    style={{ height: "18px", width: "18px" }}
                                  ></div>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                          <Col>
                            {ParseDate(session.startDate).date} {ParseDate(session.startDate).time}
                          </Col>
                        </Row>

                        {isEndDateValid(session.endDate) ? (
                          <>
                            <Row>
                              <Col xs={"auto"}>
                                <Row className={"mx-0 justify-content-center"} style={{ width: "24px" }}>
                                  <Col xs={"auto"} className={"px-0"}>
                                    <DottedLineUi type={"primary"} chainAmount={5} />
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={"auto"}>
                                <div
                                  className={"rounded-circle bg-color-accent"}
                                  style={{ height: "24px", width: "24px" }}
                                >
                                  <Row className={"justify-content-center h-100"}>
                                    <Col xs={"auto"} className={"align-content-center"}>
                                      <div
                                        className={"rounded-circle bg-white"}
                                        style={{ height: "18px", width: "18px" }}
                                      ></div>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                              <Col>
                                {ParseDate(session.endDate).date} {ParseDate(session.endDate).time}
                              </Col>
                            </Row>
                          </>
                        ) : (
                          <>
                            <Row>
                              <Col xs={"auto"}>
                                <Row className={"mx-0 justify-content-center"} style={{ width: "24px" }}>
                                  <Col xs={"auto"} className={"px-0"}>
                                    <DottedLineUi type={"primary"} chainAmount={5} />
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={"auto"}>
                                <div
                                  className={"rounded-circle bg-color-accent"}
                                  style={{ height: "24px", width: "24px" }}
                                >
                                  <Row className={"justify-content-center h-100"}>
                                    <Col xs={"auto"} className={"align-content-center"}>
                                      <div
                                        className={"rounded-circle bg-white"}
                                        style={{ height: "18px", width: "18px" }}
                                      ></div>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                              <Col>invalid enddate</Col>
                            </Row>
                          </>
                        )}
                      </Col>
                    </Row>
                    {!lastItem && (
                      <Row>
                        <Col xs={"auto"}>
                          <Row className={"mx-0 justify-content-center"} style={{ width: "24px" }}>
                            <Col xs={"auto"} className={"px-0"}>
                              <DottedLineUi type={"secondary"} chainAmount={5} />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    )}
                  </>
                );
              })}
            </Col>
            <Col>
              <ScrollableCard>
                <Row className={"h-100 mx-0 justify-content-center"}>
                  {imageArray.map((imageName, i) => (
                    <Col xs={"auto"} className={"p-0"} key={i}>
                      <img
                        className={"m-1"}
                        alt={"image " + i}
                        src={ApiHelper.baseURL + "wwwroot/candidate/" + imageName}
                      />
                    </Col>
                  ))}
                </Row>
              </ScrollableCard>
            </Col>
          </Row>
        </Modal.Body>
      </div>
    </Modal>
  );
};
export default SessionsModal;
