import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import ScrollableCard from "../../../../SharedComponents/ScrollableCard";
import IconSvg from "../../../../AppMenu/Components/IconSvg";

const ApplicationCardContent = ({ companyCredit }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.auth.user.companyUser.company.companyId);
  const companyCredits = useSelector((state) => state.adminDash.companyCredits);

  return (
    <>
      <Row className={"justify-content-center"}>
        <IconSvg icon={"packet"} />
      </Row>
      <Row className={"justify-content-center"}>
        <Col xs={"auto"} className={"text-24 text-color-gray-dark fw-medium"}>
          {" "}
          {companyCredit.assessment.name}
        </Col>
      </Row>
      <Row className={"justify-content-center"}>
        <Col xs={"auto"} className={"text-16 text-color-gray-light fw-medium"}>
          {companyCredit.creditAmount} Kredi
        </Col>
      </Row>
      <ScrollableCard emptySpaceHeight={25}>
        <Row className={"mx-0"}>
          {companyCredit.assessment.inventories.map((inventory, i) => {
            return (
              <Card key={`I${i}`} className={"assessment-row"}>
                <Row className={"justify-content-between m-0"}>
                  <Col xs={"auto"}>{inventory.name}</Col> <Col xs={"auto"}> </Col>
                </Row>
              </Card>
            );
          })}
        </Row>
        <Row className={"mx-0"}>
          {companyCredit.assessment.surveys.map((survey, i) => {
            return (
              <Card key={`S${i}`} className={"assessment-row"}>
                <Row className={"justify-content-between m-0"}>
                  <Col xs={"auto"}>{survey.name}</Col> <Col xs={"auto"}> </Col>
                </Row>
              </Card>
            );
          })}
        </Row>
        <Row className={"mx-0"}>
          {companyCredit.assessment.tests.map((test, i) => {
            return (
              <Card key={`T${i}`} className={"assessment-row"}>
                <Row className={"justify-content-between m-0"}>
                  <Col xs={"auto"}>{test.name}</Col> <Col xs={"auto"}> </Col>
                </Row>
              </Card>
            );
          })}
        </Row>
      </ScrollableCard>
    </>
  );
};
export default ApplicationCardContent;
