import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { UpdateCreditHistoryModalData } from "../Store";
import { getUploadRecordsByCreditId } from "../Store/Thunk";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import Table from "react-bootstrap/Table";
import ScrollableCard from "../../../../SharedComponents/ScrollableCard";
import { ParseDate } from "../../../../../Helpers/DateHelper";
import Row from "react-bootstrap/Row";

const CreditHistoryModal = ({ companyName }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const creditHistoryModalData = useSelector((state) => state.credits.creditHistoryModalData);
  const uploadRecords = useSelector((state) => state.credits.uploadRecords);
  const totalCreditAmount = useSelector((state) => state.credits.totalCreditAmount);

  useEffect(() => {
    if (creditHistoryModalData.creditId) {
      dispatch(getUploadRecordsByCreditId({ creditId: creditHistoryModalData?.creditId }));
    }
  }, [creditHistoryModalData.creditId]);

  const GetAssignedAndSpent = () => {
    let assigned = 0;
    uploadRecords.forEach((upload) => {
      assigned = assigned + upload.uploadedAmount;
    });
    const spent = assigned - totalCreditAmount;

    return { assigned: assigned, spent: spent };
  };

  const HandleModalOnHide = () => {
    dispatch(UpdateCreditHistoryModalData({ creditId: null, assessmentName: "", isShown: false }));
  };

  return (
    <Modal
      className={"modal-lg"}
      show={creditHistoryModalData.isShown && uploadRecords.length > 0}
      centered
      onHide={HandleModalOnHide}
    >
      <Modal.Header className={"justify-content-evenly"}>
        <Col xs={"auto"} className={"px-2"}>
          <b>
            <h3>{creditHistoryModalData.assessmentName}</h3>
          </b>
        </Col>
        <Col xs={"auto"} className={"px-2"}>
          <b>
            <h3>{companyName}</h3>
          </b>
        </Col>
      </Modal.Header>

      <Modal.Body>
        <ScrollableCard>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>{t("assignationDateTableHeader")}</th>
                <th>{t("adderTableHeader")}</th>
                <th>{t("prevAmountTableHeader")}</th>
                <th>{t("addedAmountTableHeader")}</th>
                <th>{t("newAmountTableHeader")}</th>
              </tr>
            </thead>
            <tbody>
              {uploadRecords.map((upload, index) => {
                const parsedDate = ParseDate(upload.createdAt);

                return (
                  <tr>
                    <td className={"px-4"}>
                      {parsedDate.date} {parsedDate.time}
                    </td>
                    <td>{upload.superAdminUserName}</td>
                    <td>{upload.prevAmount}</td>
                    <td className={upload.uploadedAmount > 0 ? "text-success" : "text-danger"}>
                      {upload.uploadedAmount > 0 && "+"}
                      {upload.uploadedAmount}
                    </td>
                    <td>{upload.uploadedAmount + upload.prevAmount}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </ScrollableCard>
      </Modal.Body>
      <Modal.Footer className={"justify-content-between"}>
        <Col xs={"auto"}>
          <Row className={"justify-content-between"}>
            <Col xs={"auto"}>
              {t("totalAssigned")}: {GetAssignedAndSpent().assigned}
            </Col>
            <Col xs={"auto"}>
              {t("totalSpent")}: {GetAssignedAndSpent().spent}
            </Col>
            <Col xs={"auto"}>
              {t("remaining")}: {totalCreditAmount}
            </Col>
          </Row>
        </Col>
        <Col xs={"auto"}>
          <Button onClick={HandleModalOnHide} variant={"danger"}>
            {t("closeButtonLabel")}
          </Button>
        </Col>
      </Modal.Footer>
    </Modal>
  );
};
export default CreditHistoryModal;
