import { useLocation, Navigate, Outlet } from "react-router-dom";
import { setUser } from "../Store/Auth/index";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

export const RequireAuth = ({ allowedRoles, checkLogin }) => {
  const dispatch = useDispatch();
  const { location } = useLocation();

  const userAtRedux = useSelector((state) => state.auth.user);
  let localAuthData = localStorage.cpdata && JSON.parse(localStorage.cpdata);

  if (!userAtRedux && localAuthData) {
    dispatch(setUser(localAuthData));
  }

  var user = userAtRedux?.userRole ? userAtRedux : localAuthData && localAuthData;

  if (checkLogin) {
    if (user != null && Object.keys(user).length !== 0) {
      let role = user.userRole;
      if (role === "SuperAdmin") return <Navigate to="/sdash" state={{ from: location }} replace />;
      if (role === "Admin") return <Navigate to="/dash" state={{ from: location }} replace />;
      if (role === "User") return <Navigate to="/userdash" state={{ from: location }} replace />;
    } else return <Outlet />;
  }

  let isIncludes = false;
  let userRole = user?.userRole;
  if (userRole) {
    for (let j = 0; j < allowedRoles.length; j++) {
      const allowedrole = allowedRoles[j];
      if (allowedrole === userRole) isIncludes = true;
    }
  }

  if (isIncludes) {
    return <Outlet />;
  } else {
    return <Navigate to="/" state={{ from: location }} replace />;
  }
};
