import React from "react";
import { Col, Row } from "react-bootstrap";

export default function Detail_1({ item, isSub }) {
  let elementScore = item.normScore ? Number(item.normScore) : item.score;

  return (
    <Row className={"mb-3 mx-0"}>
      <div>
        <h6
          className={
            isSub ? "detail-section-sub-scope-name-header-wrap-up" : "detail-section-scope-name-header-wrap-up"
          }
        >
          {item.name}
          <span className={isSub ? "detail-sub-scope-score-header-wrap-up" : "detail-main-scope-score-header-wrap-up"}>
            {elementScore}
          </span>
        </h6>
        <div className={isSub ? "detail-sub-scope-container-wrap-up" : "detail-scope-container-wrap-up"}>
          <Row>
            {item.scale.ranges.map((range, i) => {
              let isHit = range.isHit;
              return (
                <Col
                  key={i}
                  className={
                    isHit
                      ? isSub
                        ? "scale-style-wrap-up hit-sub-scale-wrap-up"
                        : "scale-style-wrap-up hit-scale-wrap-up"
                      : "scale-style-wrap-up"
                  }
                >
                  {item.scale.isHtml ? (
                    <div dangerouslySetInnerHTML={{ __html: item.monoSpaceHtml }} />
                  ) : (
                    range.start + range.text + range.end
                  )}
                </Col>
              );
            })}
          </Row>
          <Row>
            <Col xs="5">
              <div className="detail-scale-text-wrap-up" dangerouslySetInnerHTML={{ __html: item.lowSideText }} />
            </Col>
            <Col xs={{ offset: 2, span: 5 }}>
              <div className="detail-scale-text-wrap-up" dangerouslySetInnerHTML={{ __html: item.highSideText }} />
            </Col>
          </Row>
          {item.generalInformationText?.length > 2 && (
            <div
              className="detail-scale-general-info-text-wrap-up"
              dangerouslySetInnerHTML={{ __html: item.generalInformationText }}
            />
          )}
        </div>
      </div>
    </Row>
  );
}
