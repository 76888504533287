import React from "react";
import { Col, Row } from "react-bootstrap";
import IconSvg from "../../../../../AppMenu/Components/IconSvg";

export default function MonoText({ item }) {
  let hitRange = item.scale.ranges.find((range) => range.isHit === true);

  return (
    <Row className={(hitRange === undefined && "d-none") + " mx-1 mb-3"}>
      <div className={"round-30px p-2 "} style={{ backgroundColor: "#efefef" }}>
        <div className={"position-relative "}>
          <div className={"position-absolute"} style={{ top: -20, left: -4 }}>
            <IconSvg icon={"documentIcon"} height={30} width={30} />
          </div>

          <Row>
            <Col>
              <div className={"round-30px p-2"} style={{ backgroundColor: "#ffffff" }}>
                {item.scale.isHTML ? <div dangerouslySetInnerHTML={{ __html: hitRange.text }} /> : hitRange.text}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Row>
  );
}
