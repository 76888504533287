import React from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { designTypes } from "../../ReportItemBase/Components/DesignTypeDropdown";
import IconSvg from "../../../../../AppMenu/Components/IconSvg";
import { updateReportItemBase } from "../../ReportItemBase/Store";
import { setIsHtml, setScales } from "../../../ScoringScale/Store";

export default function ReportItemEntityList(props) {
  const dispatch = useDispatch();

  const GetKeyByValue = (value) => {
    return Object.keys(designTypes).find((key) => designTypes[key] === value);
  };
  const DeleteEntity = (id) => {
    dispatch(props.DeleteFunction({ id }));
  };

  const SeeDetailAndUpdate = (entity) => {
    dispatch(updateReportItemBase({ key: "name", value: entity.name }));
    dispatch(updateReportItemBase({ key: "designType", value: entity.designType }));
    dispatch(setScales(entity.scale.ranges));
    dispatch(setIsHtml(entity.scale.isHtml));
    dispatch(props.UpdateStateId({ id: entity.id }));
    if (props.UpdateRelatedState) props.UpdateRelatedState(entity);
  };

  return props?.entityList?.map((entity, i) => (
    <Card className="mb-3" key={i}>
      <Card.Body className={entity.id === props.id && "dashed-border"}>
        <Row>
          <Col>
            <h6>{entity.id}</h6>
          </Col>
          <Col>
            <h6>{entity.name}</h6>
          </Col>
          <Col>
            <h6>{GetKeyByValue(entity.designType)}</h6>
          </Col>
          <Col className="flex-center">
            <Button variant="success" onClick={() => SeeDetailAndUpdate(entity)}>
              Detay
            </Button>
          </Col>
          <Col>
            <Button style={{ float: "right" }} onClick={() => DeleteEntity(entity.id)} variant="danger">
              <IconSvg icon="trash-can" fill="#fff" />
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  ));
}
