import { createAsyncThunk } from "@reduxjs/toolkit";
import AppMenuAPI, { AppMenuApiUrls } from "../API/AppMenuAPI";

import LocalStorage from "../../../Services/Storage/LocalStorage";
import StorageModel from "../../../Models/StorageModel";
import HttpsClient from "../../../Services/HttpsClient";
import ApiHelper from "../../../Services/ApiHelper";
import Cache from "../../../Services/Cache";

const storage = new LocalStorage();
const storageModel = new StorageModel(storage);
const cache = new Cache(storageModel);

const httpsClient = new HttpsClient();
const apiHelper = new ApiHelper(httpsClient);
const appMenuAPI = new AppMenuAPI(apiHelper, cache);

export const dummyUrl = createAsyncThunk(AppMenuApiUrls.dummyUrl, async (payload, { getState, dispatch }) => {
  const state = getState();
  const token = state.auth.user.token;
  if (!payload) {
    payload = { companyId: state.companyInfo.companyId };
  }
  return await appMenuAPI.dummyUrl(payload, token);
});
