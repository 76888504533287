import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import IconSvg from "../../../../AppMenu/Components/IconSvg";
import { SetSelectedComment } from "../../Store";
import { ParseDate } from "../../../../../Helpers/DateHelper";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteComment } from "../../Store/Thunk";

const CommentListRow = ({ comment, lastItem }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  const HandleDeleteComment = (commentId) => {
    dispatch(deleteComment({ id: commentId }));
  };

  return (
    <div
      className={" mb-2 pb-2 hover-container"}
      style={{ borderBottom: `2px solid ${lastItem ? "transparent" : "#e7ecef"}` }}
    >
      <Row className={"justify-content-between mx-0"}>
        <Col xs={"auto"}>
          <Row>
            <Col xs={"auto"} className={"align-content-center"}>
              <IconSvg icon={"comment"}></IconSvg>
            </Col>
            <Col xs={"auto"} className={"px-0 align-content-center text-18 text-color-gray-light fw-medium"}>
              {comment.companyUserName}
            </Col>
            {comment.isEdited && (
              <Col xs={"auto"} className={"align-content-center text-10 text-color-gray-light fw-medium"}>
                (edited)
              </Col>
            )}
          </Row>
        </Col>
        <Col xs={"auto"}>
          <Row style={{ height: "32px" }}>
            {user.companyUser.companyUserId === comment.companyUserId && (
              <>
                <Col xs={"auto"} className={"hover-actions"}>
                  <div className={"hover-bg-darken px-2 py-1"} onClick={() => dispatch(SetSelectedComment(comment))}>
                    <IconSvg icon={"edit"}></IconSvg>
                  </div>
                </Col>

                <Col xs={"auto"} className={"hover-actions"}>
                  <div className={"hover-bg-darken px-2 py-1"} onClick={() => HandleDeleteComment(comment.commentId)}>
                    <IconSvg icon={"delete"}></IconSvg>
                  </div>
                </Col>
              </>
            )}
            <Col xs={"auto"} className={"text-16 text-color-gray-light fw-medium"}>
              {ParseDate(comment.createdAt).date + " "}
              {ParseDate(comment.createdAt).time}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className={"mx-0"}>
        <Col className={"text-16 text-color-gray-dark fw-medium"}>{comment.content}</Col>
      </Row>
    </div>
  );
};
export default CommentListRow;
