import React from "react";
import { useDispatch, useSelector } from "react-redux";
import InputGroup from "react-bootstrap/InputGroup";

// import { PdfDownloadButton } from "../../../../Components/Application/ApplicationResults/Pdf/PdfDownloadButton";
//todo pdf download button is need to refactor for new system
import { selectTests, uncheckTest } from "../../Store";
import { ExportToExcel } from "../ExportToExcel/ExportToExcel";

export default function DemoResultItem({ test }) {
  const dispatch = useDispatch();
  const selectedTestsId = useSelector((state) => state.applicationResults.selectedTestsId);

  const isSelected = selectedTestsId.find((id) => {
    return test.id === id;
  });

  const HandleDateFormat = (date) => {
    const dt = new Date(date);
    const str =
      dt.getDate() + "-" + (dt.getMonth() + 1) + "-" + dt.getFullYear() + " " + dt.getHours() + ":" + dt.getMinutes();
    if (str === "1-1-1 0:0") return false;
    return str;
  };

  let endDate = HandleDateFormat(test.endDate);

  let HandleTestConpletionTime = (start, end) => {
    return MillisToMinutesAndSeconds(Math.abs(new Date(end) - new Date(start)));
  };

  let MillisToMinutesAndSeconds = (millis) => {
    const minutes = Math.floor(millis / 60000);
    const seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
  };

  const handleSelect = (event) => {
    event.target.checked ? dispatch(selectTests([test.id])) : dispatch(uncheckTest(test.id));
  };

  return (
    <tr key={test.testId}>
      <td>
        <InputGroup.Checkbox checked={!!isSelected} className="test-result-item" onChange={handleSelect} />
      </td>
      <td style={{ color: endDate ? "green" : "red" }}>{test.id}</td>
      <td>{test.name}</td>
      <td>{test.email}</td>
      <td>{test.gender}</td>
      <td>{test.testId}</td>
      <td>{HandleDateFormat(test.startDate)}</td>
      <td>{endDate && endDate}</td>
      <td>{endDate && HandleTestConpletionTime(test.startDate, test.endDate)}</td>
      <td>
        <ExportToExcel isActive={endDate} result={test} />
      </td>
      <td>{/* <PdfDownloadButton isActive={endDate} result={test} /> */}</td>
    </tr>
  );
}
