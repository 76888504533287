import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TextSection from "../../../AnsweringApplication/SharedComponents/TextSection";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import validator from "validator";
import Alert from "react-bootstrap/Alert";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Overlay from "../../../SharedComponents/Overlay";
import { useLocation, useNavigate } from "react-router-dom";
import { getDemographyByCandidateId, updateDemography } from "../Store/Thunk";

export default function DemographyForm() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoading = useSelector((state) => state.selectedCandidateDemography.isLoading);
  const fetchedName = useSelector((state) => state.selectedCandidateDemography.fetchedName);
  const fetchedDemography = useSelector((state) => state.selectedCandidateDemography.fetchedDemography);

  const email = useSelector((state) => state.auth.user.email);
  const { state } = useLocation();

  useEffect(() => {
    dispatch(getDemographyByCandidateId({ candidateId: state.candidateId }));
  }, [state]);

  useEffect(() => {
    setInfo((i) => ({
      ...i,
      name: fetchedName,
    }));
  }, [fetchedName]);

  const [modal, setModal] = useState(false);
  const [check, setCheck] = useState(false);
  const [info, setInfo] = useState({
    email: email ? email : "",
    name: null,
    school: "",
    gender: "",
    birthYear: "",
    experience: "",
    kvkkConfirmed: false,
    faculty: "",
    department: "",
  });
  const [buttonClassName, setButtonClassName] = useState(null);

  const options = [];
  for (let i = 57; i > 0; i--) {
    let year = 1950 + i;
    options.push(
      <option value={year} key={year}>
        {year}
      </option>
    );
  }

  const submitDemographyForm = () => {
    const postObj = {
      id: fetchedDemography.id,
      ...info,
    };

    handleUpdate(postObj);
  };

  const handleUpdate = (postObj) => {
    dispatch(updateDemography(postObj)).then((result) => {
      if (result.meta.requestStatus === "fulfilled") {
        navigate("/userDash");
      }
    });
  };

  return (
    <>
      {isLoading && <Overlay />}
      <Row className={"mx-0"}>
        <Col>
          <TextSection text="Temel Bilgiler" />

          <Container mb={6} style={{ paddingTop: "192px", marginBottom: "150px" }}>
            <Card.Body style={{ padding: "20px" }} className="animate__animated animate__zoomIn">
              <Form>
                <Row className="mb-2">
                  <Col xs={{ span: 12, order: 1 }} md={{ span: 6, order: 1 }}>
                    <Form.Group className="mb-2">
                      <Form.Label className="candidate-info-input-label">E-Posta Adresi</Form.Label>
                      <Form.Control
                        className={(check && !info.email && "invalid") || (info.valid_email && "invalid")}
                        value={email && email}
                        readOnly={email}
                        disabled={true}
                        type="email"
                        placeholder="E-Posta adresinizi giriniz"
                        onChange={(e) =>
                          setInfo((state) => ({
                            ...state,
                            email: e.target.value,
                            valid_email: !validator.isEmail(e.target.value),
                          }))
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={{ span: 12, order: 3 }} md={{ span: 6, order: 2 }}>
                    <Form.Group>
                      <Form.Label className="candidate-info-input-label">Eğitim Durumu</Form.Label>
                      <Form.Select
                        className={check && !info.school && "invalid"}
                        onChange={(e) =>
                          setInfo((i) => ({
                            ...i,
                            school: e.target.value,
                          }))
                        }
                      >
                        <option key="blankChoice" hidden value="">
                          Lütfen seçim yapınız
                        </option>
                        <option>Ortaokul ve Öncesi</option>
                        <option>Lise</option>
                        <option>Ön Lisans</option>
                        <option>Lisans</option>
                        <option>Yüksek Lisans</option>
                        <option>Doktora</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  <Col xs={{ span: 12, order: 2 }} md={{ span: 6, order: 3 }}>
                    <Form.Group className="mb-2">
                      <Form.Label className="candidate-info-input-label">Ad - Soyad</Form.Label>
                      <Form.Control
                        className={check && !info.name ? "invalid candidate-info-input" : "candidate-info-input"}
                        type="text"
                        placeholder="Lütfen isminizi giriniz"
                        value={info.name}
                        onChange={(e) => {
                          setInfo((i) => ({
                            ...i,
                            name: e.target.value,
                          }));
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={{ span: 12, order: 4 }} md={{ span: 6, order: 4 }}>
                    <Form.Group>
                      <Form.Label className="candidate-info-input-label">Fakülte Bilgisi</Form.Label>
                      <Form.Select
                        onChange={(e) =>
                          setInfo((i) => ({
                            ...i,
                            faculty: e.target.value,
                          }))
                        }
                        className={check && !info.faculty && "invalid"}
                      >
                        <option key="blankChoice" hidden value>
                          Lütfen seçim yapınız
                        </option>
                        <option>İktisadi ve İdari Bilimler</option>
                        <option>Mühendislik</option>
                        <option>Fen-Edebiyat</option>
                        <option>Eğitim</option>
                        <option>İletişim</option>
                        <option>Hukuk</option>
                        <option>Tıp</option>
                        <option>Diğer</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mb-2">
                  <Col xs={12} md={6}>
                    <Form.Group className="mb-2">
                      <Form.Label className="candidate-info-input-label">Cinsiyet</Form.Label>
                      <Form.Select
                        onChange={(e) =>
                          setInfo((i) => ({
                            ...i,
                            gender: e.target.value,
                          }))
                        }
                        className={check && !info.gender && "invalid"}
                      >
                        <option key="blankChoice" hidden value>
                          Lütfen seçim yapınız
                        </option>
                        <option>Kadın</option>
                        <option>Erkek</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group>
                      <Form.Label className="candidate-info-input-label">Toplam İş Deneyimi</Form.Label>
                      <Form.Select
                        onChange={(e) =>
                          setInfo((i) => ({
                            ...i,
                            experience: e.target.value,
                          }))
                        }
                        className={check && !info.experience && "invalid"}
                      >
                        <option key="blankChoice" hidden value>
                          Lütfen seçim yapınız
                        </option>
                        <option>Yok</option>
                        <option>0-2 yıl</option>
                        <option>3-5 yıl</option>
                        <option>6-10 yıl</option>
                        <option>11-20 yıl</option>
                        <option>21+ yıl</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mb-2">
                  <Col xs={12} md={6} className="mb-2">
                    <Form.Group className="mb-2">
                      <Form.Label className="candidate-info-input-label">Doğum Yılı</Form.Label>
                      <Form.Select
                        onChange={(e) =>
                          setInfo((i) => ({
                            ...i,
                            birthYear: e.target.value,
                          }))
                        }
                        className={check && !info.birthYear && "invalid"}
                      >
                        <option key="blankChoice" hidden value>
                          Lütfen seçim yapınız
                        </option>
                        {options}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group>
                      <Form.Label className="candidate-info-input-label">Departman Bilgisi</Form.Label>
                      <Form.Select
                        onChange={(e) =>
                          setInfo((i) => ({
                            ...i,
                            department: e.target.value,
                          }))
                        }
                        className={check && !info.department && "invalid"}
                      >
                        <option key="blankChoice" hidden value>
                          Lütfen seçim yapınız
                        </option>
                        <option>Satış</option>
                        <option>Pazarlama</option>
                        <option>İnsan Kaynakları</option>
                        <option>Finans</option>
                        <option>Muhasebe</option>
                        <option>IT/Teknik</option>
                        <option>Üretim</option>
                        <option>Tedarik Zinciri</option>
                        <option>Halkla İlişkiler</option>
                        <option>Hukuk/Denetim</option>
                        <option>Yönetim</option>
                        <option>Diğer</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <div style={{ display: "inline-flex" }}>
                  <Form.Check.Input
                    className={check && !info.kvkkConfirmed && "invalid"}
                    type="checkbox"
                    onChange={(e) =>
                      setInfo((i) => ({
                        ...i,
                        kvkkConfirmed: e.target.checked,
                      }))
                    }
                  />

                  <Form.Check.Label
                    style={{
                      paddingLeft: "5px",
                      color: "#303030",
                      fontFamily: "Plus Jakarta Sans",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: "800",
                      lineHeight: "normal",
                    }}
                    className={check && !info.kvkkConfirmed && "invalid-text"}
                  >
                    Aşağıda yazılı şartları okudum ve kabul ediyorum.
                  </Form.Check.Label>
                </div>

                <p
                  style={{
                    color: "#303030",
                    fontFamily: "Plus Jakarta Sans",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: "300",
                    lineHeight: "15.6px",
                  }}
                >
                  6698 sayılıKişisel Verilerin Korunması Kanunu (KVKK) çerçevesinde, “Temel Bilgiler” kısmında
                  paylaştığım E-Posta, İsim, Soy İsim,Eğitim durumu, Toplam iş deneyimi, cinsiyet ve doğum yılı
                  bilgilerimin, kanunun belirlediği sınırlar çerçevesinde kullanılmasına izin veriyorum. Envanterin bir
                  kısmının ya da tamamının ekran görüntüsünü almayacağımı,fotoğraf/videosunu çekmeyeceğimi,içeriği hiç
                  kimseyle paylaşmayacağımı taahhüt eder, taahhüdümde durmamam nedeniyle ortaya çıkacak her türlü yasal
                  sonucu kabul ettiğimi beyan ederim.
                  <Alert.Link onClick={() => setModal(true)}>Sözleşme detayları</Alert.Link>
                </p>
              </Form>
            </Card.Body>

            <Row>
              <Col>
                <Button
                  onClick={submitDemographyForm}
                  disabled={
                    info.birthYear &&
                    info.name &&
                    info.email &&
                    info.gender &&
                    info.experience &&
                    info.kvkkConfirmed &&
                    info.school
                      ? false
                      : true
                  }
                >
                  action
                </Button>
              </Col>
            </Row>
          </Container>

          <Modal size="lg" show={modal} onHide={() => setModal(false)} aria-labelledby="example-modal-sizes-title-lg">
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-lg">
                KİŞİSEL VERİLERİN KORUNMASINA İLİŞKİN SÖZLEŞME
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="candidate-info-kvkk-modal-body">
              <p>
                Kişisel Verilerin Korunmasına İlişkin Sözleşme, devam eden bir süreç kapsamında paylaşılan kişisel
                verilerin korunmasına yönelik kişilerin hak ve yükümlülüklerini düzenleyen bir sözleşmedir. Talentturk
                olarak kişisel verileriniz korunması en temel önceliğimizdir. Temel hak ve özgürlüklerinizden birisi
                olan bilgilerinizin korunması amacıyla çıkartılmış olan 6698 sayılı Kişisel Verilerin Korunması Kanunu
                (bundan sonra KVKK kısaltması kullanılacaktır) çerçevesinde kişisel verilerinizin korunması ve kullanımı
                hakkında sizi bilgilendirmek istiyoruz.
              </p>
              <p>
                <h6>1. Kişisel Verinin Anlamı ve Kapsamı</h6>
                Kişisel veri kavramı KVKK’da kimliği bilinen ya da belirlenebilir gerçek kişilere ait tüm bilgiler
                olarak ifade edilmiştir. Bu çerçevede, isminiz, soy isminiz, telefon numaranız, e-mail adresiniz, doğum
                tarihiniz gibi bilgiler kişisel veri kapsamına girmektedir.
              </p>
              <p>
                <h6>2. Kişisel Verinin Alınması ve İşlenmesi</h6>
                Kişisel verilerinizi, yasal ve resmi sorumluluklarımızı yerine getirmek, bilimsel çalışmalarımızda
                kullanmak, ilerde benzer çalışmalar için sizinle iletişim kurabilmek ve gerekli durumlarda sizleri
                bilgilendirmek topluyoruz.
              </p>
              <p>
                <h6>3. Kişisel Verilerinizin Üçüncü Partilerle Paylaşılması</h6>
                Kişisel bilgilerinizi birlikte çalıştığımız çözüm ortaklarımızla, hizmet aldığımız kişi ve işletmelerle,
                hizmet verdiğimiz müşterilerimizle ve bilimsel danışmanlık desteği aldığımız bilim insanlarıyla
                paylaşılabilir. Bunlara ek olarak, yasal sorumluluklarımız kapsamında talep edilmesi durumunda
                mahkemeler ve diğer kamu kurumları ile kişisel veriler paylaşılabilir.
              </p>
              <p>
                <h6>4. Verilerin Saklanması ve Saklama Süresi</h6>
                Kişisel verilerinizi KVKK hükümleri ve standartları doğrultusunda veritabanımızda ve şifreli
                bilgisayarlarımızda saklıyoruz. KVKK 7. Madde f.1.'e göre kullanım amacı sona erdiğinde ve/veya mevzuat
                gereğince verileri kullanma için gerekli zamanaşımı süreleri sona erdiğinde, kişisel verileriniz
                silinmekte ya da anonim hale getirilmektedir.
              </p>
              <p>
                <h6>5. Haklar ve Sorumluluklar</h6>
                KVKK, 11. Maddeye göre, kişisel verilerinizle ilgili bizimle iletişime geçerek aşağıdaki taleplerde
                bulunabilirsiniz.
                <br></br>5.1.Kişisel verilerinizin işlenip işlenmediğini öğrenebilirsiniz
                <br></br>5.2.Eğer işlendiyse hangi amaçlarla işlendiğini, işlenme işlemlerinin belirtilen amaçlara uygun
                yapılıp yapılmadığını öğrenebilirsiniz
                <br></br>5.3. Kişisel verilerinizin kimlerle paylaşıldığını öğrenebilirsiniz
                <br></br>5.4. Kişisel verilerinizin düzeltilmesini, silinmesini, üçüncü şahıslarla paylaşılan
                verilerinizin de onlarla iletişim geçilerek silinmesini talep edebilirsiniz.
                <br></br>5.5. Kişisel verilerinizin kanuna aykırı olarak işlendiğini düşünmeniz durumunda itiraz
                edebilir, bir zarara uğradığınızı düşünüyorsanız zararın giderilmesini talep edebilirsiniz.
              </p>
              <p>
                <h6>6. Süre ve İletişim</h6>
                Bizimle info@talentturk.com mail adresi üzerinden iletişime geçebilirsiniz. Tüm talepleriniz 30 iş günü
                içinde sonuçlandırılacaktır
              </p>
              <Row>
                <Col xs="5"></Col>
                <Col xs="2">
                  <Button
                    className="candidate-info-kvkk-modal-closebutton"
                    variant="secondary"
                    size="sm"
                    onClick={() => setModal(false)}
                  >
                    Kapat
                  </Button>
                </Col>
                <Col xs="5"></Col>
              </Row>
            </Modal.Body>
          </Modal>
        </Col>
      </Row>
    </>
  );
}
