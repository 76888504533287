import { createAsyncThunk } from "@reduxjs/toolkit";

import LocalStorage from "../../../../../Services/Storage/LocalStorage";
import StorageModel from "../../../../../Models/StorageModel";
import HttpsClient from "../../../../../Services/HttpsClient";
import ApiHelper from "../../../../../Services/ApiHelper";
import Cache from "../../../../../Services/Cache";
import AssessmentAPI from "../API/AssessmentAPI";

const storage = new LocalStorage();
const storageModel = new StorageModel(storage);
const cache = new Cache(storageModel);

const httpsClient = new HttpsClient();
const apiHelper = new ApiHelper(httpsClient);
const assessmentAPI = new AssessmentAPI(apiHelper, cache);

export const getAssessments = createAsyncThunk("assessment/getAssessments", async (payload, { getState, dispatch }) => {
  const state = getState();
  const token = state.auth.user.token;

  return await assessmentAPI.getAssesments(payload, token);
});

export const createAssessment = createAsyncThunk(
  "assessment/createassessment",
  async (payload, { getState, dispatch }) => {
    const state = getState();
    const token = state.auth.user.token;

    await assessmentAPI.createAssessment(payload, token);

    dispatch(getAssessments());

    return;
  }
);

export const updateAssessment = createAsyncThunk(
  "assessment/updateAssessment",
  async (payload, { getState, dispatch }) => {
    const state = getState();
    const token = state.auth.user.token;

    await assessmentAPI.updateAssessment(payload, token);

    dispatch(getAssessments());

    return;
  }
);

export const deleteAssessment = createAsyncThunk(
  "assessment/deleteAssessment",
  async (payload, { getState, dispatch }) => {
    const state = getState();
    const token = state.auth.user.token;

    await assessmentAPI.deleteAssessment(payload, token);

    dispatch(getAssessments());

    return;
  }
);
