import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import CommentListRow from "./CommentListRow";

const CommentList = () => {
  const comments = useSelector((state) => state.candidate.comments);
  const dispatch = useDispatch();

  return (
    <Row className={"mx-0 mt-3"}>
      <Col>
        <Card className={"default-card py-3 px-4"}>
          {comments.map((comment, index) => {
            const lastItem = index === comments.length - 1;
            return <CommentListRow key={index} comment={comment} lastItem={lastItem}></CommentListRow>;
          })}
        </Card>
      </Col>
    </Row>
  );
};
export default CommentList;
