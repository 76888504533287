import { createAsyncThunk } from "@reduxjs/toolkit";

import LocalStorage from "../../../../Services/Storage/LocalStorage";
import StorageModel from "../../../../Models/StorageModel";
import Cache from "../../../../Services/Cache";

import HttpsClient from "../../../../Services/HttpsClient";
import ApiHelper from "../../../../Services/ApiHelper";
import ExportExcelAllFilterAPI from "../API/ExportExcelAllFilterAPI";
import ExcelGenerator from "../../../../Services/ExcelGenerator/ExcelGenerator";

import generateExcelRows from "../Utils/GenerateExcelRows";

const storage = new LocalStorage();
const storageModel = new StorageModel(storage);
const cache = new Cache(storageModel);

const httpsClient = new HttpsClient();
const apiHelper = new ApiHelper(httpsClient);
const applicationResultsAPI = new ExportExcelAllFilterAPI(apiHelper, cache);

const excelGenerator = new ExcelGenerator();

export const updateTest = createAsyncThunk("exportExcelAllFilter/updateTest", async (payload, getState) => {
  const state = getState.getState();
  const token = state.auth.user.token;
  const data = await applicationResultsAPI.getDemoResultData(payload, token);

  const excelRows = generateExcelRows(data);

  excelGenerator.generateExcel(excelRows, data.test.name);

  return data.data;
});

export const getTestEntity = createAsyncThunk("exportExcelAllFilter/GetTestsEntityOnly", async (_, getState) => {
  const state = getState.getState();
  const token = state.auth.user.token;
  const data = await applicationResultsAPI.getTestEntityOnly(token);

  return data.data;
});
