import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";

import Overlay from "../../../../SharedComponents/Overlay";
import CompanyUserList from "./CompanyUserList";
import { ClearCompanyUserState, HandleCompanyUserStateOnChange, UpdateCompanyId } from "../Store";
import ScrollableCard from "../../../../SharedComponents/ScrollableCard";
import { createCompanyUser, getCompanyUsers, updateCompanyUser } from "../Store/Thunk";
import { useLocation, useNavigate } from "react-router-dom";
import validator from "validator";
import BreadCrumb from "../../../BreadCrumb";
import { companyUserEnum, GetValueByKey } from "../../../../../Helpers/EnumHelper";
import ChangePasswordModal from "./ChangePasswordModal";
import { FloatingLabel } from "react-bootstrap";

export default function CompanyUser() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const companyUser = useSelector((state) => state.companyUser);
  const isLoading = useSelector((state) => state.companyUser.isLoading);
  const isUpdateOperation = useSelector((state) => state.companyUser.isUpdateOperation);
  const dispatch = useDispatch();
  const [selectedCompanyUserName, setSelectedCompanyUserName] = useState("");

  const { state } = useLocation();
  const companyId = state.id;
  const companyName = state.name;

  useEffect(() => {
    if (companyId) {
      dispatch(UpdateCompanyId(companyId));
      dispatch(getCompanyUsers({ companyId: companyId }));
    } else {
      navigate("/company");
    }
  }, [companyId]);

  const isDisabled = () => {
    return !(
      companyUser.Email?.isValid &&
      companyUser.password?.isValid &&
      companyUser.phoneNumber?.isValid &&
      companyUser.name?.isValid &&
      companyUser.companyUserTypeEnum?.isValid
    );
  };

  const HandleInputCssClass = (input, isValid) => {
    if (input.length === 0) return null;
    if (input?.length > 0 && isValid) return "valid-input-buttongroup ";
    return "invalid-input-buttongroup ";
  };

  const HandleValidationFieldChange = (input, variableName) => {
    let isValid = false;
    switch (variableName) {
      case "Email":
        isValid = validator.isEmail(input);
        break;
      case "password":
        isValid = validator.isStrongPassword(input) || input.length === 0;
        break;
      case "name":
        isValid = !validator.isEmpty(input);
        break;
      case "companyUserTypeEnum":
        isValid = !validator.isEmpty(input);
        break;
      case "phoneNumber":
        isValid = validator.isMobilePhone(input);
        break;

      default:
        console.log("Error at HandleValidationFieldChange, Check CompanyUser.js");
        return false;
    }

    let cssClass = HandleInputCssClass(input, isValid);

    dispatch(
      HandleCompanyUserStateOnChange({
        variableName: variableName,
        value: input,
        isValid: isValid,
        cssClass: cssClass,
      })
    );
  };

  const submitForm = (event) => {
    event.preventDefault();

    if (isUpdateOperation) {
      dispatch(
        updateCompanyUser({
          name: companyUser.name.value,
          Email: companyUser.Email.value,
          phoneNumber: companyUser.phoneNumber.value,
          companyUserTypeEnum: GetValueByKey(companyUser.companyUserTypeEnum.value, companyUserEnum),
          companyId: companyId,
          userId: companyUser.userId,
          companyUserId: companyUser.id,
        })
      );
    } else {
      dispatch(
        createCompanyUser({
          name: companyUser.name.value,
          password: companyUser.password.value,
          Email: companyUser.Email.value,
          phoneNumber: companyUser.phoneNumber.value,
          companyUserTypeEnum: GetValueByKey(companyUser.companyUserTypeEnum.value, companyUserEnum),
          companyId: companyId,
        })
      );

      event.currentTarget.reset();
    }
    ClearInputs();
  };

  const ClearInputs = () => {
    setSelectedCompanyUserName("");
    dispatch(ClearCompanyUserState());
  };

  return (
    <div>
      {isLoading && <Overlay />}
      <BreadCrumb
        pageName={t("companyUsersBreadcrumbLabel")}
        backPageName={t("companiesBreadCrumbLabel")}
        backUrl="/Company"
      >
        <Row>
          <Col>
            <h3>
              {t("companyName")} : {companyName}
            </h3>
          </Col>
          <Col>
            <h4> {selectedCompanyUserName ? t("companyUsersBreadcrumbLabel") + ": " + selectedCompanyUserName : ""}</h4>
          </Col>
        </Row>
      </BreadCrumb>
      <Card body className="mb-3 jakarta-sans">
        <Form onSubmit={submitForm}>
          <Row className={"mx-0"}>
            <Col xs={12} md={10}>
              <Row>
                <Col xs={12} md={true}>
                  <Row>
                    <FloatingLabel className={"px-0"} controlId={"floatingName"} label={t("nameFloatingPlaceholder")}>
                      <Form.Control
                        name="name"
                        placeholder={t("namePlaceholder")}
                        value={companyUser.name?.value}
                        className={companyUser.name?.cssClass}
                        onChange={(e) => {
                          HandleValidationFieldChange(e.target.value, "name");
                        }}
                      />
                    </FloatingLabel>
                  </Row>
                </Col>
                <Col xs={12} md={true}>
                  <Row>
                    <FloatingLabel
                      className={"px-0"}
                      controlId={"floatingPassword"}
                      label={t("passwordFloatingPlaceholder")}
                    >
                      <Form.Control
                        disabled={companyUser.isUpdateOperation}
                        name="password"
                        placeholder={t("passwordPlaceholder")}
                        value={isUpdateOperation ? "" : companyUser.password?.value}
                        className={isUpdateOperation ? "" : companyUser.password?.cssClass}
                        onChange={(e) => {
                          HandleValidationFieldChange(e.target.value, "password");
                        }}
                      />
                    </FloatingLabel>
                  </Row>
                </Col>
                <Col xs={12} md={true}>
                  <Row>
                    <FloatingLabel className={"px-0"} controlId={"floatingEmail"} label={t("emailFloatingPlaceholder")}>
                      <Form.Control
                        required
                        name="Email"
                        placeholder={t("emailPlaceholder")}
                        value={companyUser.Email?.value}
                        className={companyUser.Email?.cssClass}
                        onChange={(e) => {
                          HandleValidationFieldChange(e.target.value, "Email");
                        }}
                      />
                    </FloatingLabel>
                  </Row>
                </Col>
                <Col xs={12} md={true}>
                  <Row>
                    <FloatingLabel
                      className={"px-0"}
                      controlId={"floatingPhoneNumber"}
                      label={t("phoneNumberFloatingPlaceholder")}
                    >
                      <Form.Control
                        required
                        name="phoneNumber"
                        type={"number"}
                        placeholder={t("phoneNumberPlaceholder")}
                        value={companyUser.phoneNumber?.value}
                        className={companyUser.phoneNumber?.cssClass}
                        onChange={(e) => {
                          HandleValidationFieldChange(e.target.value, "phoneNumber");
                        }}
                      />
                    </FloatingLabel>
                  </Row>
                </Col>
                <Col xs={12} md={true}>
                  <Row className={"h-100"}>
                    <Form.Select
                      className={companyUser.companyUserTypeEnum?.cssClass}
                      value={
                        companyUser.companyUserTypeEnum?.value
                          ? companyUser.companyUserTypeEnum?.value
                          : t("chooseRoleDropdownToggleLabel")
                      }
                      onChange={(e) => {
                        if (e.target.value === "changeCss") {
                          HandleValidationFieldChange("", "companyUserTypeEnum"); //This will make it have 0 length, which makes HandleInputCssClass return null.
                        } else {
                          HandleValidationFieldChange(e.target.value, "companyUserTypeEnum");
                        }
                      }}
                    >
                      <option value={"changeCss"}>{t("chooseRoleDropdownToggleLabel")}</option>
                      {Object.keys(companyUserEnum).map((key) => {
                        return <option value={key}>{key}</option>;
                      })}
                    </Form.Select>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={2} style={{ paddingLeft: 24, paddingRight: 0 }} className={"align-content-between"}>
              <div style={{ height: isUpdateOperation ? "50%" : "100%" }}>
                <Button
                  type="submit"
                  style={{ padding: 0, width: "100%", height: "100%" }}
                  variant="primary"
                  disabled={isDisabled()}
                >
                  {isUpdateOperation ? t("update") : t("create")}
                </Button>
              </div>
              <div style={{ height: isUpdateOperation ? "50%" : "100%", display: !isUpdateOperation && "none" }}>
                <Button
                  style={{
                    padding: 0,
                    width: "100%",
                  }}
                  variant="secondary"
                  onClick={ClearInputs}
                >
                  {t("decline")}
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Card>
      <h5>{t("currentCompanyUsers")}</h5>
      <ScrollableCard>
        <CompanyUserList setSelectedCompanyUserName={setSelectedCompanyUserName} />
      </ScrollableCard>

      <ChangePasswordModal />
    </div>
  );
}
