import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import BreadCrumb from "../../../BreadCrumb";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";

import ApiHelper from "../../../../../Helpers/ApiHelper";
import { useSelector } from "react-redux";

export function Likert() {
  const user = useSelector((state) => state.auth.user);

  const { state } = useLocation();

  const isSub = state.isSub;

  const [scopeQuestion, setScopeQuestion] = useState({
    ScopeId: state.scope.id,
    SubScopeId: state.subScope?.id,
    Likert: {
      QuestionText: "",
      reverseItem: false,
    },
  });

  const [ScopeQuestions, setScopeQuestions] = useState();

  const [modal, setModalShow] = useState({
    show: false,
    data: { questionText: "" },
  });

  useEffect(() => {
    RefreshScopeQuestions();
  }, []);

  let CreateScopeQuestion = () => {
    const url = isSub ? "question/CreateSubLikert" : "question/CreateLikert";
    ApiHelper.postAsync(
      url,
      scopeQuestion,
      function () {
        RefreshScopeQuestions();
      },
      user.token
    );
  };

  let RefreshScopeQuestions = () => {
    setScopeQuestion({
      ScopeId: state.scope.id,
      SubScopeId: state.subScope?.id,
      Likert: {
        QuestionText: "",
        reverseItem: false,
      },
    });

    const url = isSub ? "question/GetSubLikerts" : "question/GetLikerts";
    const id = isSub ? state.subScope?.id : state.scope?.id;

    ApiHelper.getAsync(
      url,
      { id },
      function (result) {
        setScopeQuestions(result.data.data);
      },
      user.token
    );
  };

  let DeleteScopeQuestion = (id) => {
    ApiHelper.getAsync(
      "question/DeleteQuestion",
      { id: id },
      function () {
        RefreshScopeQuestions();
      },
      user.token
    );
  };

  let UpdateScopeQuestion = () => {
    ApiHelper.postAsync(
      "question/UpdateLikert",
      {
        id: modal.data.id,
        ScopeId: state.scope.id,
        SubScopeId: state.subScope?.id,
        Likert: {
          id: modal.data?.likert?.id,
          QuestionText: modal.data?.likert?.questionText,
          reverseItem: modal.data?.likert?.reverseItem,
        },
      },
      function () {
        setModalShow({
          show: false,
          data: { questionText: "" },
        });
        RefreshScopeQuestions();
      },
      user.token
    );
  };

  return (
    <div>
      <BreadCrumb
        pageName="Likert Soru"
        backPageName={isSub ? "Boyutlar" : "Alt Boyutlar"}
        backUrl={isSub ? "/subscope" : "/scope"}
        backState={
          isSub ? { state: { scope: state.scope, application: state.application } } : { state: state.application }
        }
      >
        <Row>
          <Col>
            <h5>Envanter : {state.application.name}</h5>
          </Col>
          <Col>
            <h5>Boyut : {state.scope.name}</h5>
          </Col>
          {isSub && (
            <Col>
              <h5>Alt Boyut : {state.subScope?.name}</h5>
            </Col>
          )}
        </Row>
      </BreadCrumb>
      <Card body className="mb-3">
        <Row>
          <Row>
            <Col xs="2">
              <h6>Likert Soru Metni </h6>
            </Col>
            <Col xs="2">
              <Form.Check
                type="switch"
                label="Reverse Item"
                onChange={(e) =>
                  setScopeQuestion((q) => ({
                    ...q,
                    Likert: { ...q.Likert, reverseItem: e.target.checked },
                  }))
                }
              />
            </Col>
          </Row>
          <Col xs={12} md={10}>
            <InputGroup className="mb-3">
              <Form.Control
                value={scopeQuestion.Likert?.QuestionText}
                placeholder="Likert soru metni giriniz"
                onChange={(e) =>
                  setScopeQuestion((q) => ({
                    ...q,
                    Likert: { ...q.Likert, QuestionText: e.target.value },
                  }))
                }
              />
            </InputGroup>
          </Col>

          <Col xs={12} md={2} className="mb-3">
            <Button
              style={{ height: "100%", width: "100%" }}
              variant="primary"
              onClick={CreateScopeQuestion}
              disabled={!scopeQuestion.Likert?.QuestionText}
            >
              Oluştur
            </Button>
          </Col>
        </Row>
      </Card>
      {ScopeQuestions?.length > 0 &&
        ScopeQuestions.map((sq, i) => {
          return (
            <Card key={i} className="mb-3">
              <Card.Body>
                <Row>
                  <Col xs="12" md="8">
                    {sq.id} - {sq.likert?.questionText}
                  </Col>
                  <Col xs="12" md="1">
                    {sq.likert?.reverseItem && "Reverse"}
                  </Col>
                  <Col xs="12" md="1">
                    {sq.order}
                  </Col>
                  <Col xs="12" md="2">
                    <Row xs="auto" className="justify-content-md-end">
                      <Col>
                        <Button size="sm" variant="success" onClick={() => setModalShow({ show: true, data: sq })}>
                          Düzenle
                        </Button>
                      </Col>
                      <Col>
                        <Button size="sm" variant="danger" onClick={() => DeleteScopeQuestion(sq.id)}>
                          Sil
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          );
        })}

      <Modal
        show={modal.show}
        onHide={() => {
          setModalShow({
            show: false,
            data: { questionText: "" },
          });
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Likert Soru Güncelle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs="6">
              <h6>Likert Soru Metni </h6>
            </Col>
            <Col xs="6">
              <Form.Check
                type="switch"
                label="Reverse Item"
                checked={modal.data?.likert?.reverseItem}
                onChange={(e) =>
                  setModalShow((modal) => ({
                    ...modal,
                    data: {
                      ...modal.data,
                      likert: {
                        ...modal.data?.likert,
                        reverseItem: e.target.checked,
                      },
                    },
                  }))
                }
              />
            </Col>
          </Row>
          <InputGroup className="mb-3">
            <Form.Control
              placeholder="Soru metini"
              value={modal.data?.likert?.questionText}
              onChange={(e) =>
                setModalShow((modal) => ({
                  ...modal,
                  data: {
                    ...modal.data,
                    likert: {
                      ...modal.data?.likert,
                      questionText: e.target.value,
                    },
                  },
                }))
              }
            />
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={UpdateScopeQuestion}>
            Güncelle
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
