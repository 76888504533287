import React from "react";
import Card from "react-bootstrap/Card";
import { Col, Row } from "react-bootstrap";
import StatisticTimePeriod from "./StatisticTimePeriod";
import { useSelector } from "react-redux";

const Statistics = () => {
  const statistics = useSelector((state) => state.adminDash.companyStatistics);

  return (
    <Card className={"default-card h-100 py-2"}>
      <Row className={"mx-0 h-25"}>
        <Col className={"align-content-center"} style={{ borderBottom: "3px solid #E7ECEF", margin: "0 24px" }}>
          <StatisticTimePeriod headerText={"BUGÜN"} color={"#9155FD"} completedCount={statistics?.lastDayCount} />
        </Col>
      </Row>
      <Row className={"mx-0 h-25"}>
        <Col className={"align-content-center"} style={{ borderBottom: "3px solid #E7ECEF", margin: "0 24px" }}>
          <StatisticTimePeriod headerText={"BU HAFTA"} color={"#56CA00"} completedCount={statistics?.last7daysCount} />
        </Col>
      </Row>
      <Row className={"mx-0 h-25"}>
        <Col className={"align-content-center"} style={{ borderBottom: "3px solid #E7ECEF", margin: "0 24px" }}>
          <StatisticTimePeriod headerText={"BU AY"} color={"#FFB400"} completedCount={statistics?.last30DaysCount} />
        </Col>
      </Row>
      <Row className={"mx-0 h-25"}>
        <Col className={"align-content-center"} style={{ margin: "0 12px" }}>
          <StatisticTimePeriod headerText={"SON 3 AY"} color={"#16B1FF"} completedCount={statistics?.last90DaysCount} />
        </Col>
      </Row>
    </Card>
  );
};
export default Statistics;
