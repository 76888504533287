import React from "react";
import { Button } from "react-bootstrap";
import IconSvg from "../../AppMenu/Components/IconSvg";

export default function DownloadButtonUi({ StartPdfExportProcess }) {
  return (
    <Button className="_pdf-download-button" onClick={StartPdfExportProcess}>
      Pdf
      <span style={{ paddingLeft: "5px" }}>
        <IconSvg icon="download" />
      </span>
    </Button>
  );
}
