import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Overlay from "../SharedComponents/Overlay";
import IconSvg from "../AppMenu/Components/IconSvg";
import { login } from "../../Store/Auth/AuthThunk";
import { useDispatch, useSelector } from "react-redux";

export function HashLogin() {
  const { hash } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userAtRedux = useSelector((state) => state.auth.user);

  if (hash) {
    let loginData = atob(hash);
    loginData = JSON.parse(JSON.parse(loginData));
    dispatch(login(loginData));
  } else {
    alert("Bozuk link, lütfen mailde size gönderilen kullanıcı bilgileri ile giriş yapınız");
    navigate("/login");
  }

  useEffect(() => {
    if (userAtRedux?.userRole) {
      localStorage.setItem("cpdata", JSON.stringify(userAtRedux));
      if (userAtRedux.userRole === "Admin") navigate("/dash");
      else if (userAtRedux.userRole === "User") navigate("/userdash");
    }
  }, [userAtRedux]);

  return (
    <div style={{ marginTop: "100px", display: "flex", justifyContent: "center" }}>
      <Overlay text="Hoşgeldiniz, Yönlendirme yapılıyor" />
      <IconSvg icon="cp-logo" height="100" width="100" />
    </div>
  );
}
