import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import Row from "react-bootstrap/Row";
import { SetSquadModalIsShown } from "../Store";
import Col from "react-bootstrap/Col";
import IconSvg from "./IconSvg";
import React from "react";

const SquadModal = () => {
  const dispatch = useDispatch();
  const windowSizeCssClass = useSelector((state) => state.appMenu.screenSize);

  const isShown = useSelector((state) => state.appMenu.squadModalIsShown);
  const squads = useSelector((state) => state.appMenu.squads);

  return (
    <Modal
      size={"lg"}
      dialogClassName={`custom-modal-dialog ${windowSizeCssClass}`}
      contentClassName={"custom-modal-content"}
      show={isShown}
      onHide={() => {
        dispatch(SetSquadModalIsShown(false));
      }}
    >
      <div className={"bordered"}>
        <Modal.Header closeButton className={"p-0 border-bottom-0"}></Modal.Header>

        {squads.map((squad, i) => {
          return (
            <Row key={i} className={"text-16 text-color-gray-dark "}>
              <Col xs={"auto"}>
                <IconSvg icon={"succes-dot"} fill={squad.squadColor}></IconSvg>
              </Col>
              <Col xs={"auto"} className={"px-0"}>
                {squad.squadName}
              </Col>
              <Row className={"justify-content-evenly"}>
                {squad.admins.map((admin, i) => {
                  return (
                    <Col key={i} xs={"auto"}>
                      {admin.adminName}
                    </Col>
                  );
                })}
              </Row>
            </Row>
          );
        })}
      </div>
    </Modal>
  );
};

export default SquadModal;
