import Col from "react-bootstrap/Col";
import { Button } from "react-bootstrap";
import IconSvg from "../../../../AppMenu/Components/IconSvg";
import Row from "react-bootstrap/Row";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SetSelectedResult } from "../../Store";

const AssignedAssessmentsListRowButtons = ({ type, result, color, size }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const HandleWrapUpButton = () => {
    switch (type) {
      case "Inventory": {
        navigate("/iwrapup/" + result.inventoryResultId);
        break;
      }
      case "Test": {
        navigate("/twrapup/" + result.testResultId);
        break;
      }
      case "Survey": {
        break;
      }
      default: {
        console.log("something wrong with HandleWrapUpButton at AssignedAssessmentsListRowButton");
      }
    }
  };
  return (
    <Row>
      <Col xs={"auto"}>
        <Button
          onClick={() => {
            dispatch(SetSelectedResult(result));
          }}
          className={"button secondary p-0"}
          style={{ height: size, width: size, borderRadius: "10px", borderColor: color }}
        >
          <IconSvg icon={"clock"} fill={color}></IconSvg>
        </Button>
      </Col>
      <Col xs={"auto"}>
        <Button
          onClick={() => HandleWrapUpButton()}
          className={"button secondary p-0"}
          style={{ height: size, width: size, borderRadius: "10px", borderColor: color }}
        >
          <IconSvg icon={"wrapUp"} fill={color}></IconSvg>
        </Button>
      </Col>
      <Col xs={"auto"}>
        <Button
          className={"button secondary p-0"}
          style={{ height: size, width: size, borderRadius: "10px", borderColor: color }}
        >
          <IconSvg icon={"pdf"} fill={color}></IconSvg>
        </Button>
      </Col>
    </Row>
  );
};
export default AssignedAssessmentsListRowButtons;
