import React, { useEffect } from "react";
import { Card } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useLocation, useNavigate } from "react-router-dom";
import { ParseDate } from "../../../../Helpers/DateHelper";
import ScrollableCard from "../../../SharedComponents/ScrollableCard";

const PositionCandidatesList = ({ candidatesList }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const companyId = 16;
  const { state } = useLocation();

  useEffect(() => {}, []);

  return (
    <>
      <Card className={"header"}>
        <Row className={"justify-content-between text-20 text-color-gray-light fw-medium"} style={{ height: "50px" }}>
          <Col xs={{ span: 5, offset: 1 }} className={"align-content-center"}>
            Ad - Soyad
          </Col>
          <Col xs={6} className={"align-content-center"}>
            <Row>E - Mail</Row>
          </Col>
        </Row>
      </Card>

      <ScrollableCard>
        {candidatesList &&
          candidatesList.map((candidate, i) => (
            <Card className={"list-row"} key={i}>
              <Row
                className={"justify-content-between text-20 text-color-gray-dark fw-medium hover-bg-darken"}
                onClick={() => {
                  navigate("/candidate", { state: { candidateId: candidate.id, backUrl: "/createPosition" } });
                }}
                style={{ height: "50px" }}
              >
                <Col xs={{ span: 6, offset: 0 }} className={"align-content-center"}>
                  <Row>
                    <Col xs={2} className={"align-content-center"}>
                      <Row className={"justify-content-end"}>
                        <Col xs={"auto"}>
                          <div
                            className={"rounded-circle align-content-center border border-1 border-gray"}
                            style={{ height: "40px", width: "40px" }}
                          ></div>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={10} className={"align-content-center"}>
                      {candidate.name}
                    </Col>
                  </Row>
                </Col>
                <Col xs={5} className={"align-content-center"}>
                  <Row>{candidate.email} </Row>
                </Col>
                <Col xs={1} className={"align-content-center"}>
                  <Row>{ParseDate(candidate.createdAt).date}</Row>
                </Col>
              </Row>
            </Card>
          ))}
      </ScrollableCard>
    </>
  );
};
export default PositionCandidatesList;
