import React, { useEffect, useState } from "react";
import AppMenu from "./AppMenu";
import AppHeader from "./AppHeader";
import { useDispatch, useSelector } from "react-redux";
import { SetScreenSizeCssClass } from "../Store";
import { useLocation } from "react-router-dom";
import AlertWrapper from "../../SharedComponents/Alerts/Components/AlertWrapper";

export default function AppLayout({ children }) {
  const user = useSelector((state) => state.auth.user);
  const windowSizeCssClass = useSelector((state) => state.appMenu.screenSize);
  const location = useLocation();

  const [windowSizeCss, setWindowSizeCss] = useState("large-screen");
  const dispatch = useDispatch();

  useEffect(() => {
    const handleResize = () => {
      setWindowSizeCss(window.innerWidth > 1200 ? "large-screen" : "small-screen");
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    dispatch(SetScreenSizeCssClass(windowSizeCss));
  }, [windowSizeCss]);

  return (
    <div className={"position-relative"}>
      {!user?.userRole ||
      location.pathname.includes("invapp") ||
      location.pathname.includes("testapp") ||
      location.pathname.includes("application") ||
      location.pathname.includes("demography") ||
      location.pathname.includes("demoResult") ||
      location.pathname.includes("wrapup") ? (
        <>
          <AppHeader />
          {children}
        </>
      ) : (
        <>
          {/* todo appMunu neden user i prop olarak aliyor app menu user i reduxtan cekebilir  */}
          <AppMenu role={user?.userRole} userName={user?.userName} />
          <div className={`content ${windowSizeCssClass}`}>
            {children}

            <AlertWrapper />
          </div>
        </>
      )}
    </div>
  );
}
