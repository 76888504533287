import { Col, Row } from "react-bootstrap";
import React from "react";
import { ParseDate } from "../../../../../Helpers/DateHelper";

const NotificationListRow = ({ key, notification }) => {
  return (
    <div key={key} className={"p-0"}>
      <Row key={key} className={" text-20 text-color-gray-dark fw-medium hover-bg-darken"} style={{ height: "50px" }}>
        <Col xs={{ span: 4, offset: 0 }} className={"align-content-center"}>
          <Row>
            <Col xs={3} className={"align-content-center"}>
              <Row className={"justify-content-end"}>
                <Col xs={"auto"}>
                  <div
                    className={"rounded-circle align-content-center border border-1 border-gray"}
                    style={{ height: "40px", width: "40px" }}
                  ></div>
                </Col>
              </Row>
            </Col>
            <Col xs={9} className={"align-content-center"}>
              {notification?.candidateName}
            </Col>
          </Row>
        </Col>
        <Col xs={4} className={"align-content-center"}>
          <Row className={"justify-content-center "}>{notification?.applicationName}'i Tamamladı </Row>
        </Col>
        <Col xs={{ span: 3, offset: 1 }} className={"align-content-center"}>
          <Row className={"mx-0 text-20 text-color-gray-light fw-medium"}>
            {ParseDate(notification?.finishDate).date} {ParseDate(notification?.finishDate).time}
          </Row>
        </Col>
      </Row>
    </div>
  );
};
export default NotificationListRow;
