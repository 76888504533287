import React from "react";
import { useTranslation } from "react-i18next";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BreadCrumb from "../../../../BreadCrumb";

export default function ReportItemBreadCrumb({ state, pageName }) {
  const { t } = useTranslation();
  return (
    <BreadCrumb
      pageName={pageName}
      backPageName={state.subScope ? t("subScopes") : t("scopes")}
      backUrl={state.subScope ? "/subScope" : "/scope"}
      backState={{
        state: state.subScope ? { scope: state.scope, application: state.application } : state.application,
      }}
    >
      <Row>
        <Col>
          <h4>Envanter Adı : {state?.application?.name}</h4>
        </Col>
        <Col>
          <h4>Boyut Adı : {state.scope.name}</h4>
        </Col>
        {state.subScope && (
          <Col>
            <h4>Alt Boyut Adı : {state.subScope.name}</h4>
          </Col>
        )}
      </Row>
    </BreadCrumb>
  );
}
