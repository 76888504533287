import React from "react";
import Row from "react-bootstrap/Row";
import { useDispatch } from "react-redux";
import Col from "react-bootstrap/Col";
import CommentList from "./CommentList";
import ScrollableCard from "../../../../SharedComponents/ScrollableCard";

const Comment = () => {
  const dispatch = useDispatch();
  const scrollableCardPosition = { bottom: window.innerHeight / 3 };

  return (
    <div>
      <Row className={"justify-content-between mt-3"}>
        <Col xs={"auto"} className={"text-14 text-color-gray-light fw-medium"}>
          Yorumlar
        </Col>
      </Row>
      <ScrollableCard top={0}>
        <CommentList></CommentList>
      </ScrollableCard>
    </div>
  );
};
export default Comment;
