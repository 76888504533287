import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Card from "react-bootstrap/Card";
import Overlay from "../../../../SharedComponents/Overlay";
import AssessmentList from "./AssessmentList";
import ScrollableCard from "../../../../SharedComponents/ScrollableCard";
import { getB2bAssessments, getCreditsByCompanyId, upsertCredit } from "../Store/Thunk";
import { useLocation, useNavigate } from "react-router-dom";
import BreadCrumb from "../../../BreadCrumb";
import { FloatingLabel } from "react-bootstrap";
import { UpdateCompanyId } from "../Store";
import CreditHistoryModal from "./CreditHistoryModal";

export default function Credits() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.credits.isLoading);
  const assessmentList = useSelector((state) => state.credits.assessmentList);
  const { state } = useLocation();
  const companyId = state?.id;
  const companyName = state?.name;
  const isUpdateOperation = useSelector((state) => state.company.isUpdateOperation);

  const [currentAssessment, setCurrentAssessment] = useState({ name: "Choose an Assessment", id: "-1" });
  const [creditAmount, setCreditAmount] = useState(0);

  useEffect(() => {
    if (companyId) {
      dispatch(UpdateCompanyId({ companyId: companyId }));
      dispatch(getB2bAssessments());
      dispatch(getCreditsByCompanyId({ companyId: companyId }));
    } else {
      navigate("/company");
    }
  }, [companyId]);

  const HandleChooseAssessment = (chosenAssessmentId) => {
    if (chosenAssessmentId === "-1") {
      setCurrentAssessment({ name: "Choose an Assessment", id: "-1" });
    } else {
      const chosenAssessment = assessmentList.find((assessment) => assessment.id === Number(chosenAssessmentId));
      setCurrentAssessment({ name: chosenAssessment.name, id: chosenAssessment.id });
    }
  };

  const submitForm = (event) => {
    event.preventDefault();
    dispatch(
      upsertCredit({
        companyId: companyId,
        assessmentId: currentAssessment.id,
        creditAmount: Number(creditAmount),
      })
    );
  };

  return (
    <div>
      {isLoading && <Overlay />}
      <BreadCrumb
        pageName={t("creditsBreadCrumbLabel")}
        backPageName={t("companiesBreadCrumbLabel")}
        backUrl="/Company"
      >
        <Row>
          <Col className={"align-content-center"}>
            <h3>
              {t("companyName")} : {companyName}
            </h3>
          </Col>
        </Row>
      </BreadCrumb>
      <Card body className="mb-3">
        <Form onSubmit={submitForm}>
          <Row>
            <Col xs={12} md={10}>
              <InputGroup className={"h-100"}>
                <Form.Select
                  className={currentAssessment.id !== "-1" && "valid-input-buttongroup "}
                  onChange={(e) => {
                    HandleChooseAssessment(e.target.value);
                  }}
                  value={currentAssessment.id ? currentAssessment.id : t("chooseAnAssessmentFormSelectLabel")}
                >
                  <option value={"-1"}>{t("chooseAnAssessmentFormSelectLabel")}</option>
                  {assessmentList.map((assessment, index) => {
                    return (
                      <option key={index} value={assessment.id}>
                        {assessment.name}
                      </option>
                    );
                  })}
                </Form.Select>

                <FloatingLabel controlId={"floatingCreditAmount"} label={t("enterCreditAmountPlaceholder")}>
                  <Form.Control
                    type={"number"}
                    className={creditAmount && " valid-input-buttongroup "}
                    placeholder={t("enterCreditAmountPlaceholder")}
                    onChange={(e) => {
                      setCreditAmount(e.target.value);
                    }}
                  ></Form.Control>
                </FloatingLabel>
              </InputGroup>
            </Col>
            <Col xs={12} md={2} style={{ marginBottom: "0px !important" }}>
              <Row style={{ height: !isUpdateOperation && "100%", margin: 0 }}>
                <Button
                  className="mb-1"
                  type="submit"
                  style={{ width: "100%", height: "100%" }}
                  variant="primary"
                  disabled={!creditAmount || currentAssessment.id === "-1"}
                >
                  {t("assignButtonLabel")}
                </Button>
              </Row>
            </Col>
          </Row>
        </Form>
      </Card>
      <h5>{t("currentAssessmentsHeader")}</h5>
      <ScrollableCard>
        <AssessmentList />
      </ScrollableCard>
      <CreditHistoryModal companyName={companyName}></CreditHistoryModal>
    </div>
  );
}
