import { createSlice } from "@reduxjs/toolkit";

export const generalModals = createSlice({
  name: "generalModals",
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {},
});

export const { UpdateConfirmationModalData, UpdateErrorModalData, UpdateSuccessModalData } = generalModals.actions;

export default generalModals.reducer;
