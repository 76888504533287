import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container } from "react-bootstrap";

import ApiHelper from "../../../Helpers/ApiHelper";
import Overlay from "../../SharedComponents/Overlay";
import WrapUpHeader from "./WrapUpHeader";
import ReportItemMapper from "./ReportItemMapper";
import InventoryPdfButton from "../../PdfDownloadButton/Components/InventoryPdfButton";
import { useSelector } from "react-redux";

import "../Styles/index.css";
import "../Styles/WrapUp.css";
import "animate.css";

export default function InventoryWrapUp() {
  const user = useSelector((state) => state.auth.user);
  const { inventoryResultId } = useParams();
  const { demoResultId } = useParams();
  const [appData, setAppData] = useState([]);
  const [resultData, setResultData] = useState(null);

  const [showOverlay, setShowOverlay] = useState(true);

  useEffect(() => {
    if (demoResultId || inventoryResultId) {
      const id = demoResultId ? demoResultId : inventoryResultId;
      const endPoint = demoResultId ? "GetInventoryDemoCalculation" : "GetInventoryCalculation";
      const url = "ticket/" + endPoint;
      ApiHelper.getAsync(
        url,
        { id },
        function (response) {
          if (response.data.statusCode) MapResponse(response.data.data);
          else alert("bir hata oluştu"); //todo burada hatayi handle et , guzek bir alert kutusu goster gerekirse sayfayi kapat
        },
        user.token
      );
    } else {
      setShowOverlay(false);
      alert("problem");
    }
  }, []);

  let MapResponse = (resultData) => {
    setResultData(resultData);
    const scheme = JSON.parse(resultData.wrapUpJson);
    const calculation = JSON.parse(resultData.calculationJson);
    const calculatedScopes = calculation?.calculatedScopes;

    scheme.forEach((reportItem) => {
      if (reportItem.htmlEl) return;
      else if (reportItem.componentName === "AnsweringTendencyEntity") {
        reportItem.consistencyScore = calculation?.consistencyScore?.score;
        reportItem.inventoryLeanNumbers = calculation?.inventoryLeanNumbers;
      } else if (reportItem.subScopeId)
        reportItem.score = calculatedScopes.find(
          (x) => x.isSub && x.scopeId === reportItem.subScopeId
        )?.calculatedScore;
      else if (reportItem.scopeId) {
        reportItem.score = calculatedScopes.find((x) => !x.isSub && x.scopeId === reportItem.scopeId)?.calculatedScore;
      }
    });

    setAppData({ scheme, resultData });
    setShowOverlay(false);
  };

  const CreateQrUrl = () => {
    let qrURL = "";
    if (demoResultId) qrURL = window.location.origin + "/#/idemoResult/" + demoResultId;
    else if (inventoryResultId) qrURL = window.location.origin + "/#/iResult/" + inventoryResultId;
    return qrURL;
  };

  return (
    <Container>
      {showOverlay && <Overlay text="Sonuçlar hazırlanıyor" />}
      <WrapUpHeader resultData={appData.resultData} CreateQrUrl={CreateQrUrl}>
        <InventoryPdfButton demoResultId={demoResultId} inventoryResultId={inventoryResultId} resultData={resultData} />
      </WrapUpHeader>
      <ReportItemMapper calculatedScheme={appData.scheme} type={"inventory"} />
    </Container>
  );
}
