export const CompanyInfoApiUrls = {
  upsertCompanyInfo: "company/UpsertCompanyInfo",
  deleteCompanyInfo: "company/DeleteCompanyInfo",
  deleteImageFromCompanyInfo: "company/DeleteImageFromCompanyInfo",
  getCompanyInfo: "company/GetCompanyInfo",
};

export default class adminCompanyInfoAPI {
  constructor(httpsClient, cache) {
    this.httpsClient = httpsClient;
    this.cache = cache;
  }

  async upsertCompanyInfo(data, token) {
    return this.post(CompanyInfoApiUrls.upsertCompanyInfo, data, token);
  }

  async deleteCompanyInfo(data, token) {
    return this.get(CompanyInfoApiUrls.deleteCompanyInfo, data, token);
  }

  async deleteImageFromCompanyInfo(data, token) {
    return this.get(CompanyInfoApiUrls.deleteImageFromCompanyInfo, data, token);
  }

  async getCompanyInfo(data, token) {
    return this.get(CompanyInfoApiUrls.getCompanyInfo, data, token);
  }

  async get(url, data, token, eAlert, sAlert) {
    return await this.httpsClient.get(url, data, token, eAlert, sAlert);
  }

  async post(url, data, token, eAlert, sAlert) {
    this.cache.remove("tests");

    return await this.httpsClient.post(url, data, token, eAlert, sAlert);
  }
}
