import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Overlay from "../../../SharedComponents/Overlay";
import CreateCandidatesList from "./CreateCandidatesList";
import { ChangeIsUpdateOperation, ShowUpsertCandidateModal } from "../Store";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { getCandidatesByCompanyId } from "../Store/Thunk";
import { getPositionsByCompanyId } from "../../CreatePosition/Store/Thunk";
import UpsertCandidateModal from "./UpsertCandidateModal";
import ScrollableCard from "../../../SharedComponents/ScrollableCard";
import IconSvg from "../../../AppMenu/Components/IconSvg";
import { getCompanyCreateProjectData } from "../../Project/Store/Thunk";

export default function CreateCandidate() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.createCandidate.isLoading);
  const candidateList = useSelector((state) => state.createCandidate.candidateList);
  const positionList = useSelector((state) => state.createPosition.positionList);
  const companyId = useSelector((state) => state.auth.user.companyUser.company.companyId);

  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    dispatch(getPositionsByCompanyId({ id: companyId }));
    dispatch(getCandidatesByCompanyId({ companyId: companyId }));
    dispatch(getCompanyCreateProjectData());
  }, [companyId]);

  const CreateCandidate = () => {
    dispatch(ShowUpsertCandidateModal({ upsertCandidateModalIsShown: true }));
    dispatch(ChangeIsUpdateOperation({ isUpdateOperation: false }));
  };

  const FilteredCandidateList = () => {
    return candidateList.filter((candidate) => {
      const matchesName = searchInput
        ? candidate.candidateName.toLowerCase().includes(searchInput.toLowerCase())
        : true;
      const matchesEmail = searchInput ? candidate.email.toLowerCase().includes(searchInput.toLowerCase()) : true;

      return matchesName || matchesEmail;
    });
  };

  return (
    <>
      <Form>
        <Row className={"justify-content-between mx-0 mb-3"}>
          <Col>
            {/*<FloatingLabel controlId={"floatingCandidateSearch"} label={t("searchCandidateFloatingLabel")}>*/}
            <Form.Control
              placeholder={t("searchCandidateLabel")}
              className={"h-100 default-text-input white-bg text-color-gray-light"}
              onChange={(e) => {
                setSearchInput(e.target.value);
              }}
            ></Form.Control>
          </Col>
          <Col xs={"auto"}>
            <Button
              className={"button secondary"}
              onClick={() => {
                CreateCandidate();
              }}
            >
              <Row>
                <Col xs={"auto"} className={"align-content-center"}>
                  <IconSvg icon={"plusV2"} height={22} width={22}></IconSvg>
                </Col>
                <Col xs={"auto"} className={"text-20 fw-medium"}>
                  {t("createNewCandidateButtonLabel")}
                </Col>
              </Row>
            </Button>
          </Col>
        </Row>
      </Form>
      {isLoading && <Overlay />}
      <ScrollableCard bottom={20}>
        <CreateCandidatesList candidatesList={FilteredCandidateList()}></CreateCandidatesList>
      </ScrollableCard>
      <UpsertCandidateModal positionList={positionList}></UpsertCandidateModal>
    </>
  );
}
