import React from "react";
import { Card } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ChangeIsUpdateOperation, ShowUpsertPositionModal, UpdateUpsertPositionModalData } from "../Store";
import { useNavigate } from "react-router-dom";
import IconSvg from "../../../AppMenu/Components/IconSvg";
import Button from "react-bootstrap/Button";

const PositionList = ({ positionList }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const HandleUpdateClick = (position) => {
    dispatch(UpdateUpsertPositionModalData(position));
    dispatch(ChangeIsUpdateOperation({ isUpdateOperation: true }));
    dispatch(ShowUpsertPositionModal({ upsertPositionModalIsShown: true }));
  };

  return (
    <>
      <Row className={"text-14 fw-medium text-color-gray-light mx-0"}>
        <Col>MEVCUT POZİSYONLAR</Col>
      </Row>
      <Row className={"mx-0 mt-3"}>
        {positionList &&
          positionList.map((position, i) => (
            <Col xs={12} md={6} xl={4} xxl={3} xxxl={2} key={i} className={"mb-4"}>
              <Card className="default-card" key={"position" + i} style={{ height: "330px" }}>
                <div className="d-flex flex-column h-100 p-3">
                  <Row>
                    <Col className="text-16 text-color-gray-light fw-medium">ID {position.id}</Col>
                    <Col xs="auto">
                      <div
                        className="hover-bg-darken p-1"
                        onClick={() => {
                          HandleUpdateClick(position);
                        }}
                      >
                        <IconSvg icon="edit" />
                      </div>
                    </Col>
                  </Row>

                  <Row className={"justify-content-center mt-3"}>
                    <Col xs={"auto"}>
                      <IconSvg icon={"shopping-bag"} width={52} height={52}></IconSvg>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="text-center text-24 text-color-gray-dark fw-medium mt-4">{position.name}</Col>
                  </Row>

                  <Row className="mt-auto mb-4 justify-content-center">
                    <Col xs={"auto"}>
                      <Button
                        onClick={() => {
                          navigate("/position", { state: { positionId: position.id } });
                        }}
                        className="button secondary text-20"
                      >
                        {t("goToPositionButtonLabel")}
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          ))}
      </Row>
    </>
  );
};
export default PositionList;
