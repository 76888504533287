import React from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import "@fontsource/plus-jakarta-sans"; // Defaults to weight 400
import "react-tooltip/dist/react-tooltip.css";
import "./App.css";
import AppLayout from "../Features/AppMenu/Components/AppLayout";

// import AppHeader from "./AppHeader";
// import AppFooter from "./AppFooter";
//Pages
import AdminDash from "../Features/Admin/Dashboard";
// import { UserDash } from "../Components/Dashboard/User/UserDash/UserDash";
import { Register } from "../Features/LoginRegister/Register";
import { Login } from "../Features/LoginRegister/Login";
import { Confirmation } from "../Features/LoginRegister/Confirmation";
import Inventory from "../Features/SuperAdmin/CreateApplication/Inventory";
import Test from "../Features/SuperAdmin/CreateApplication/Test";
import Survey from "../Features/SuperAdmin/CreateApplication/Survey";
import ApplicationResults from "../Features/SuperAdmin/ApplicationResults";
import InventoryAnswers from "../Features/SuperAdmin/CreateApplication/InventoryAnswers";
import { DemoInventory } from "../Features/AnsweringApplication/AnswerInventoryApp/DemoInventory";
import { DemoTest } from "../Features/AnsweringApplication/AnswerTestApp/DemoTest";
import { Scope, SubScope } from "../Features/SuperAdmin/CreateApplication/Scope";
import { Polar } from "../Features/SuperAdmin/CreateApplication/CreateQuestions/Questions/Polar";

import { Likert } from "../Features/SuperAdmin/CreateApplication/CreateQuestions/Questions/Likert";
import { CheckBox } from "../Features/SuperAdmin/CreateApplication/CreateQuestions/Questions/CheckBox";
import { TestItem } from "../Features/SuperAdmin/CreateApplication/CreateQuestions/Questions/TestItem";
import { ReorderQuestions } from "../Features/SuperAdmin/CreateApplication/CreateQuestions/ReorderQuestions";
import { ReorderCheckboxes } from "../Features/SuperAdmin/CreateApplication/CreateQuestions/ReorderCheckboxes";
import { LandingPage } from "../Features/LoginRegister/LandingPage";

import { HashLogin } from "../Features/LoginRegister/HashLogin";

import { MatchQuestions } from "../Features/SuperAdmin/CreateApplication/CreateQuestions/MatchQuestions";

import { RequireAuth } from "../Helpers/RequireAuth";
import BasicGraphSummary from "../Features/SuperAdmin/CreateApplication/ReportItems/BasicGraphSummary";
import BasicGraphDetail from "../Features/SuperAdmin/CreateApplication/ReportItems/BasicGraphDetail";
import MonoTextuary from "../Features/SuperAdmin/CreateApplication/ReportItems/MonoTextuary";
import DualTextuary from "../Features/SuperAdmin/CreateApplication/ReportItems/DualTextuary";
import ReportScheme from "../Features/SuperAdmin/CreateApplication/ReportScheme";
import Assessment from "../Features/SuperAdmin/CreateApplication/Assessment";
import ApplicationInfo from "../Features/SuperAdmin/CreateApplication/ApplicationInfo";
import InventoryWrapUp from "../Features/WrapUp/Components/InventoryWrapUp";
import TestWrapUp from "../Features/WrapUp/Components/TestWrapUp";

//import "bootstrap/dist/css/bootstrap.css";
import Company from "../Features/SuperAdmin/CreateCompany/Company";
import CompanyUser from "../Features/SuperAdmin/CreateCompany/CompanyUser";
import Credits from "../Features/SuperAdmin/CreateCompany/Credits";
import Position from "../Features/Admin/Position/Components/Position";
import CreatePosition from "../Features/Admin/CreatePosition";
import CreateCandidate from "../Features/Admin/CreateCandidate";
import CompanyInfo from "../Features/SuperAdmin/CreateCompany/CompanyInfo";
import Candidate from "../Features/Admin/Candidate/Components/Candidate";
import AdminCompanyInfo from "../Features/Admin/AdminCompanyInfo";
import UserDash from "../Features/User/UserDash";
import Project from "../Features/Admin/Project";
import { B2bInventory } from "../Features/AnsweringApplication/AnswerInventoryApp/B2bInventory";
import DemographyForm from "../Features/User/Demography";
import { B2bTest } from "../Features/AnsweringApplication/AnswerTestApp/B2bTest";

function App() {
  const prevLocation = window.location.href;
  return (
    <HashRouter>
      <AppLayout>
        <Routes>
          <Route element={<RequireAuth allowedRoles={["SuperAdmin"]} />}>
            <Route path="/assessment" element={<Assessment />}></Route>
            <Route path="/inventory" element={<Inventory />}></Route>
            <Route path="/survey" element={<Survey />}></Route>
            <Route path="/company" element={<Company />}></Route>
            <Route path="/companyUsers" element={<CompanyUser />}></Route>
            <Route path="/credits" element={<Credits />}></Route>
            <Route path="/test" element={<Test />}></Route>
            <Route path="/info" element={<ApplicationInfo />}></Route>
            <Route path="/answers" element={<InventoryAnswers />}></Route>
            <Route path="/ApplicationResults/" element={<ApplicationResults />}></Route>
            <Route path="/scope" element={<Scope />}></Route>
            <Route path="/subscope" element={<SubScope />}></Route>
            <Route path="/polar" element={<Polar />}></Route>
            <Route path="/likert" element={<Likert />}></Route>
            <Route path="/checkbox" element={<CheckBox />}></Route>
            <Route path="/testItem" element={<TestItem />}></Route>
            <Route path="/reorder" element={<ReorderQuestions />}></Route>
            <Route path="/reordercheckboxes" element={<ReorderCheckboxes />}></Route>
            <Route path="/match" element={<MatchQuestions />}></Route>
            <Route path="/basicGraphSummary" element={<BasicGraphSummary />}></Route>
            <Route path="/basicGraphDetail" element={<BasicGraphDetail />}></Route>
            <Route path="/monotextuary" element={<MonoTextuary />}></Route>
            <Route path="/dualtextuary" element={<DualTextuary />}></Route>
            <Route path="/scheme" element={<ReportScheme />}></Route>
            <Route path="/companyInfo" element={<CompanyInfo />}></Route>
          </Route>

          <Route element={<RequireAuth allowedRoles={["Admin"]} />}>
            <Route path="/adminCompanyInfo" element={<AdminCompanyInfo />}></Route>
            <Route path="/dash" element={<AdminDash />}></Route>

            <Route path="/createPosition" element={<CreatePosition />}></Route>
            <Route path="/position" element={<Position />}></Route>
            <Route path="/createCandidate" element={<CreateCandidate />}></Route>
            <Route path="/candidate" element={<Candidate />}></Route>
            <Route path="/project" element={<Project />}></Route>
            <Route path="/iwrapup/:inventoryResultId" element={<InventoryWrapUp />}></Route>
            <Route path="/twrapup/:testResultId" element={<TestWrapUp />}></Route>
          </Route>

          <Route element={<RequireAuth allowedRoles={["User"]} />}>
            <Route path="/userdash" element={<UserDash />}></Route>

            <Route path="/invapp/:invId/:ticketId" element={<B2bInventory />}></Route>
            <Route path="/testapp/:testId/:ticketId" element={<B2bTest />}></Route>
            <Route path="/iresult/:inventoryResultId" element={<InventoryWrapUp />}></Route>
            <Route path="/tresult/:testResultId" element={<TestWrapUp />}></Route>
            <Route path="/demographyForm" element={<DemographyForm />}></Route>
          </Route>

          <Route element={<RequireAuth allowedRoles={["SuperAdmin", "Admin"]} />}></Route>

          <Route element={<RequireAuth allowedRoles={["User", "Admin", "SuperAdmin"]} checkLogin={true} />}>
            <Route path="/Login" element={<Login />}></Route>
            <Route path="/Register" element={<Register />}></Route>
            <Route path="/Confirmation" element={<Confirmation />}></Route>
            <Route path="/" element={<LandingPage prevLocation={prevLocation} />}></Route>
          </Route>

          <Route path="/hashlogin/:hash" element={<HashLogin />}></Route>
          <Route path="/iapp/:invId" element={<DemoInventory />}></Route>
          <Route path="/tapp/:testId" element={<DemoTest />}></Route>
          <Route path="/idemoResult/:demoResultId" element={<InventoryWrapUp />}></Route>
          <Route path="/tdemoResult/:demoResultId" element={<TestWrapUp />}></Route>
        </Routes>
      </AppLayout>
    </HashRouter>
  );
}

export default App;
