import React from "react";
import IconSvg from "./IconSvg";
import { useTranslation } from "react-i18next";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useSelector } from "react-redux";

export default function MenuButton({ selected, page, HandleSideMenuButtonClick }) {
  const { t } = useTranslation();
  const windowSizeCssClass = useSelector((state) => state.appMenu.screenSize);
  const buttonClass = " text-color-gray-dark";
  const selectedClass = " selected text-color-accent";

  return (
    <Row
      className={
        `side-bar-button ${windowSizeCssClass} show-cursor text-20 ` + (selected === page ? selectedClass : buttonClass)
      }
      onClick={() => HandleSideMenuButtonClick(page)}
    >
      <Col xs={12} xl={"auto"} className={"align-content-center"}>
        <Row className={`justify-content-center side-bar-menu-icon ${windowSizeCssClass}`}>
          <IconSvg page={page.pageName} isSelected={selected === page} />
        </Row>
      </Col>
      {windowSizeCssClass === "large-screen" && (
        <Col xs={12} xl={"auto"} className={"align-content-center p-0 animateClass"}>
          {t(page.pageName)}
        </Col>
      )}
    </Row>
  );
}
