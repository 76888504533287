import React from "react";
import { Col, Row } from "react-bootstrap";
import IconSvg from "../../../../../AppMenu/Components/IconSvg";

export default function DualText({ item }) {
  let hitRange = item.scale.ranges.find((range) => range.isHit === true);

  return (
    <Row className={(!hitRange && "d-none") + " mx-1 mb-3"}>
      <div className={"round-30px p-2 "} style={{ backgroundColor: "#efefef" }}>
        <div className={"position-relative "}>
          <div className={"position-absolute"} style={{ top: -20, left: -4 }}>
            <IconSvg icon={"documentIcon"} height={30} width={30} />
          </div>

          <Row>
            <Col xs={6}>
              <div className={"round-30px p-2 h-100"} style={{ backgroundColor: "#ffffff" }}>
                {item.scale.isHTML ? <div dangerouslySetInnerHTML={{ __html: hitRange.text }} /> : hitRange.text}
              </div>
            </Col>
            <Col xs={6}>
              <div className={"round-30px p-2 h-100"} style={{ backgroundColor: "#ffffff" }}>
                {item.scale.isHTML ? (
                  <div dangerouslySetInnerHTML={{ __html: hitRange.rightText }} />
                ) : (
                  hitRange.rightText
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Row>
  );

  /*

  return item.scale.ranges.map((range, i) => {
    if (range.isHit)
      return (
        <Row className="mb-3 mx-0">
          <Col xs="6">
            <div
              style={{
                padding: "20px",
                background: "white",
                borderRadius: "20px",
                height: "100%",
              }}
            >
              {item.scale.isHtml ? <div dangerouslySetInnerHTML={{ __html: range.text }} /> : range.text}
            </div>
          </Col>
          <Col xs="6">
            <div
              style={{
                padding: "20px",
                background: "white",
                borderRadius: "20px",
                height: "100%",
              }}
            >
              {item.scale.isHtml ? <div dangerouslySetInnerHTML={{ __html: range.rightText }} /> : range.rightText}
            </div>
          </Col>
        </Row>
      );
  });

  */
}
