import { createAsyncThunk } from "@reduxjs/toolkit";

import LocalStorage from "../../../../Services/Storage/LocalStorage";
import StorageModel from "../../../../Models/StorageModel";
import HttpsClient from "../../../../Services/HttpsClient";
import ApiHelper from "../../../../Services/ApiHelper";
import Cache from "../../../../Services/Cache";
import AnsweringApplicationAPI, { AnsweringApplicationApiUrls } from "../API/AnsweringApplicationAPI";

const storage = new LocalStorage();
const storageModel = new StorageModel(storage);
const cache = new Cache(storageModel);

const httpsClient = new HttpsClient();
const apiHelper = new ApiHelper(httpsClient);
const answeringApplicationAPI = new AnsweringApplicationAPI(apiHelper, cache);

export const finishSession = createAsyncThunk(
  AnsweringApplicationApiUrls.finishSession,
  async (payload, { getState, dispatch }) => {
    const state = getState();
    const token = state.auth.user.token;

    return await answeringApplicationAPI.finishSession(payload, token, true, true);
  }
);
