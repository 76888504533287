import { SelectPdfReportItemDesign } from "../PdfReportItems/ReportItems/SelectPdfReportItemDesign";
import React from "react";
import PdfFooter from "./PdfPage/PdfFooter";
import PdfContent from "./PdfPage/PdfContent";
import PdfHeader from "./PdfPage/PdfHeader";
import "./PdfPage/Styles/PdfPage.css";

const PdfPage = ({ children, pageNumber, appName, personName, type }) => {
  //dimensions of a pdf =  792px X 1123px

  return (
    <div style={{ width: "792px", padding: "0 30px", backgroundColor: "white" }} className="jakarta-sans ">
      <PdfHeader personName={personName} appName={appName} />
      <PdfContent>
        {children.map((item, i) => {
          return SelectPdfReportItemDesign(item, type, i); // TODO: Determine when to randomize score..
        })}
      </PdfContent>
      <PdfFooter pageNumber={pageNumber}></PdfFooter>
    </div>
  );
};

export default PdfPage;
