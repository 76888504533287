import { createAsyncThunk } from "@reduxjs/toolkit";

import HttpsClient from "../../../../../../Services/HttpsClient";
import ApiHelper from "../../../../../../Services/ApiHelper";
import BasicGraphSummaryAPI, { BasicGraphSummaryApiUrls } from "../API/BasicGraphSummaryAPI";

const httpsClient = new HttpsClient();
const apiHelper = new ApiHelper(httpsClient);
const basicGraphSummaryAPI = new BasicGraphSummaryAPI(apiHelper);

export const createBasicGraphSummary = createAsyncThunk(
  BasicGraphSummaryApiUrls.createBasicGraphSummary,
  async (payload, { getState, dispatch }) => {
    const state = getState();
    const token = state.auth.user.token;
    await basicGraphSummaryAPI.createBasicGraphSummary(payload, token);
    dispatch(
      getBasicGraphSummaries(
        {
          id: state.basicGraphSummary.scopeId,
          isSub: state.basicGraphSummary.isSub,
        },
        token
      )
    );
  }
);

export const getBasicGraphSummaries = createAsyncThunk(
  BasicGraphSummaryApiUrls.getBasicGraphSummaries,
  async (payload, { getState, dispatch }) => {
    const state = getState();
    const token = state.auth.user.token;

    let data = await basicGraphSummaryAPI.getBasicGraphSummaries(payload, token);
    return data;
  }
);

export const deleteBasicGraphSummary = createAsyncThunk(
  BasicGraphSummaryApiUrls.deleteBasicGraphSummary,
  async (payload, { getState, dispatch }) => {
    const state = getState();
    const token = state.auth.user.token;
    await basicGraphSummaryAPI.deleteBasicGraphSummary(payload, token);
    dispatch(
      getBasicGraphSummaries(
        {
          id: state.basicGraphSummary.scopeId,
          isSub: state.basicGraphSummary.isSub,
        },
        token
      )
    );
  }
);

export const updateBasicGraphSummary = createAsyncThunk(
  basicGraphSummaryAPI.updateBasicGraphSummary,
  async (payload, { getState, dispatch }) => {
    const state = getState();
    const token = state.auth.user.token;
    await basicGraphSummaryAPI.updateBasicGraphSummary(payload, token);
    dispatch(
      getBasicGraphSummaries(
        {
          id: state.basicGraphSummary.scopeId,
          isSub: state.basicGraphSummary.isSub,
        },
        token
      )
    );
  }
);
