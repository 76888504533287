import React from "react";
import { Accordion, Col, Row } from "react-bootstrap";
import IconSvg from "../../../../../AppMenu/Components/IconSvg";
import { useDispatch, useSelector } from "react-redux";
import { updateReportSchemeState } from "../../Store";
import ApiHelper from "../../../../../../Helpers/ApiHelper";

export default function ImageSpace({ entity, UpdateScheme, children }) {
  const user = useSelector((state) => state.auth.user);

  const dispatch = useDispatch();
  const entityList = useSelector((state) => state.reportScheme.visibleReportItemEntityList);

  const deleteElement = async () => {
    let newList = JSON.parse(JSON.stringify(entityList));
    const res = await DeleteFromDb();

    if (res) {
      newList.splice(entity.order - 1, 1);
      newList.forEach((entity, i) => {
        entity.order = i + 1;
      });
      dispatch(updateReportSchemeState({ visibleReportItemEntityList: newList }));
      UpdateScheme(newList);
    }
  };

  let DeleteFromDb = async () => {
    // setShowOverlay(true);

    return new Promise(function (resolve, reject) {
      ApiHelper.getAsync(
        "report/DeleteImage",
        { fileName: entity.imageName },
        function (result) {
          if (result.data.statusCode == 1) resolve(true);
          else {
            alert("hata olustu sayfa yenile");
            resolve(false);
          }
        },
        user.token
      );
    }).then((val) => {
      return val;
    });
  };

  return (
    <>
      <Accordion className="mb-3">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <Row style={{ width: "100%" }}>
              <Col xs="6" md="6">
                <h5> Görsel</h5>
              </Col>
              <Col xs="6" md="4">
                <Row>
                  <Col></Col>
                  <Col>{entity.reportType}</Col>
                  <Col>
                    <h5
                      style={{
                        color: entity.orderedBefore && !entity.edited ? "green" : "red",
                      }}
                    >
                      {entity.order}
                    </h5>
                  </Col>
                </Row>
              </Col>
              <Col xs="6" md="2">
                <div className="report-item-delete-button" onClick={deleteElement}>
                  <IconSvg icon="trash-can" fill="#fff" />
                </div>
              </Col>
            </Row>
          </Accordion.Header>
          <Accordion.Body>
            <img src={ApiHelper.baseURL + "wwwroot/scheme/" + entity.imageName} />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      {children && children}
    </>
  );
}
