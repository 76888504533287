import React from "react";
import { Col, Row } from "react-bootstrap";
import { GetBeforeAndAfterHitRanges } from "../../Calculators/RangeSplitter";

export default function Detail_5({ item, isSub }) {
  let elementScore = item.normScore ? Number(item.normScore) : item.score;
  let splitRanges = GetBeforeAndAfterHitRanges(item.scale.ranges);

  return (
    <Row
      className={"justify-content-center align-content-start"}
      style={{ height: Number(item?.height), marginBottom: "6px" }}
    >
      {!isSub && (
        <Row className={"justify-content-center jakarta-sans"} style={{ fontSize: 15, fontWeight: 800 }}>
          {item.scopeName}
        </Row>
      )}

      <div className={"py-3 rounded-4 px-4"} style={{ backgroundColor: isSub ? "white" : "#0048aa" }}>
        <Row>
          <Col className={"align-content-center"}>
            <Row className={"detail-scope-name-blurred mb-1 px-3"}>{isSub && item.scopeName}</Row>
            <Row style={{ color: !isSub ? "white" : "" }} className={"main-scope-name-detail-pdf-new px-3 pb-2"}>
              {item.name}
            </Row>
          </Col>
        </Row>
        <Row>
          <Col className={"align-content-center _scale-detail-text-pdf-new"} xs={5}>
            <div
              style={{ color: !isSub ? "white" : "" }}
              dangerouslySetInnerHTML={{ __html: item.generalInformationText }}
            ></div>
          </Col>

          <Col xs={7} className={"align-content-center"}>
            <Row
              className={"justify-content-between round-30px border border-2"}
              style={{ borderColor: "#A6D1FF", padding: "0px 5px" }}
            >
              {splitRanges.beforeHitRangeList.map((range, i) => {
                return (
                  <Col key={i} xs={"auto"} className={"align-content-center p-0"}>
                    <div
                      style={{
                        backgroundColor: "white",
                        width: "fit-content",
                        height: "fit-content",
                        borderRadius: "50%",
                        padding: 0,
                      }}
                    >
                      <div
                        className={"scale-style-variable-opacity-pdf-round"}
                        style={{
                          opacity: (i + 1) * (1 / splitRanges.beforeHitRangeList.length),
                          backgroundColor: "#02a4ff",
                        }}
                      ></div>
                    </div>
                  </Col>
                );
              })}
              <Col xs={"auto"} className={" align-content-center p-0"}>
                {splitRanges.hitRange && (
                  <SmoothDiamondHitRange
                    width={40}
                    height={40}
                    score={elementScore}
                    isSub={isSub}
                  ></SmoothDiamondHitRange>
                )}
              </Col>
              {splitRanges.afterHitRangeList.map((range, i) => {
                return (
                  <Col key={i} xs={"auto"} className={"align-content-center p-0"}>
                    <div
                      className={"scale-style-variable-opacity-pdf-round"}
                      style={{ backgroundColor: "#eeeeee" }}
                    ></div>
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      </div>
    </Row>
  );
}

export const SmoothDiamondHitRange = ({ score, height, width, isSub }) => {
  return (
    <div
      className={"rotated-div-cube p-0"}
      style={{ width: width, height: height, backgroundColor: isSub ? "#284eaf" : "#007aff" }}
    >
      <div className={"straightened-div-cube align-content-center"} style={{ width: width, height: height }}>
        <Row className={"justify-content-center"}>
          <Col xs={"auto"} className={"hit-scale-style-variable-opacity-pdf p-0"} style={{ fontSize: "10px" }}>
            %{score}
          </Col>
        </Row>
      </div>
    </div>
  );
};
