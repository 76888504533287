import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Card from "react-bootstrap/Card";

const ApplicationCard = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.auth.user.companyUser.company.companyId);
  const companyCredits = useSelector((state) => state.adminDash.companyCredits);

  return (
    <Card className={"p-4 default-card position-relative"} style={{ height: "300px" }}>
      {props.children}
    </Card>
  );
};
export default ApplicationCard;
