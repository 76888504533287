import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { useTranslation } from "react-i18next";
import DesignTypeDropdown from "./DesignTypeDropdown";
import { updateReportItemBase } from "../Store";
import ScoringScale from "../../../ScoringScale";
import IconSvg from "../../../../../AppMenu/Components/IconSvg";

export default function ReportItemBase(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const base = useSelector((state) => state.reportItemBase);
  const scale = useSelector((state) => state.scale);

  const designTypeDropDownOnChange = (val) => {
    dispatch(updateReportItemBase({ key: "designType", value: val }));
  };

  const updateName = (key, value) => {
    dispatch(updateReportItemBase({ key, value }));
  };

  const createReportItem = () => {
    props.create(base, scale);
  };

  const isCreateDisabled = () => {
    if (base.name && base.designType !== null && scale.ranges.length) return false;
    else return true;
  };

  return (
    <div className="mb-3">
      <Row>
        <Col xs={12} md={5}>
          <InputGroup className="mb-3 me-3">
            <InputGroup.Text>{props.id ? <h6>{props.id}</h6> : t("reportItemName")}</InputGroup.Text>
            <Form.Control
              name="name"
              value={base.name}
              placeholder={t("reportItemNamePlaceHolder")}
              onChange={(e) => updateName("name", e.target.value)}
            />
          </InputGroup>
        </Col>
        <Col xs={12} md={4}>
          <DesignTypeDropdown
            keyword={props.keyword}
            designTypeDropDownOnChange={designTypeDropDownOnChange}
            selectedDesignType={base.designType}
          />
        </Col>
        <Col xs={12} md={1} className="mb-3">
          <Button style={{ width: "100%", height: "100%" }} variant="secondary" onClick={props.Clean}>
            <IconSvg icon="sweep" />
          </Button>
        </Col>
        <Col xs={12} md={2} className="mb-3">
          <Button
            style={{ width: "100%", height: "100%" }}
            variant="primary"
            disabled={isCreateDisabled()}
            onClick={createReportItem}
          >
            {props.id ? t("update") : t("create")}
          </Button>
        </Col>
      </Row>
      <ScoringScale />
    </div>
  );
}
