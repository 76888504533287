import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useEffect, useState } from "react";
import CreateProject from "./Tabs/CreateProject";
import ProjectHistory from "./Tabs/ProjectHistory";
import { useDispatch } from "react-redux";
import { SetScreenSizeCssClass } from "../../../AppMenu/Store";

const Project = () => {
  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState("createProject");
  useEffect(() => {
    dispatch(SetScreenSizeCssClass("small-screen"));
  }, []);

  return (
    <>
      <Row
        className={"tab-container-single-line text-20 text-color-gray-light fw-semibold"}
        style={{ height: "40px", margin: "0 12px 12px 12px" }}
      >
        <Col className={"align-content-center"}>
          <Row
            className={`tab-button justify-content-center h-100  ${currentTab === "createProject" && "selected"}`}
            style={{ height: "30px" }}
            onClick={() => {
              setCurrentTab("createProject");
            }}
          >
            <Col xs={"auto"} className={"align-content-center"}>
              Proje oluştur
            </Col>
          </Row>
        </Col>

        <Col className={"align-content-center"}>
          <Row
            className={`tab-button justify-content-center h-100  ${currentTab === "projectHistory" && "selected"}`}
            style={{ height: "30px" }}
            onClick={() => {
              setCurrentTab("projectHistory");
            }}
          >
            <Col xs={"auto"} className={"align-content-center"}>
              Gönderim geçmişi
            </Col>
          </Row>
        </Col>
      </Row>

      {currentTab === "createProject" && <CreateProject></CreateProject>}

      {currentTab === "projectHistory" && <ProjectHistory></ProjectHistory>}
    </>
  );
};
export default Project;
