import { configureStore } from "@reduxjs/toolkit";
import BasicGraphSummary from "../Features/SuperAdmin/CreateApplication/ReportItems/BasicGraphSummary/Store";
import BasicGraphDetail from "../Features/SuperAdmin/CreateApplication/ReportItems/BasicGraphDetail/Store";
import MonoTexruary from "../Features/SuperAdmin/CreateApplication/ReportItems/MonoTextuary/Store";
import DualTextuary from "../Features/SuperAdmin/CreateApplication/ReportItems/DualTextuary/Store";
import ReportItemBase from "../Features/SuperAdmin/CreateApplication/ReportItems/ReportItemBase/Store";
import Auth from "./Auth/index";
import ApplicationResults from "../Features/SuperAdmin/ApplicationResults/Store";
import ExportExcelAllFilter from "../Features/SuperAdmin/ExportExcelAllFilter/Store";
import Inventory from "../Features/SuperAdmin/CreateApplication/Inventory/Store";
import TestEntityList from "../Features/SuperAdmin/CreateApplication/TestEntityList/Store";
import Test from "../Features/SuperAdmin/CreateApplication/Test/Store";
import Scope from "../Features/SuperAdmin/CreateApplication/Scope/Store";
import Scale from "../Features/SuperAdmin/CreateApplication/ScoringScale/Store";
import ReportItem from "../Features/SuperAdmin/CreateApplication/ReportItems/ReportItem/Store";
import ReportScheme from "../Features/SuperAdmin/CreateApplication/ReportScheme/Store";
import PdfDownloadButton from "../Features/PdfDownloadButton/Store";
import WrapUp from "../Features/WrapUp/Store";
import Assessment from "../Features/SuperAdmin/CreateApplication/Assessment/Store";
import Survey from "../Features/SuperAdmin/CreateApplication/Survey/Store";
import Company from "../Features/SuperAdmin/CreateCompany/Company/Store";
import CompanyUser from "../Features/SuperAdmin/CreateCompany/CompanyUser/Store";
import Credits from "../Features/SuperAdmin/CreateCompany/Credits/Store";
import CreatePosition from "../Features/Admin/CreatePosition/Store";
import Position from "../Features/Admin/Position/Store";
import CreateCandidate from "../Features/Admin/CreateCandidate/Store";
import Candidate from "../Features/Admin/Candidate/Store";
import Project from "../Features/Admin/Project/Store";
import CompanyInfo from "../Features/SuperAdmin/CreateCompany/CompanyInfo/Store";
import AdminDash from "../Features/Admin/Dashboard/Store";
import UserDash from "../Features/User/UserDash/Store";
import Demography from "../Features/User/Demography/Store";
import AppMenu from "../Features/AppMenu/Store";
import AnsweringApplication from "../Features/AnsweringApplication/AnswerInventoryApp/Store";
import GeneralModals from "../Features/SharedComponents/GeneralModals/Store";
import Alerts from "../Features/SharedComponents/Alerts/Store";
//TODO: separate admin redux from superAdmin redux
export default configureStore({
  reducer: {
    auth: Auth,
    applicationResults: ApplicationResults,
    exportExcelAllFilter: ExportExcelAllFilter,
    inventory: Inventory,
    testEntityList: TestEntityList,
    test: Test,
    scope: Scope,
    scale: Scale,
    basicGraphSummary: BasicGraphSummary,
    basicGraphDetail: BasicGraphDetail,
    monoTextuary: MonoTexruary,
    dualTextuary: DualTextuary,
    reportItemBase: ReportItemBase,
    reportItem: ReportItem,
    reportScheme: ReportScheme,
    pdfDownloadButton: PdfDownloadButton,
    wrapUp: WrapUp,
    assessment: Assessment,
    survey: Survey,
    company: Company,
    companyUser: CompanyUser,
    credits: Credits,
    createPosition: CreatePosition,
    position: Position,
    createCandidate: CreateCandidate,
    projects: Project,
    adminDash: AdminDash,
    companyInfo: CompanyInfo,
    candidate: Candidate,
    userDash: UserDash,
    appMenu: AppMenu,
    answeringApplication: AnsweringApplication,
    selectedCandidateDemography: Demography,
    generalModals: GeneralModals,
    alerts: Alerts,
  },
});
