import React from "react";
import IconSvg from "./IconSvg";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export default function TopNavigation() {
  const { t } = useTranslation();

  const companyUsername = useSelector((state) => state.auth?.user?.companyUser?.companyUsername);
  const selectedPage = useSelector((state) => state.appMenu.selectedPage);
  const windowSizeCssClass = useSelector((state) => state.appMenu.screenSize);

  let size = window.innerWidth <= 500 ? "40" : "40";

  return (
    <Row className={"justify-content-between h-100"}>
      <Col xs={2} className={"align-content-center"}>
        <Row className={"justify-content-center"}>
          <Col xs={"auto"} className={"align-content-center"}>
            {/*<IconSvg icon="cp-logo" height={size} width={size} />*/}
            <IconSvg icon="cp-logo" height={size} width={size} fill={"#ffffff"} />
          </Col>
          <Col xs={"auto"} className={"text-32 text-color-white fw-medium hide-if-under-1200"}>
            Cus-Pro
          </Col>
        </Row>
      </Col>
      <Col xs={5} sm={6} lg={7} className={"align-content-center"}>
        <div>
          <Row className={"text-32 fw-medium text-color-white"}>{t(selectedPage.pageName)}</Row>
          <Row className={"text-16 fw-medium text-color-gray-light"}>{t(selectedPage.pageName)}</Row>
          {/*TODO: What do we write here ?*/}
        </div>
      </Col>
      <Col className={"align-content-center me-5"}>
        <Row className={"justify-content-end"}>
          <Col xs={"auto"} className={"px-0 align-content-center"}>
            <div className={"show-cursor p-1"}>
              <IconSvg icon={"search"}></IconSvg>
            </div>
          </Col>
          <Col xs={"auto"} className={"align-content-center"}>
            <div className={"show-cursor p-1"}>
              <IconSvg icon={"bell"}></IconSvg>
            </div>
          </Col>
          <Col xs={"auto"} className={"px-0 align-content-center"}>
            <div
              className={"rounded-circle align-content-center border border-1 border-white"}
              style={{ height: "70px", width: "70px" }}
            >
              {companyUsername}
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
