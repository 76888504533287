import React, { useEffect } from "react";

import Card from "react-bootstrap/Card";

import { useDispatch, useSelector } from "react-redux";
import ReportItemBreadCrumb from "./ReportItemBreadCrumb";
import ReportItemBase from "../../ReportItemBase";
import ReportItemEntityList from "./ReportItemEntityList";
import { updateReportItemState } from "../Store";
import { updateReportItemBase } from "../../ReportItemBase/Store";
import { deleteAllScales, setIsHtml } from "../../../ScoringScale/Store";
import Overlay from "../../../../../SharedComponents/Overlay";

export default function ReportItem({
  state,
  keyword,
  pageName,
  CreateFunction,
  UpdateFunction,
  DeleteFunction,
  entityList,
  isLoading,
  relatedComponent,
  UpdateRelatedState,
  CleanIfNecessary,
}) {
  const dispatch = useDispatch();
  const ReportItemState = useSelector((state) => state.reportItem);
  useEffect(() => {
    let isSub = state.subScope ? true : false;
    let scopeId = state.subScope ? state.subScope.id : state.scope.id;
    dispatch(updateReportItemState({ scopeId, isSub }));
    dispatch(updateReportItemState({ id: 0 }));
    dispatch(updateReportItemBase({ key: "designType", value: null }));
    dispatch(updateReportItemBase({ key: "name", value: "" }));
    dispatch(setIsHtml(false));
  }, []);

  const CreateOrUpdateReportItem = (base, scale) => {
    if (ReportItemState.id)
      UpdateFunction({
        id: ReportItemState.id,
        name: base.name,
        designType: parseInt(base.designType),
        scale: {
          isHtml: scale.isHtml,
          isDual: scale.isDual,
          ranges: scale.ranges,
        },
      });
    else
      CreateFunction({
        scopeId: state.subScope ? 0 : state.scope.id,
        subScopeId: state.subScope ? state.subScope.id : 0,
        name: base.name,
        designType: parseInt(base.designType),
        scale: {
          isHtml: scale.isHtml,
          isDual: scale.isDual,
          ranges: scale.ranges,
        },
      });
  };

  const CleanReportItem = () => {
    dispatch(updateReportItemBase({ key: "designType", value: null }));
    dispatch(updateReportItemBase({ key: "name", value: "" }));
    dispatch(setIsHtml(false));
    dispatch(deleteAllScales());
    dispatch(updateReportItemState({ id: 0 }));
    CleanIfNecessary && CleanIfNecessary();
  };

  return (
    <>
      {isLoading && <Overlay />}
      <ReportItemBreadCrumb state={state} pageName={pageName} />
      <Card body className={ReportItemState.id ? "mb-3 dashed-border" : "mb-3"}>
        <ReportItemBase
          keyword={keyword}
          create={CreateOrUpdateReportItem}
          id={ReportItemState.id}
          Clean={CleanReportItem}
        />

        {relatedComponent && relatedComponent}
      </Card>

      <ReportItemEntityList
        entityList={entityList}
        DeleteFunction={DeleteFunction}
        id={ReportItemState.id}
        UpdateStateId={updateReportItemState}
        UpdateRelatedState={UpdateRelatedState}
      />
    </>
  );
}
