import { createSlice } from "@reduxjs/toolkit";
import { createTickets, getCompanyCreateProjectData, getLastTenOrderRecords } from "./Thunk";

const SetReactSelectOptions = (state, action) => {
  state.reactSelectOptions.nameOptions = state.candidates.map((candidate) => ({
    value: candidate.candidateId,
    label: candidate.candidateName,
  }));
  state.reactSelectOptions.emailOptions = state.candidates.map((candidate) => ({
    value: candidate.candidateId,
    label: candidate.email,
  }));
  state.reactSelectOptions.positionOptions = state.positions.map((position) => ({
    value: position.id,
    label: position.name,
  }));
  state.reactSelectOptions.assessmentOptions = state.credits.map((credit) => ({
    value: credit.assessment.id,
    label: credit.assessment.name,
    creditId: credit.creditId,
  }));
};

const PushNewReactSelectOption = (state, action) => {
  state.candidates.push({
    candidateId: state.customId,
    candidateName: state.selectedOption.selectedNameOption.label,
    email: state.selectedOption.selectedEmailOption.label,
    positionId: state.selectedOption?.selectedPositionOption?.value
      ? state.selectedOption.selectedPositionOption.value
      : "",
    isNew: true,
  });

  SetReactSelectOptions(state, action);

  state.customId = null;
};

const CreateReactSelectOptionFromName = (state, action) => {
  state.selectedOption.selectedNameOption = {
    label: action.payload.label,
    value: state.customId,
    isNew: true,
    isDisabled: true,
  };
};

const CreateReactSelectOptionFromEmail = (state, action) => {
  state.selectedOption.selectedEmailOption = {
    label: action.payload.label,
    value: state.customId,
    isNew: true,
    isDisabled: true,
  };
};

export const projects = createSlice({
  name: "projects",
  initialState: {
    id: "",
    name: "",
    candidates: [],
    credits: [],
    positions: [],
    projectList: [],
    reactSelectOptions: { nameOptions: [], emailOptions: [], positionOptions: [], assessmentOptions: [] },
    customId: null,
    selectedOption: {
      selectedNameOption: null,
      selectedEmailOption: null,
      selectedPositionOption: null,
      selectedAssessmentOption: { array: [] },
    },
    projectSubmitModalIsShown: false,
    cameraRequirement: false,
    resultAccess: false,
    lastTenOrderRecords: [],

    isLoading: false,
  },
  reducers: {
    ChangeResultAccess(state, action) {
      state.resultAccess = !state.resultAccess;
    },
    ChangeCameraRequirement(state, action) {
      state.cameraRequirement = !state.cameraRequirement;
    },

    AddNewCandidate(state, action) {
      state.candidates = [...state.candidates, action.payload];
    },
    ChangeProjectSubmitModalIsShown(state, action) {
      state.projectSubmitModalIsShown = action.payload;
    },

    RemoveFromProjectList(state, action) {
      const candidateId = action.payload.candidateId;

      state.projectList = state.projectList.filter((project) => project.candidate.candidateId !== candidateId);
    },

    PushToProjectList(state, action) {
      if (state.selectedOption.selectedNameOption.isNew && state.selectedOption.selectedEmailOption.isNew) {
        PushNewReactSelectOption(state, action);
      }

      const selectedCandidateId =
        state.selectedOption.selectedEmailOption.value && state.selectedOption.selectedNameOption.value;

      const selectedCandidate = state.candidates.find((candidate) => candidate.candidateId === selectedCandidateId);

      const selectedPosition = state.positions.find((position) => position.id === selectedCandidate.positionId);

      const selectedCredits = state.credits.filter((credit) => {
        return state.selectedOption.selectedAssessmentOption.array.some((assessment) => {
          return assessment.creditId === credit.creditId;
        });
      });

      // Check if the candidate already exists in the project list
      const candidateExists = state.projectList.some(
        (project) => project.candidate.candidateId === selectedCandidate.candidateId
      );

      if (candidateExists) {
        // Throw an error (you can customize this to show an error message on the UI)
        alert("Candidate already exists in the project list.");
      } else {
        // Push the new project if the candidate does not exist
        state.projectList.push({
          candidate: selectedCandidate,
          position: selectedPosition,
          credit: selectedCredits, // this has creditId's
        });
      }

      state.selectedOption.selectedNameOption = null;
      state.selectedOption.selectedEmailOption = null;
      state.selectedOption.selectedPositionOption = null;
    },

    ClearDisabledSelectors(state, action) {
      state.selectedOption.selectedNameOption = null;
      state.selectedOption.selectedEmailOption = null;
      state.selectedOption.selectedPositionOption = null;
      state.selectedOption.selectedAssessmentOption = { array: [] };
    },

    SetSelectedPosition(state, action) {
      state.selectedOption.selectedPositionOption = { ...action.payload, isDisabled: true };
    },

    SetSelectedAssessment(state, action) {
      state.selectedOption.selectedAssessmentOption = { array: action.payload, isDisabled: false };
    },

    SetSelectedOptionByCandidateId(state, action) {
      if (action.payload.__isNew__) {
        // if candidate doesnt exist

        if (!state.customId) {
          state.customId = Date.now(); //this is because we do not want to set different ids to candidate email and name
        }

        switch (action.payload.type) {
          case "name":
            CreateReactSelectOptionFromName(state, action);
            break;
          case "email":
            CreateReactSelectOptionFromEmail(state, action);
            break;
          default: {
          }
        }
      } else {
        //if candidate exists
        const selectedCandidateId = action.payload.value;

        const selectedCandidate = state.candidates.find((candidate) => candidate.candidateId === selectedCandidateId);
        const selectedPosition = state.positions.find((position) => position.id === selectedCandidate.positionId);

        state.selectedOption.selectedNameOption = {
          label: selectedCandidate.candidateName,
          value: selectedCandidate.candidateId,
          isDisabled: true,
        };
        state.selectedOption.selectedEmailOption = {
          label: selectedCandidate.email,
          value: selectedCandidate.candidateId,
          isDisabled: true,
        };
        state.selectedOption.selectedPositionOption = {
          label: selectedPosition?.name,
          value: selectedPosition?.id,
          isDisabled: true,
        };

        state.selectedOption.selectedAssessmentOption = {
          ...state.selectedOption.selectedAssessmentOption,
          isDisabled: false,
        };
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCompanyCreateProjectData.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getCompanyCreateProjectData.fulfilled, (state, action) => {
      state.credits = action.payload.credits;
      state.positions = action.payload.positions;
      state.candidates = action.payload.candidates;

      if (state.projectList.length > 0) {
        //if projectList is present
        //add the new candidates from projectList to candidates state.

        state.projectList.forEach((project) => {
          if (project.candidate?.isNew) {
            state.candidates.push(project.candidate);
          }
        });
      }

      SetReactSelectOptions(state, action);
      state.isLoading = false;
    });

    builder.addCase(getLastTenOrderRecords.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getLastTenOrderRecords.fulfilled, (state, action) => {
      state.lastTenOrderRecords = action.payload.map((record) => {
        const {
          jsoN_SendApplicationsToCandidatesDto, // Extract and remove this
          jsoN_SuccesRegistrations, // Extract and remove this
          jsoN_UnSuccesRegistrations, // Extract and remove this
          ...rest // Keep the remaining properties
        } = record;

        return {
          ...rest, // Include all other properties
          sendApplicationsToCandidatesDto: JSON.parse(jsoN_SendApplicationsToCandidatesDto),
          successfulRegistrations: JSON.parse(jsoN_SuccesRegistrations),
          unsuccessfulRegistrations: JSON.parse(jsoN_UnSuccesRegistrations),
        };
      });
      state.isLoading = false;
    });

    builder.addCase(createTickets.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createTickets.fulfilled, (state, action) => {
      if (action.payload.statusCode === 1) {
        state.projectSubmitModalIsShown = false;
        state.selectedOption.selectedNameOption = null;
        state.selectedOption.selectedEmailOption = null;
        state.selectedOption.selectedPositionOption = null;
        state.selectedOption.selectedAssessmentOption = { array: [] };
        state.projectList = [];
      }
      state.isLoading = false;
    });
  },
});

export const {
  AddNewCandidate,
  ChangeProjectSubmitModalIsShown,
  SetSelectedOptionByCandidateId,
  ClearDisabledSelectors,
  SetSelectedPosition,
  SetSelectedAssessment,
  PushToProjectList,
  ChangeCameraRequirement,
  ChangeResultAccess,
  RemoveFromProjectList,
} = projects.actions;

export default projects.reducer;
