import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { ParseDate } from "../../../../../Helpers/DateHelper";
import { Button } from "react-bootstrap";
import IconSvg from "../../../../AppMenu/Components/IconSvg";
import AssignedAssessmentsListRowButtons from "./AssignedAssessmentsListRowButtons";

const AssignedAssessmentsListRow = ({ ticket }) => {
  return (
    <Card className={"default-card py-3 px-4 mb-3"}>
      <Row className={"justify-content-between"} style={{ borderBottom: "2px solid #e7ecef", padding: "0 12px" }}>
        <Col xs={"auto"} className={"text-20 text-color-gray-dark fw-medium"}>
          {ticket.assessmentName}
        </Col>
        <Col className={"text-16 text-color-gray-dark fw-medium align-content-center"}>
          <Row className={"justify-content-end"}>
            By: {ticket.createdBy} At: {ParseDate(ticket.createdAt).date} {ParseDate(ticket.createdAt).time}
          </Row>
        </Col>
        <Col xs={"auto"} className={"text-20 text-color-gray-dark fw-medium"}>
          <Row>
            <Col xs={"auto"}>
              <Button
                size={"sm"}
                className={"button secondary p-0"}
                style={{ height: 40, width: 40, borderRadius: "10px" }}
              >
                <IconSvg icon={"wrapUp"}></IconSvg>
              </Button>
            </Col>
            <Col xs={"auto"}>
              <Button
                size={"sm"}
                className={"button secondary p-0"}
                style={{ height: 40, width: 40, borderRadius: "10px" }}
              >
                <IconSvg icon={"pdf"}></IconSvg>
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className={"mx-0"}>
        <Col>
          <Row>
            <Col>
              {ticket.inventoryResults?.map((inventoryResult, i) => {
                const lastItem = i === ticket.inventoryResults.length - 1 && ticket.testResults.length === 0;
                return (
                  <Row
                    key={i + "I"}
                    className={"justify-content-between py-2"}
                    style={{
                      borderBottom: `2px solid ${lastItem ? "transparent" : "#e7ecef"}`,
                      marginRight: "-5px",
                    }}
                  >
                    <Col xs={"auto"} className={"text-18 text-color-gray-light fw-medium align-content-center"}>
                      {inventoryResult.inventoryName}
                    </Col>
                    <Col className={"align-content-center text-12 text-color-gray-light fw-medium"}>
                      <Row className={"justify-content-center"}>
                        <Col xs={"auto"}>
                          <Row> {inventoryResult.isFinished && "Tamamlandı"} </Row>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={"auto"}>
                      {inventoryResult.isFinished && (
                        <AssignedAssessmentsListRowButtons
                          type={"Inventory"}
                          result={inventoryResult}
                          color={"#8B8C89"}
                          size={35}
                        ></AssignedAssessmentsListRowButtons>
                      )}
                    </Col>
                  </Row>
                );
              })}
              {ticket.testResults?.map((testResult, i) => {
                const lastItem = i === ticket.testResults.length - 1 && ticket.surveyResults.length === 0;
                return (
                  <Row
                    key={i + "T"}
                    className={"justify-content-between py-2"}
                    style={{
                      borderBottom: `2px solid ${lastItem ? "transparent" : "#e7ecef"}`,
                      marginRight: "-5px",
                    }}
                  >
                    <Col xs={"auto"} className={"text-18 text-color-gray-light fw-medium align-content-center"}>
                      {testResult.testName}
                    </Col>
                    <Col className={"align-content-center text-12 text-color-gray-light fw-medium"}>
                      <Row className={"justify-content-center"}>
                        <Col xs={"auto"}>
                          <Row> {testResult.isFinished && "Tamamlandı"} </Row>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={"auto"} className={"text-18 text-color-gray-light fw-medium"}>
                      {testResult.isFinished && (
                        <AssignedAssessmentsListRowButtons
                          type={"Test"}
                          result={testResult}
                          color={"#8B8C89"}
                          size={35}
                        ></AssignedAssessmentsListRowButtons>
                      )}
                    </Col>
                  </Row>
                );
              })}
              {ticket.surveyResults?.map((surveyResult, i) => {
                const lastItem = i === ticket.surveyResults.length - 1;
                return (
                  <Row
                    key={i + "S"}
                    className={"justify-content-between py-2"}
                    style={{
                      borderBottom: `2px solid ${lastItem ? "transparent" : "#e7ecef"}`,
                      marginRight: "-5px",
                    }}
                  >
                    <Col xs={"auto"} className={"text-18 text-color-gray-light fw-medium align-content-center"}>
                      {surveyResult.surveyName}
                    </Col>
                    <Col className={"align-content-center text-12 text-color-gray-light fw-medium"}>
                      <Row className={"justify-content-center"}>
                        <Col xs={"auto"}>
                          <Row> {surveyResult.isFinished && "Tamamlandı"} </Row>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={"auto"} className={"text-18 text-color-gray-light fw-medium"}>
                      {surveyResult.isFinished && (
                        <AssignedAssessmentsListRowButtons
                          result={surveyResult}
                          color={"#8B8C89"}
                          size={35}
                        ></AssignedAssessmentsListRowButtons>
                      )}
                    </Col>
                  </Row>
                );
              })}
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};
export default AssignedAssessmentsListRow;
