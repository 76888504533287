import React from "react";
import { Card, Col, Row } from "react-bootstrap";

export default function ScaleEntity({ scale, testScaleRanges }) {
  const HandleIsHtml = (text) => {
    if (scale !== undefined && scale.isHtml)
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        />
      );
    else return text;
  };
  const HandleRanges = (ranges) => {
    return ranges.map((range, i) => (
      <Col key={i}>
        <Card>
          <Card.Body>
            <h5>
              {range.start} - {range.end}
            </h5>
            <Row>
              <Col>{HandleIsHtml(range.text)} </Col>
              {scale !== undefined && scale.isDual && <Col> {HandleIsHtml(range.rightText)}</Col>}
            </Row>
          </Card.Body>
        </Card>
      </Col>
    ));
  };

  let ranges = [];
  if (testScaleRanges) {
    ranges = testScaleRanges;
  } else {
    ranges = scale.ranges;
  }
  return (
    <div>
      <Row>{HandleRanges(ranges)}</Row>
    </div>
  );
}
