import { createSlice } from "@reduxjs/toolkit";
import { getDemographyByCandidateId, updateDemography } from "./Thunk";

export const selectedCandidateDemography = createSlice({
  name: "selectedCandidateDemography",
  initialState: {
    Id: 1, // bu ne olacak?
    fetchedName: "",
    Email: "asd",
    fetchedDemography: null,

    isShown: false,
    isLoading: false,
  },
  reducers: {
    SetSelectedCandidateDemographyId: (state, action) => {
      state.Id = action.payload;
    },
    SetSelectedCandidateDemographyIsVisible: (state, action) => {
      state.isVisible = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDemographyByCandidateId.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getDemographyByCandidateId.fulfilled, (state, action) => {
      state.isLoading = false;
      state.fetchedName = action.payload.name;
      state.fetchedDemography = action.payload;
    });

    builder.addCase(updateDemography.pending, (state, action) => {
      state.isLoading = true;
      console.log(action.payload);
    });
    builder.addCase(updateDemography.fulfilled, (state, action) => {
      state.fetchedDemography = null;
      state.fetchedName = "";

      state.isLoading = false;
    });
  },
});

export const { SetSelectedCandidateDemographyIsVisible, SetSelectedCandidateDemographyId } =
  selectedCandidateDemography.actions;

export default selectedCandidateDemography.reducer;
