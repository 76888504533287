import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Row } from "react-bootstrap";
import IconSvg from "../../../AppMenu/Components/IconSvg";

const ConfirmationModal = ({ isShown, setIsShown, text, confirmationFunction, dismissFunction }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const windowSizeCssClass = useSelector((state) => state.appMenu.screenSize);

  const handleConfirm = () => {
    confirmationFunction();

    setIsShown(false);
  };

  const handleDismiss = () => {
    if (dismissFunction) dismissFunction();

    setIsShown(false);
  };

  return (
    <Modal
      size={"md"}
      dialogClassName={`custom-modal-dialog ${windowSizeCssClass}`}
      contentClassName={"custom-modal-content secondary"}
      show={isShown}
      onHide={() => {
        handleDismiss();
      }}
    >
      <Row className={"justify-content-center"}>
        <Col xs={"auto"}>
          <IconSvg icon={"confirmationModalHeader"}></IconSvg>
        </Col>
      </Row>
      <Row className={"justify-content-center mb-3"}>
        <Col xs={"auto"} className={"text-50 text-color-gray-dark fw-medium"}>
          {t("areYouSureModalHeader")}
        </Col>
      </Row>
      <Row className={"justify-content-center mb-3"}>
        <Col xs={"auto"} className={"text-20 text-color-gray-dark fw-medium"}>
          {text ? text : t("confirmationModalConditionalText")}
        </Col>
      </Row>
      <Row className={"justify-content-center"}>
        <Col>
          <Row className={"mx-0"}>
            <Button
              className={"button secondary"}
              style={{ height: "50px" }}
              onClick={() => {
                handleDismiss();
              }}
            >
              {t("confirmationModalDismissButtonLabel")}
            </Button>
          </Row>
        </Col>
        <Col>
          <Row className={"mx-0"}>
            <Button
              className={"button primary"}
              style={{ height: "50px" }}
              onClick={() => {
                handleConfirm();
              }}
            >
              {t("confirmationModalConfirmButtonLabel")}
            </Button>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};
export default ConfirmationModal;
