import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container } from "react-bootstrap";

import ApiHelper from "../../../Helpers/ApiHelper";
import ReportItemMapper from "./ReportItemMapper";
import Overlay from "../../SharedComponents/Overlay";
import { useSelector } from "react-redux";

import "../Styles/index.css";
import "../Styles/WrapUp.css";
import "animate.css";

import WrapUpHeader from "./WrapUpHeader";
import TestPdfButton from "../../PdfDownloadButton/Components/TestPdfButton";

export default function TestWrapUp() {
  const user = useSelector((state) => state.auth.user);
  const { testResultId } = useParams();
  const { demoResultId } = useParams();
  const [appData, setAppData] = useState([]);
  const [resultData, setResultData] = useState(null);

  const [showOverlay, setShowOverlay] = useState(true);

  useEffect(() => {
    if (demoResultId || testResultId) {
      const id = demoResultId ? demoResultId : testResultId;
      const endPoint = demoResultId ? "GetTestDemoCalculation" : "GetTestCalculation";
      const url = "ticket/" + endPoint;
      ApiHelper.getAsync(
        url,
        { id },
        function (response) {
          if (response.data.statusCode) MapResponse(response.data.data);
          else alert("bir hata oluştu"); //todo burada hatayi handle et , guzek bir alert kutusu goster gerekirse sayfayi kapat
        },
        user.token
      );
    } else {
      setShowOverlay(false);
      alert("problem");
    }
  }, []);

  const CreateQrUrl = () => {
    let qrURL = "";
    if (demoResultId) qrURL = window.location.origin + "/#/tdemoResult/" + demoResultId;
    else if (testResultId) qrURL = window.location.origin + "/#/tResult/" + testResultId;
    return qrURL;
  };

  let MapResponse = (resultData) => {
    setResultData(resultData);
    const scheme = JSON.parse(resultData.wrapUpJson);
    const calculation = JSON.parse(resultData.calculationJson);
    const calculatedScopes = calculation?.calculatedScopes;

    scheme.forEach((reportItem) => {
      if (reportItem.htmlEl) return;
      else if (reportItem.scales) {
        reportItem.score = calculation.testScore;
      } else reportItem.score = calculatedScopes.find((x) => x.scopeId === reportItem.scopeId)?.score;
    });

    setAppData({ scheme, resultData });
    setShowOverlay(false);
  };

  return (
    <Container>
      {showOverlay && <Overlay text=" Sonuçlar hazırlanıyor" />}

      <WrapUpHeader resultData={appData.resultData} CreateQrUrl={CreateQrUrl}>
        <TestPdfButton demoResultId={demoResultId} testResultId={testResultId} resultData={resultData} />
      </WrapUpHeader>

      <ReportItemMapper calculatedScheme={appData.scheme} type={"test"} />
    </Container>
  );
}
