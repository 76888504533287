import React from "react";
import { Button, ButtonGroup, Col, Row } from "react-bootstrap";
import { updateShowButtons } from "../Store";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CreateQR from "../../SharedComponents/CreateQR";

export default function WrapUpHeader({ children, resultData, CreateQrUrl }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const wrapUpState = useSelector((state) => state.wrapUp);

  const IsAllShown = () => {
    if (wrapUpState.Summary && wrapUpState.Detail && wrapUpState.Mono && wrapUpState.Dual) return true;
    else return false;
  };

  const UpdateReportItemsFilteringButtons = (property, value) => {
    let newState = {};
    if (property === "showAll") {
      for (const [key, value] of Object.entries(wrapUpState)) newState[key] = true;
    } else
      for (const [key, value] of Object.entries(wrapUpState)) {
        if (key === property) newState[key] = true;
        else newState[key] = false;
      }

    dispatch(
      updateShowButtons({
        newState,
      })
    );
  };

  return (
    <Row className="mb-2 justify-content-between" style={{ marginTop: "90px" }}>
      <Col xs={"auto"}>
        <Row>
          <Col className={"align-content-center"}>
            <CreateQR size={64} value={CreateQrUrl()} />
          </Col>
          <Col className={"align-content-center"}>{<h1> {resultData?.applicationName} </h1>}</Col>
        </Row>
      </Col>

      <Col xs={5} className="flex-center d-none">
        <ButtonGroup>
          <Button
            variant={IsAllShown() ? "primary" : "secondary"}
            onClick={() => UpdateReportItemsFilteringButtons("showAll")}
          >
            Hepsi
          </Button>
          <Button
            variant={!IsAllShown() && wrapUpState.Summary ? "primary" : "secondary"}
            onClick={() => UpdateReportItemsFilteringButtons("Summary")}
          >
            Özet
          </Button>
          <Button
            variant={!IsAllShown() && wrapUpState.Detail ? "primary" : "secondary"}
            onClick={() => UpdateReportItemsFilteringButtons("Detail")}
          >
            Detay
          </Button>
          <Button
            variant={!IsAllShown() && wrapUpState.Mono ? "primary" : "secondary"}
            onClick={() => UpdateReportItemsFilteringButtons("Mono")}
          >
            Mono
          </Button>
          <Button
            variant={!IsAllShown() && wrapUpState.Dual ? "primary" : "secondary"}
            onClick={() => UpdateReportItemsFilteringButtons("Dual")}
          >
            Dual
          </Button>
        </ButtonGroup>
      </Col>
      <Col xs={"auto"} className={"align-content-center"}>
        <h2>{resultData?.applicationName}</h2>
      </Col>

      <Col xs={"auto"}>
        <div className="result-page-summary-download-pdf-button-col animate__animated animate__flash animate__delay-1s">
          {children}
        </div>
      </Col>
    </Row>
  );
}
