import React, { useEffect, useState } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

export function ScoringScale(props) {
  const [start, setStart] = useState(0);

  const sortScalesProperty = (scalesArray) => {
    return scalesArray.sort((firstScale, secondScale) => {
      if (firstScale.start !== secondScale.start) {
        return firstScale.start - secondScale.start;
      }
      return firstScale.end - secondScale.end;
    });
  };

  const [dataArr, setDataArr] = useState(props.data ? sortScalesProperty(props.data) : []);
  const [data, setData] = useState({
    text: null,
    end: null,
  });
  const [textInputTarget, setTextInputTarget] = useState();
  const [endInputTarget, setEndIntputTarget] = useState();
  useEffect(() => {
    if (props.clean) {
      CleanDataArr();
    }
  });
  useEffect(() => {
    if (props.data?.length > 0) setDataArr(sortScalesProperty(props.data));
  }, [props.data]);

  const SetScoringData = (target, type) => {
    let val = target.value;
    if (type === "text") {
      setData((d) => ({ ...d, text: val }));
      !textInputTarget && setTextInputTarget(target);
    } else if (type === "end") {
      if (Number(val) > 100) {
        endInputTarget.value = null;
      } else if (Number(val) < Number(start)) {
        setData((d) => ({ ...d, end: null }));
      } else {
        setData((d) => ({ ...d, end: val }));
        !endInputTarget && setEndIntputTarget(target);
      }
    }
  };

  const HandleSettingDataArr = () => {
    const d = { ...data, start };
    setDataArr((arr) => sortScalesProperty([...arr, d]));
    setStart(data.end);
    setData({ text: null, end: null });
    textInputTarget.value = null;
    endInputTarget.value = null;
  };

  const CleanDataArr = () => {
    setDataArr([]);
    setStart(0);
    setData({ text: null, end: null });
    props.HandleAddScoreScale(null);
    if (!props.data) {
      endInputTarget.value = null;
      textInputTarget.value = null;
    }
  };

  const HandleDisable = () => {
    if (dataArr.length && parseInt(dataArr[dataArr.length - 1].end) === 100 && !props.scale?.length) return false;

    return true;
  };

  return (
    <div>
      <Row>
        <Col md={6} lg={5}>
          <InputGroup size="sm" className="mb-3">
            <InputGroup.Text id="inputGroup-sizing-sm">Text</InputGroup.Text>
            <Form.Control
              onChange={(e) => {
                SetScoringData(e.target, "text");
              }}
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
              disabled={dataArr.length && dataArr[dataArr.length - 1].end === 100 ? true : false}
            />
          </InputGroup>
        </Col>
        <Col md={6} lg={2}>
          <InputGroup size="sm" className="mb-3">
            <InputGroup.Text id="inputGroup-sizing-sm">Başlangıç</InputGroup.Text>
            <Form.Control
              type="number"
              readOnly
              value={start}
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
              disabled={true}
            />
          </InputGroup>
        </Col>
        <Col md={6} lg={2}>
          <InputGroup size="sm" className="mb-3">
            <InputGroup.Text id="inputGroup-sizing-sm">Bitiş</InputGroup.Text>
            <Form.Control
              aria-describedby="inputGroup-sizing-sm"
              aria-label="Small"
              type="number"
              min={start}
              disabled={dataArr.length && dataArr[dataArr.length - 1].end === 100 ? true : false}
              onChange={(e) => {
                SetScoringData(e.target, "end");
              }}
            />
          </InputGroup>
        </Col>
        <Col md={6} lg={1}>
          <Button
            variant="primary"
            size="sm"
            style={{ width: "100%" }}
            disabled={data.text && data.end && Number(data.end) > Number(start) ? false : true}
            onClick={HandleSettingDataArr}
          >
            Ekle
          </Button>
        </Col>
        <Col md={6} lg={2}>
          <Button
            variant="primary"
            size="sm"
            style={{ width: "100%" }}
            disabled={HandleDisable()}
            onClick={() => props.HandleAddScoreScale(dataArr)}
          >
            Tamamla
          </Button>
        </Col>
      </Row>
      {dataArr &&
        dataArr.map((d, i) => {
          return (
            <span key={i} className="scale-values-card">
              {d.start} - {d.end} / {d.text}
            </span>
          );
        })}
      {dataArr.length !== 0 ? (
        <span className="scale-delete-button" onClick={CleanDataArr}>
          Temizle
        </span>
      ) : (
        "Lütfen değerlendirme parametrelerini ekleyiniz"
      )}
    </div>
  );
}
