import React, { useEffect, useState } from "react";
import Overlay from "../../../SharedComponents/Overlay";
import AnsweringPageFooter from "../../SharedComponents/AnswerIngPageFooter";
import FinishingModal from "../../SharedComponents/FinishingModal";
import { TestItemImage } from "./TestItemImage";
import { Counter } from "./Counter";
import "animate.css";

export function QuestionSlider({
  isB2b,
  questions,
  duration,
  openCam,
  finish,
  dismissFinish,
  Update,
  UpdateDurationAndPhoto,
  isFinished,
  HandleFinishButton,
}) {
  useEffect(() => window.scrollTo({ top: 0, behavior: "smooth" }));

  let percentage = (window.questionNo / questions.length) * 100 + "%";

  const [current, setCurret] = useState();
  const [maxQuestionNumber, setMaxQuestionNumber] = useState(0);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [finishModalShow, setFinishModalShow] = useState(false);
  const [isMediaRunning, setIsMediaRunning] = useState(false);
  const [stopCounter, setStopCounter] = useState(false);

  let AnswerinFooterNextButtonProps = {
    func: null,
    text: null,
    isDisabled: null,
  };

  function timeout(delay) {
    return new Promise((res) => setTimeout(res, delay));
  }

  let NextQuestion = (n) => {
    if (window.questionNo + 1 > questions.length || n > questions.length) return;
    if (n === false) renderQuestion(window.questionNo + 1);
    else renderQuestion(n);
  };
  let PrevQuestion = () => {
    if (window.questionNo - 1 === 0) {
    } else if (maxQuestionNumber - 1 < window.questionNo) {
      renderQuestion(window.questionNo - 1);
    }
  };
  let SetEmptyQuestion = () => {
    SetAnswer(window.questionNo, "b", window.questionNo + 1, false, 0);
  };

  let Finish = () => {
    if (duration) setStopCounter(true);
    finish(questions);
    setFinishModalShow(false);
  };

  let DismisFinish = () => {
    dismissFinish(questions);
    setFinishModalShow(false);
  };

  let SetMediaClick = (order, mediaClickCount) => {
    questions.map((q) => {
      if (q.order === order) {
        q.mediaClickCount = mediaClickCount;
      }
    });
  };

  let SetUnAnsweredAnswerTime = (order, timer) => {
    questions.map((q) => {
      if (q.order === order) {
        if (q.timer) q.timer = q.timer + timer;
        else q.timer = timer;
      }
    });
  };

  let SetAnswer = async (order, option, n, isPolar, timer) => {
    if (window.innerWidth <= 500 || isPolar) setIsOverlayVisible(true);
    questions.map((q) => {
      if (q.order === order) {
        q.answer = option;
        if (q.timer) q.timer = q.timer + timer;
        else q.timer = timer;
      }
    });
    isB2b && Update(questions);
    NextQuestion(n);
    if (window.innerWidth <= 500 || isPolar) await timeout(200);
    if (window.innerWidth <= 500 || isPolar) setIsOverlayVisible(false);

    if (window.isFinished || questions.length === n - 1) {
      setFinishModalShow(true);
    } else if (questions.length === n) {
      window.isFinished = true;
      window.lastAnswerNo = n;
    } else {
      window.lastAnswerNo = n;
    }
  };

  let renderQuestion = (n) => {
    if (n === 1) {
      //uygulama onceden baslami mi kontrol ediliyor, baslamis ise kalinan sira bulunuyor
      let startedBefore = questions.find((x) => {
        return x.answer !== undefined;
      });

      let last = questions.find((x) => {
        return x.answer === undefined;
      });

      if (last) n = last.order;
      else if (!last && startedBefore) {
        n = questions.length;
        window.isFinished = true;
        setFinishModalShow(true);
      }
    }
    if (maxQuestionNumber < n) setMaxQuestionNumber(n);
    const image = questions.find((x) => x.order === n);
    window.questionNo = n;
    setCurret(
      <TestItemImage
        q={image}
        n={n}
        SetAnswer={SetAnswer}
        SetMediaClick={SetMediaClick}
        _isMediaRunning={isMediaRunning}
        _setIsMediaRunning={setIsMediaRunning}
        _startTime={new Date()}
        SetUnAnsweredAnswerTime={SetUnAnsweredAnswerTime}
      />
    );
  };

  let HandleNextButtonFunctionAndText = () => {
    if (
      isFinished &&
      (window.lastAnswerNo === undefined || window.lastAnswerNo <= window.questionNo || window.isFinished)
    ) {
      AnswerinFooterNextButtonProps = {
        func: HandleFinishButton,
        text: "Uygulamayı Bitir",
      };
    } else {
      AnswerinFooterNextButtonProps = {
        func: SetEmptyQuestion,
        text: "Boş Bırak",
      };
    }
  };

  /*let CloseApplication = async () => {
    setIsOverlayVisible(true);
    await timeout(400);        todo: remove if safe
    window.close();
  };*/

  HandleNextButtonFunctionAndText();

  return (
    <>
      {isOverlayVisible && <Overlay text="Lütfen Bekleyiniz" />}
      <FinishingModal show={finishModalShow} Finish={Finish} DismisFinish={DismisFinish} />
      {/*{isB2b && (
        <Button variant="warning" className="save-and-exit-button" onClick={CloseApplication}> // todo: remove if safe
          Kaydet ve Çık
        </Button>
      )}*/}
      {duration && (
        <Counter
          duration={duration}
          Update={UpdateDurationAndPhoto}
          Finish={Finish}
          isB2b={isB2b}
          openCam={openCam}
          stopCounter={stopCounter}
        />
      )}
      <h4 className="test-question-no-at-heder">
        {window.questionNo} / {questions.length}
      </h4>
      <div className="test-question-progression-container" style={{ position: "fixed" }}>
        <div className="test-question-progression-bar" style={{ width: percentage, position: "fixed" }}></div>
      </div>
      <div className="animate__animated  animate__fadeInDown">
        {!current && questions && renderQuestion(1)}
        {current}
      </div>
      <div style={{ display: isMediaRunning ? "none" : null }}>
        <AnsweringPageFooter
          prevButtonFunction={PrevQuestion}
          prevButtonText="Önceki"
          nextButtonText={AnswerinFooterNextButtonProps.text}
          nextButtonFunction={AnswerinFooterNextButtonProps.func}
          isNextDisabled={false}
        />
      </div>
    </>
  );
}
