import { createSlice } from "@reduxjs/toolkit";
import { createCandidate_, deleteCandidate, getCandidatesByCompanyId, updateCandidate } from "./Thunk";
import validator from "validator";

export const createCandidate = createSlice({
  name: "createCandidate",
  initialState: {
    id: "",
    candidate: {
      candidateName: { value: null, isValid: true },
      email: { value: null, isValid: true },
      phoneNumber: { value: null, isValid: true },
      positionId: { value: null, isValid: true },
    },
    candidateList: [],
    upsertCandidateModalIsShown: false,

    isLoading: false,
  },
  reducers: {
    UpdateCompanyId(state, action) {
      state.companyId = action.payload.companyId;
    },
    UpdateUpsertCandidateModalData(state, action) {
      state.candidate = action.payload;
    },
    ClearUpsertCandidateModalData(state, action) {
      state.candidate = {
        candidateName: { value: null, isValid: true },
        email: { value: null, isValid: true },
        phoneNumber: { value: null, isValid: true },
        positionId: { value: null, isValid: true },
      };
    },
    ShowUpsertCandidateModal(state, action) {
      state.upsertCandidateModalIsShown = action.payload.upsertCandidateModalIsShown;
    },
    SetUpsertModalCandidate(state, action) {
      const payload = action.payload;

      // Iterate over each key in the payload and structure it with value and isValid
      for (const [key, value] of Object.entries(payload)) {
        state.candidate[key] = {
          value: value,
          isValid: true,
        };
      }
    },
    HandleEditCandidate(state, action) {
      if (!state.candidate[action.payload.variableName]) {
        state.candidate[action.payload.variableName] = { value: null, isValid: false }; // Initialize with default structure
      }

      state.candidate[action.payload.variableName].value = action.payload.value;

      switch (action.payload.variableName) {
        case "phoneNumber": {
          state.candidate[action.payload.variableName].isValid =
            validator.isMobilePhone(action.payload.value) || action.payload.value.length === 0;
          break;
        }
        case "email": {
          state.candidate[action.payload.variableName].isValid = validator.isEmail(action.payload.value);
          break;
        }
        case "candidateName": {
          state.candidate[action.payload.variableName].isValid = action.payload.value.length > 0;
          break;
        }
        default: {
          state.candidate[action.payload.variableName].isValid = true;
        }
      }

      //candidate?.email, candidate?.name, candidate?.phoneNumber, candidate?.positionId,
    },
    ChangeIsUpdateOperation(state, action) {
      state.isUpdateOperation = action.payload.isUpdateOperation;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createCandidate_.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createCandidate_.fulfilled, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(updateCandidate.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updateCandidate.fulfilled, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(deleteCandidate.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deleteCandidate.fulfilled, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(getCandidatesByCompanyId.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getCandidatesByCompanyId.fulfilled, (state, action) => {
      state.candidateList = action.payload;
      state.isLoading = false;
    });
  },
});

export const {
  SetUpsertModalCandidate,
  UpdateUpsertCandidateModalData,
  ShowUpsertCandidateModal,
  ChangeIsUpdateOperation,
  HandleEditCandidate,
  ClearUpsertCandidateModalData,
} = createCandidate.actions;

export default createCandidate.reducer;
