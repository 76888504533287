import { useSelector } from "react-redux";
import { useState } from "react";
import AssignedAssessmentsListRow from "./AssignedAssessmentsListRow";
import { Col, Row } from "react-bootstrap";

const AssignedAssessmentsList = () => {
  const tickets = useSelector((state) => state.candidate.candidate.tickets);
  const [windowHeight, setWindowHeight] = useState();

  const reversedTickets = tickets.slice().reverse();

  return (
    <Row className={"mx-0 mt-3"}>
      <Col>
        {reversedTickets?.map((ticket, index) => {
          return <AssignedAssessmentsListRow ticket={ticket} key={index}></AssignedAssessmentsListRow>;
        })}
      </Col>
    </Row>
  );
};
export default AssignedAssessmentsList;
