import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  ChangeIsUpdateOperation,
  HandleEditPosition,
  ShowUpsertPositionModal,
  UpdateUpsertPositionModalData,
} from "../Store";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { createPosition_, deletePosition, updatePosition } from "../Store/Thunk";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import ConfirmationModal from "../../../SharedComponents/GeneralModals";
import { useNavigate } from "react-router-dom";

const UpsertPositionModal = ({ companyId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const companyUserId = useSelector((state) => state.auth.user.companyUser.companyUserId);
  const position = useSelector((state) => state.createPosition.upsertPositionModalData);
  const isUpdateOperation = useSelector((state) => state.createPosition.isUpdateOperation);
  const isShown = useSelector((state) => state.createPosition.upsertPositionModalIsShown);
  const windowSizeCssClass = useSelector((state) => state.appMenu.screenSize);

  const [fetchedInputs, setFetchedInputs] = useState({ fetchedName: null, fetchedDescription: null });
  const [confirmationModalIsShown, setConfirmationModalIsShown] = useState(false);
  const [positionToDelete, setPositionToDelete] = useState(null);

  const ClearUpsertPositionModalData = () => {
    dispatch(
      UpdateUpsertPositionModalData({
        name: "",
        description: "",
      })
    );
    dispatch(ShowUpsertPositionModal({ upsertPositionModalIsShown: false }));
    dispatch(ChangeIsUpdateOperation({ isUpdateOperation: false }));
    setFetchedInputs({ fetchedName: null, fetchedDescription: null });
    setPositionToDelete(null);
  };

  const DeletePosition = (id) => {
    setPositionToDelete({ id: id });
    dispatch(ShowUpsertPositionModal({ upsertPositionModalIsShown: false }));
    setConfirmationModalIsShown(true);
  };

  useEffect(() => {
    if (isUpdateOperation) {
      setFetchedInputs({ fetchedName: position.name?.value, fetchedDescription: position.description?.value });
    }
  }, [isUpdateOperation]);

  const HandleSubmit = (e) => {
    e.preventDefault();

    if (isUpdateOperation) {
      dispatch(
        updatePosition({
          positionId: position.id,
          name: position.name.value,
          description: position.description.value,
        })
      );
    } else {
      dispatch(
        createPosition_({
          name: position.name.value,
          companyId: companyId,
          companyUserId: companyUserId,
          description: position.description.value,
        })
      );
    }

    ClearUpsertPositionModalData();
  };

  const ConfirmationFunction = () => {
    dispatch(deletePosition(positionToDelete));
    navigate("/createPosition");
    ClearUpsertPositionModalData();
  };
  const IsDisabled = () => {
    if (!position.name?.value) return true;

    const isNameUnchanged = fetchedInputs.fetchedName === position.name?.value;
    const isDescriptionUnchanged = fetchedInputs.fetchedDescription === position.description?.value;

    if (isNameUnchanged && isDescriptionUnchanged) return true;

    return false;
  };

  return (
    <>
      <ConfirmationModal
        isShown={confirmationModalIsShown}
        setIsShown={setConfirmationModalIsShown}
        text={"Pozisyon silinecek"}
        confirmationFunction={ConfirmationFunction}
        dismissFunction={ClearUpsertPositionModalData}
      ></ConfirmationModal>

      <Modal
        size={"lg"}
        show={isShown}
        onHide={() => {
          ClearUpsertPositionModalData();
        }}
        dialogClassName={`custom-modal-dialog ${windowSizeCssClass}`}
        contentClassName={"custom-modal-content"}
      >
        <div className={"bordered"}>
          <Form onSubmit={HandleSubmit}>
            <Modal.Header closeButton className={"p-0 border-bottom-0 text-18 text-color-gray-light fw-medium"}>
              <Row>
                <Col>{isUpdateOperation ? "Pozisyon güncelle" : "Pozisyon Ekle"}</Col>
              </Row>
            </Modal.Header>
            <Modal.Body>
              <Form.Label column={"sm"} className={"text-20 text-color-gray-light fw-medium"}>
                Position adı
              </Form.Label>
              <Form.Control
                className={`default-text-input ${position.name?.isValid ? "" : "is-invalid"}`}
                style={{ height: "50px" }}
                onChange={(e) => {
                  dispatch(HandleEditPosition({ variableName: "name", value: e.target.value }));
                }}
                value={position.name?.value && position.name?.value}
                placeholder={t("enterPositionNamePlaceholder")}
              ></Form.Control>

              <Form.Label column={"sm"} className={"text-20 text-color-gray-light fw-medium"}>
                Position açıklaması
              </Form.Label>
              <Form.Control
                as={"textarea"}
                className={`default-text-input ${position.description?.isValid ? "" : "is-invalid"}`}
                style={{ height: "150px" }}
                onChange={(e) => {
                  dispatch(HandleEditPosition({ variableName: "description", value: e.target.value }));
                }}
                value={position.description?.value && position.description?.value}
                placeholder={t("enterPositionDescriptionPlaceholder")}
              ></Form.Control>
            </Modal.Body>
            <Modal.Footer className={"justify-content-evenly border-bottom-0"}>
              {isUpdateOperation && (
                <Col xs={"auto"}>
                  <Button
                    className={"button secondary danger"}
                    style={{ height: "50px", width: "200px" }}
                    onClick={() => {
                      DeletePosition(position.id);
                    }}
                  >
                    DELETE
                  </Button>
                </Col>
              )}
              <Col xs={"auto"}>
                <Button
                  disabled={IsDisabled()}
                  type={"submit"}
                  className={"button primary"}
                  style={{ height: "50px", width: "200px" }}
                >
                  {isUpdateOperation ? "UPDATE" : "CREATE"}
                </Button>
              </Col>
            </Modal.Footer>
          </Form>
        </div>
      </Modal>
    </>
  );
};
export default UpsertPositionModal;
