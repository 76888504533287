import React from "react";
import { Col, Row } from "react-bootstrap";
import IconSvg from "../../../../../../AppMenu/Components/IconSvg";

export default function DualText({ item }) {
  const isSub = item.subScopeId ? true : false;

  let hitRange = item.scale.ranges.find((range) => range.isHit === true);

  if (hitRange) {
    return (
      <Row className={!hitRange && "d-none"} style={{ height: Number(item?.height), marginBottom: "6px" }}>
        <Col>
          <div className={"round-30px p-2"} style={{ backgroundColor: "#efefef" }}>
            <div className={"position-relative "}>
              <div className={"position-absolute"} style={{ top: -20, left: -4 }}>
                <IconSvg icon={"documentIcon"} height={30} width={30} />
              </div>

              <div>
                <Row>
                  <Col xs={6}>
                    <div className={"round-30px p-2 h-100"} style={{ backgroundColor: "#ffffff", fontSize: 12 }}>
                      {item.scale.isHTML ? <div dangerouslySetInnerHTML={{ __html: hitRange.text }} /> : hitRange.text}
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div className={"round-30px p-2 h-100"} style={{ backgroundColor: "#ffffff", fontSize: 12 }}>
                      {item.scale.isHTML ? (
                        <div dangerouslySetInnerHTML={{ __html: hitRange.rightText }} />
                      ) : (
                        hitRange.rightText
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}

/*

export default function DualText({ item }) {
  const isSub = item.subScopeId ? true : false;

  return item.scale.ranges.map((range, i) => {
    if (range.isHit) {
      return (
        <Row className="mb-3" key={Math.random()} style={{ height: Number(item.height) }}>
          <Col xs="6">
            <div className={"round-30px p-2"} style={{ backgroundColor: "#efefef" }}>
              {item.scale.isHtml ? <div dangerouslySetInnerHTML={{ __html: item.monoSpaceHtml }} /> : range.text}
            </div>
          </Col>
          <Col xs="6">
            <div className={"round-30px p-2"} style={{ backgroundColor: "#efefef" }}>
              {item.scale.isHtml ? <div dangerouslySetInnerHTML={{ __html: item.monoSpaceHtml }} /> : range.rightText}
            </div>
          </Col>
        </Row>
      );
    } else {
      return <div></div>;
    }
  });
}*/
