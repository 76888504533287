export const reactSelectStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isDisabled ? "#f5f5f5" : "#fff",
    borderColor: "#e4e4e4",
    borderRadius: "10px",
    minHeight: "50px",
    boxShadow: state.isFocused ? "0 0 0 0.2rem rgba(0,123,255,.25)" : null,
    "&:hover": {
      borderColor: "#0077b6",
    },
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 9999, // Ensure dropdown is above other elements
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#f8f9fa" : "#fff",
    color: state.isFocused ? "#303030" : "#303030",
    "&:active": {
      color: "#fff",
      backgroundColor: "#0077b6",
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: state.isDisabled ? "#8b8c89" : "#303030",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#6c757d",
  }),
};
