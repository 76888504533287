import Modal from "react-bootstrap/Modal";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import IconSvg from "../../../AppMenu/Components/IconSvg";
import { SetStartInfoModalIsShown } from "../Store";
import Button from "react-bootstrap/Button";
import { applicationTypeEnum, GetKeyByValue } from "../../../../Helpers/EnumHelper";
import { useNavigate } from "react-router-dom";

const StartInfoModal = ({ selectedAssessment, setSelectedAssessment }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const windowSizeCssClass = useSelector((state) => state.appMenu.screenSize);

  const isShown = useSelector((state) => state.userDash.startInfoModalIsShown);

  const [extraScreens, setExtraScreens] = useState(false);

  useEffect(() => {
    // Initial detection
    detectExtraScreens();

    // Poll every 5 seconds
    const interval = setInterval(detectExtraScreens, 1000);

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  const detectExtraScreens = () => {
    if (window.screen.isExtended) {
      setExtraScreens(true);
    } else {
      setExtraScreens(false);
    }
  };

  const enterFullscreen = () => {
    const element = document.documentElement;

    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else {
      console.log("Fullscreen API is not supported by this browser.");
    }
  };

  const HandleStart = (application, ticketId) => {
    switch (application.applicationType) {
      case applicationTypeEnum.Inventory:
        navigate("/invapp/" + application.id + "/" + ticketId);
        break;
      case applicationTypeEnum.Test:
        navigate("/testapp/" + application.id + "/" + ticketId);
        break;
      default:
        alert("Uygulama bilgileri eksik lütfen yetkili ile iletişime geçin");
        break;
    }
  };

  return (
    <Modal
      size={"lg"}
      dialogClassName={`custom-modal-dialog ${windowSizeCssClass}`}
      contentClassName={"custom-modal-content"}
      show={isShown}
      onHide={() => {
        dispatch(SetStartInfoModalIsShown(false));
        setSelectedAssessment(null);
      }}
    >
      <div className={"bordered text-18"}>
        <Modal.Header closeButton className={"p-0 border-bottom-0"}></Modal.Header>

        {extraScreens && (
          <Row className={"mb-3"}>
            <Col xs={"auto"} className={"align-content-center"}>
              <IconSvg icon={"monitorOff"}></IconSvg>
            </Col>
            <Col>
              You have <span style={{ fontWeight: 600 }}>MULTIPLE MONITORS</span>, disconnect them to start the
              assessment
            </Col>
          </Row>
        )}

        <Row className={"mb-3"}>
          <Col xs={"auto"} className={"align-content-center"}>
            <IconSvg icon={"closeTabs"}></IconSvg>
          </Col>
          <Col>
            You cannot <span style={{ fontWeight: 600 }}>SWITCH TABS</span> during the process
          </Col>
        </Row>

        <Row className={"mb-3"}>
          <Col xs={"auto"} className={"align-content-center"}>
            <IconSvg icon={"fullscreen"}></IconSvg>
          </Col>
          <Col>
            Your page will go <span style={{ fontWeight: 600 }}> FULLSCREEN</span> after you press start
          </Col>
        </Row>

        {selectedAssessment?.application?.duration && (
          <Row className={"mb-3"}>
            <Col xs={"auto"} className={"align-content-center"}>
              <IconSvg icon={"clock"}></IconSvg>
            </Col>
            <Col>
              You have{" "}
              <span
                style={{
                  fontWeight: 600,
                }}
              >
                {selectedAssessment?.application?.duration} minutes
              </span>{" "}
              to complete the assessment
            </Col>
          </Row>
        )}
        {selectedAssessment?.application?.openCam ? (
          <Row className={"mb-3"}>
            <Col xs={"auto"} className={"align-content-center"}>
              <IconSvg icon={"webcamEnabled"}></IconSvg>
            </Col>
            <Col>
              This assessment needs camera to be{" "}
              <span
                style={{
                  color: "#0f0",
                  fontWeight: 600,
                }}
              >
                ENABLED
              </span>
              , you must remain in view of your webcam during the entire assessment process
            </Col>
          </Row>
        ) : (
          <Row className={"mb-3"}>
            <Col xs={"auto"} className={"align-content-center"}>
              <IconSvg icon={"webcamDisabled"}></IconSvg>
            </Col>
            <Col>
              This assessment needs camera to be{" "}
              <span
                style={{
                  color: "#f00",
                  fontWeight: 600,
                }}
              >
                DISABLED
              </span>
            </Col>
          </Row>
        )}

        <Row className={"mb-3"}>
          <Col xs={"auto"} className={"align-content-center"}>
            <IconSvg icon={"webcamDisabled"}></IconSvg>
          </Col>
          <Col>
            This assessment needs microphone to be{" "}
            <span
              style={{
                color: "#0f0",
                fontWeight: 600,
              }}
            >
              ENABLED
            </span>
          </Col>
        </Row>

        {!selectedAssessment?.company?.hasDemography && (
          <Row className={"mb-5"}>
            <Col xs={"auto"} className={"align-content-center"}>
              <IconSvg icon={"infoButton"}></IconSvg>
            </Col>
            <Col>
              You have to fill in your <span style={{ fontWeight: 600 }}>DEMOGRAPHIC INFORMATION</span> in the first
              part of the assessment
            </Col>
          </Row>
        )}

        <Row className={"justify-content-end"}>
          <Col xs={"auto"}>
            <Button
              disabled={extraScreens && !window.isDev}
              className={"button secondary"}
              onClick={() => {
                enterFullscreen();
                dispatch(SetStartInfoModalIsShown(false));
                HandleStart(selectedAssessment?.application, selectedAssessment?.ticketId);
              }}
            >
              {extraScreens
                ? "ekran cikart "
                : "Start " + GetKeyByValue(selectedAssessment?.application?.applicationType, applicationTypeEnum)}
            </Button>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};
export default StartInfoModal;
