import { createAsyncThunk } from "@reduxjs/toolkit";
import CompanyInfoAPI, { CompanyInfoApiUrls } from "../API/AdminCompanyInfoAPI";

import LocalStorage from "../../../../Services/Storage/LocalStorage";
import StorageModel from "../../../../Models/StorageModel";
import HttpsClient from "../../../../Services/HttpsClient";
import ApiHelper from "../../../../Services/ApiHelper";
import Cache from "../../../../Services/Cache";

const storage = new LocalStorage();
const storageModel = new StorageModel(storage);
const cache = new Cache(storageModel);

const httpsClient = new HttpsClient();
const apiHelper = new ApiHelper(httpsClient);
const creditsAPI = new CompanyInfoAPI(apiHelper, cache);

export const getCompanyInfo = createAsyncThunk(
  CompanyInfoApiUrls.getCompanyInfo,
  async (payload, { getState, dispatch }) => {
    const state = getState();
    const token = state.auth.user.token;
    if (!payload) {
      payload = { companyId: state.companyInfo.companyId };
    }
    return await creditsAPI.getCompanyInfo(payload, token);
  }
);

export const upsertCompanyInfo = createAsyncThunk(
  CompanyInfoApiUrls.upsertCompanyInfo,
  async (payload, { getState, dispatch }) => {
    const state = getState();
    const token = state.auth.user.token;

    const response = await creditsAPI.upsertCompanyInfo(payload, token);

    dispatch(getCompanyInfo());

    return response;
  }
);

export const deleteCompanyInfo = createAsyncThunk(
  CompanyInfoApiUrls.deleteCompanyInfo,
  async (payload, { getState, dispatch }) => {
    const state = getState();
    const token = state.auth.user.token;

    const response = await creditsAPI.deleteCompanyInfo(payload, token);

    dispatch(getCompanyInfo());

    return response;
  }
);

export const deleteImageFromCompanyInfo = createAsyncThunk(
  CompanyInfoApiUrls.deleteImageFromCompanyInfo,
  async (payload, { getState, dispatch }) => {
    const state = getState();
    const token = state.auth.user.token;

    const response = await creditsAPI.deleteImageFromCompanyInfo(payload, token);

    dispatch(getCompanyInfo());

    return response;
  }
);
