import React, { useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import "animate.css";
import ApiHelper from "../../../../../Helpers/ApiHelper";
import { useSelector } from "react-redux";
import { ParseDate } from "../../../../../Helpers/DateHelper";
import StartInfoModal from "../StartInfoModal";
import ScrollableCard from "../../../../SharedComponents/ScrollableCard";
import AppCardFooter from "./AppCardFooter";
import { applicationTypeEnum, GetValueByKey } from "../../../../../Helpers/EnumHelper";

export function AppCard() {
  const candidateData = useSelector((state) => state.userDash.candidateData);

  const [selectedAssessment, setSelectedAssessment] = useState(null);

  const isPastDeadline = (deadline) => {
    // Parse the deadline to ensure it is a valid Date object
    const deadlineDate = new Date(deadline);

    // Check if the parsed date is invalid
    if (isNaN(deadlineDate.getTime())) {
      throw new Error("Invalid date format");
    }

    const currentDate = new Date();

    return deadlineDate < currentDate;
  };

  const CreateAppCardUiElement = (application, company, ticketId, i) => {
    return (
      <Col
        key={i}
        xs={12}
        md={6}
        xl={4}
        xxl={3}
        xxxl={2}
        className=" animate__animated  animate__fadeInLeft animate__faster mb-4"
      >
        <Card
          className={
            "default-card " +
            ((isPastDeadline(application.deadline) ||
              application.sessionsData?.clickCounter >= 5 ||
              (Math.floor(application.sessionsData?.remainingDuration) === 0 &&
                GetValueByKey(application.applicationType, applicationTypeEnum) === "Test")) &&
              !application.sessionsData?.isFinished &&
              "unavailable")
          }
          style={{ height: "350px" }}
        >
          <div className="d-flex flex-column h-100 p-3">
            <Row className={"mx-0"}>
              <img src={ApiHelper.baseURL + "wwwroot/logo/" + company.companyLogoImageName} height="80" width="250" />
            </Row>

            <Row className={"fw-medium justify-content-between mt-3"}>
              <Col xs="auto">
                <Row className={"text-12 text-color-gray-light"}>
                  <Col xs={"auto"}>Uygulama tipi</Col>
                </Row>
                <Row className={"text-16  text-color-primary fw-semibold"}>
                  <Col xs={"auto"}>{application.name} </Col>
                </Row>
              </Col>
              <Col xs="auto">
                <Row className={"text-12 text-color-gray-light justify-content-end"}>
                  <Col xs={"auto"}>Tanımlanma</Col>
                </Row>
                <Row className={"text-16  text-color-primary fw-semibold justify-content-end"}>
                  <Col xs={"auto"}>{ParseDate(application.createdAt).date} </Col>
                </Row>
              </Col>
            </Row>
            <Row className={"fw-medium justify-content-between mt-auto "}>
              <Col xs="auto">
                <Row className={"text-12 text-color-gray-light"}>
                  <Col xs={"auto"}>Soru adeti</Col>
                </Row>
                <Row className={"text-20  text-color-primary fw-semibold"}>
                  <Col xs={"auto"}>{application.questionNumber}</Col>
                </Row>
              </Col>
              <Col xs="auto">
                <Row className={"text-12 text-color-gray-light justify-content-end"}>
                  <Col xs={"auto"}>Son Teslim</Col>
                </Row>
                <Row className={"text-16  text-color-primary fw-semibold justify-content-end"}>
                  <Col
                    xs={"auto"}
                    style={{
                      color: isPastDeadline(application.deadline) && !application.sessionsData?.isFinished && "#f00",
                    }}
                  >
                    {ParseDate(application.deadline).date}
                  </Col>
                </Row>
              </Col>
            </Row>
            <AppCardFooter
              application={application}
              ticketId={ticketId}
              setSelectedAssessment={setSelectedAssessment}
              company={company}
            ></AppCardFooter>
          </div>
        </Card>
      </Col>
    );
  };

  const HandleTicket = (ticket, company, ticketIndex) => {
    let apps = [];
    ticket.inventories?.forEach((inventory, i) => {
      apps.push(CreateAppCardUiElement(inventory, company, ticket.ticketId, `I${ticketIndex}${i}`));
    });
    ticket.tests?.forEach((test, i) => {
      apps.push(CreateAppCardUiElement(test, company, ticket.ticketId, `T${ticketIndex}${i}`));
    });
    ticket.surveys?.forEach((survey, i) => {
      apps.push(CreateAppCardUiElement(survey, company, ticket.ticketId, `S${ticketIndex}${i}`));
    });
    return apps;
  };

  return (
    <>
      <StartInfoModal
        selectedAssessment={selectedAssessment}
        setSelectedAssessment={setSelectedAssessment}
      ></StartInfoModal>
      <ScrollableCard>
        {candidateData?.map((candidate, i) => {
          const company = {
            companyName: candidate.companyName,
            companyLogoImageName: candidate.companyLogoImageName,
            candidateId: candidate.candidateId,
            companyId: candidate.companyId,
            hasDemography: candidate.hasDemography,
          };

          return (
            <div key={i}>
              <Row className={"mx-0 mt-3 "}>
                {candidate?.tickets?.map((ticket, i) => HandleTicket(ticket, company, i))}
              </Row>
            </div>
          );
        })}
      </ScrollableCard>
    </>
  );
}
