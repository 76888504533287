import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { createCandidate_, deleteCandidate, updateCandidate } from "../Store/Thunk";
import { ClearUpsertCandidateModalData, HandleEditCandidate, ShowUpsertCandidateModal } from "../Store";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "../../../SharedComponents/GeneralModals";

const UpsertCandidateModal = ({ positionList, backUrl }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const companyId = useSelector((state) => state.auth.user.companyUser.company.companyId);
  const candidate = useSelector((state) => state.createCandidate.candidate);
  const isUpdateOperation = useSelector((state) => state.createCandidate.isUpdateOperation);
  const isShown = useSelector((state) => state.createCandidate.upsertCandidateModalIsShown);
  const windowSizeCssClass = useSelector((state) => state.appMenu.screenSize);

  const [confirmationModalIsShown, setConfirmationModalIsShown] = useState(false);
  const [candidateToDelete, setCandidateToDelete] = useState(null);
  const ClearModalData = () => {
    dispatch(ClearUpsertCandidateModalData());
    dispatch(ShowUpsertCandidateModal({ upsertCandidateModalIsShown: false }));
  };

  const DeleteCandidate = (id) => {
    setConfirmationModalIsShown(true);
    dispatch(ShowUpsertCandidateModal({ upsertCandidateModalIsShown: false }));
    setCandidateToDelete({ id: id });
  };

  const ConfirmationFunction = () => {
    dispatch(deleteCandidate(candidateToDelete));
    if (backUrl) {
      navigate(backUrl);
    }
    ClearModalData();
  };

  const HandleSubmit = (e) => {
    e.preventDefault();
    if (isUpdateOperation) {
      dispatch(
        updateCandidate({
          name: candidate.candidateName?.value,
          positionId: candidate.positionId?.value,
          email: candidate.email?.value,
          phoneNumber: candidate.phoneNumber?.value,
          candidateId: candidate.candidateId?.value,
        })
      );
    } else {
      dispatch(
        createCandidate_({
          name: candidate.candidateName?.value,
          positionId: candidate.positionId?.value,
          email: candidate.email.value,
          phoneNumber: candidate.phoneNumber?.value,
          companyId: companyId,
        })
      );
    }

    ClearModalData();
  };

  return (
    <>
      <ConfirmationModal
        isShown={confirmationModalIsShown}
        setIsShown={setConfirmationModalIsShown}
        text={"Aday silinecek"}
        confirmationFunction={ConfirmationFunction}
        dismissFunction={ClearModalData}
      ></ConfirmationModal>

      <Modal
        size={"lg"}
        dialogClassName={`custom-modal-dialog ${windowSizeCssClass}`}
        contentClassName={"custom-modal-content"}
        show={isShown}
        onHide={() => {
          ClearModalData();
        }}
      >
        <div className={"bordered"}>
          <Form onSubmit={HandleSubmit}>
            <Modal.Header closeButton className={"p-0 border-bottom-0"}></Modal.Header>
            <Modal.Body>
              <Form.Label column={"sm"} className={"text-20 text-color-gray-light fw-medium"}>
                Aday adı soyadı
              </Form.Label>
              <Form.Control
                className={`default-text-input ${candidate.candidateName?.isValid ? "" : "is-invalid"}`}
                style={{ height: "50px" }}
                onChange={(e) =>
                  dispatch(
                    HandleEditCandidate({
                      variableName: "candidateName",
                      value: e.target.value,
                    })
                  )
                }
                value={candidate.candidateName?.value && candidate.candidateName?.value}
                placeholder={t("enterCandidateNamePlaceholder")}
              ></Form.Control>

              <Row>
                <Col>
                  <Form.Label column={"sm"} className={"text-20 text-color-gray-light fw-medium"}>
                    Telefon Numarası
                  </Form.Label>
                  <Form.Control
                    className={`default-text-input ${candidate.phoneNumber?.isValid ? "" : "is-invalid"}`}
                    style={{ height: "50px" }}
                    type="tel"
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d*$/.test(value)) {
                        dispatch(
                          HandleEditCandidate({
                            variableName: "phoneNumber",
                            value, // Store the string value directly
                          })
                        );
                      }
                    }}
                    value={candidate.phoneNumber?.value || ""} // Ensure value is always a string
                    placeholder={t("enterCandidatePhoneNumberPlaceholder")}
                  />
                </Col>

                <Col>
                  <Form.Label column={"sm"} className={"text-20 text-color-gray-light fw-medium"}>
                    E-mail
                  </Form.Label>
                  <Form.Control
                    className={`default-text-input ${candidate.email?.isValid ? "" : "is-invalid"}`}
                    style={{ height: "50px" }}
                    onChange={(e) => {
                      dispatch(
                        HandleEditCandidate({
                          variableName: "email",
                          value: e.target.value,
                        })
                      );
                    }}
                    value={candidate.email?.value && candidate.email?.value}
                    placeholder={t("enterCandidateEmailPlaceholder")}
                  ></Form.Control>
                </Col>
              </Row>

              <Form.Label column={"sm"} className={"text-20 text-color-gray-light fw-medium"}>
                Pozisyon
              </Form.Label>
              <Form.Select
                className={`default-text-input ${candidate.positionId?.isValid ? "" : "is-invalid"}`}
                style={{ height: "50px" }}
                value={candidate.positionId?.value ? candidate.positionId?.value : ""}
                onChange={(e) => {
                  dispatch(
                    HandleEditCandidate({
                      variableName: "positionId",
                      value: e.target.value,
                    })
                  );
                }}
              >
                <option value={0}>{t("choosePositionDropdownToggleLabel")}</option>
                {positionList.map((position, i) => {
                  return (
                    <option key={i} value={position.id}>
                      {position.name}
                    </option>
                  );
                })}
              </Form.Select>
            </Modal.Body>
            <Modal.Footer className={"justify-content-evenly"}>
              {isUpdateOperation && (
                <Col xs={"auto"}>
                  <Button
                    style={{ height: "50px", width: "200px" }}
                    className={"button secondary danger"}
                    onClick={() => {
                      DeleteCandidate(candidate.candidateId.value);
                    }}
                  >
                    DELETE
                  </Button>
                </Col>
              )}
              <Col xs={"auto"}>
                <Button
                  disabled={
                    !candidate.candidateName?.isValid ||
                    !candidate.positionId?.isValid ||
                    !candidate.email?.isValid ||
                    !candidate.phoneNumber?.isValid ||
                    candidate.email?.value === null ||
                    candidate.candidateName?.value === null
                  }
                  type={"submit"}
                  className={"button primary"}
                  style={{ height: "50px", width: "200px" }}
                >
                  {isUpdateOperation ? "UPDATE" : "CREATE"}
                </Button>
              </Col>
            </Modal.Footer>
          </Form>
        </div>
      </Modal>
    </>
  );
};
export default UpsertCandidateModal;
