import { applicationTypeEnum, GetValueByKey } from "../../../../../Helpers/EnumHelper";
import { Col, Row } from "react-bootstrap";
import IconSvg from "../../../../AppMenu/Components/IconSvg";
import Button from "react-bootstrap/Button";
import React from "react";
import { SetStartInfoModalIsShown } from "../../Store";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const AppCardFooter = ({ application, company, ticketId, setSelectedAssessment }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isPastDeadline = (deadline) => {
    const deadlineDate = new Date(deadline);

    if (isNaN(deadlineDate.getTime())) {
      throw new Error("Invalid date format");
    }

    const currentDate = new Date();
    return deadlineDate < currentDate;
  };

  const HandleRemainingDuration = (application) => {
    if (application.sessionsData === null) return Math.floor(application.duration / 60);
    else if (application.sessionsData?.remainingDuration)
      return Math.floor(application.sessionsData.remainingDuration / 60);
    else {
      console.log("ERROR AT HandleRemainingDuration.  its in AppCardFooter.js");
    }
  };

  const HandleCardButtonClick = (company, application, ticketId) => {
    if (company.hasDemography) {
      dispatch(SetStartInfoModalIsShown(true));

      setSelectedAssessment({ application, company, ticketId });
    } else {
      navigate("/demographyForm", { state: company });
    }
  };

  const CheckSessionState = (application) => {
    //returns footer content
    const sessionsData = application.sessionsData;

    let footerContent = { buttonIsShown: false, text: "" };

    //NON BUTTONS
    if (sessionsData?.clickCounter >= 5 && !sessionsData?.isFinished) {
      footerContent = { buttonIsShown: false, text: "Giriş hakkınız kalmadı" };
    } else if (application.sessionsData?.isFinished) {
      footerContent = { buttonIsShown: false, text: "Tamamlandı" };
    } else if (isPastDeadline(application.deadline)) {
      footerContent = { buttonIsShown: false, text: "Deadline passed" };
    } else if (
      Math.floor(application.sessionsData?.remainingDuration) === 0 &&
      GetValueByKey(application.applicationType, applicationTypeEnum) === "Test"
    ) {
      footerContent = { buttonIsShown: false, text: "Zaman aşımı" };
    }

    //BUTTONS
    else {
      if (sessionsData) {
        footerContent = { buttonIsShown: true, text: "Devam et" };
      } else {
        footerContent = { buttonIsShown: true, text: "Başla" };
      }
    }

    return footerContent;
  };

  return (
    <Row className="mt-auto justify-content-between">
      <Col>
        <Row className={"justify-content-between text-12 pb-2"}>
          {application?.openCam && ( //TODO: cameraRequirement check.
            <Col xs={"auto"}>
              <Row>
                <Col xs={"auto"} className={"pe-0 align-content-start"}>
                  <IconSvg icon={"webcamEnabled"} width={18} height={18}></IconSvg>
                </Col>
                <Col xs={"auto"} className={"text-12 text-color-gray-light fw-medium ps-1"}>
                  Camera required
                </Col>
              </Row>
            </Col>
          )}

          {application.sessionsData?.clickCounter &&
            application.sessionsData?.clickCounter !== 5 &&
            !application.sessionsData?.isFinished && (
              <Col xs={"auto"}>
                <Row>
                  <Col xs={"auto"} className={"pe-0 text-color-gray-light fw-medium"}>
                    Kalan giriş hakkı:
                  </Col>
                  <Col xs={"auto"} className={" text-color-gray-dark fw-semibold"}>
                    {5 - application.sessionsData?.clickCounter}
                  </Col>
                </Row>
              </Col>
            )}
        </Row>
        <Row className={"justify-content-between"}>
          {application.applicationType === applicationTypeEnum.Test && (
            <Col xs={"auto"} className={"align-content-center"}>
              <Row>
                <Col className={"align-content-center"} xs={"auto"}>
                  <IconSvg icon="clock" />
                </Col>
                <Col className={"px-0 align-content-center"}>{HandleRemainingDuration(application)} Dakika</Col>
              </Row>
            </Col>
          )}
          <Col className={"align-content-center"}>
            <Row className={"justify-content-center"} style={{ height: "40px" }}>
              {CheckSessionState(application).buttonIsShown ? (
                <Col className={"align-content-center"}>
                  <Button
                    className="button secondary w-100"
                    style={{ borderRadius: "10px" }}
                    onClick={() => {
                      HandleCardButtonClick(company, application, ticketId);
                    }}
                  >
                    {CheckSessionState(application).text}
                  </Button>
                </Col>
              ) : (
                <Col xs={"auto"} className={"align-content-center text-color-gray-dark fw-semibold"}>
                  {CheckSessionState(application).text}
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default AppCardFooter;
