import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ParseDate } from "../../../../../Helpers/DateHelper";
import IconSvg from "../../../../AppMenu/Components/IconSvg";
import ScrollableCard from "../../../../SharedComponents/ScrollableCard";
import { getB2bAssessments } from "../../../../SuperAdmin/CreateCompany/Credits/Store/Thunk";
import { useNavigate } from "react-router-dom";

const ProjectHistory = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const assessmentList = useSelector((state) => state.credits.assessmentList);
  const lastTenOrderRecords = useSelector((state) => state.projects.lastTenOrderRecords);

  useEffect(() => {
    dispatch(getB2bAssessments());
  }, []);

  const MatchAssessmentIdWithName = (id) => {
    const matchingAssessment = assessmentList.find((assessment) => assessment.id === id);
    return matchingAssessment?.name;
  };

  return (
    <Row className={"mx-0"}>
      <Col>
        <Card className={"default-list"} style={{ backgroundColor: "#f8f9fa" }}>
          <Card className={"header mb-0"} style={{ backgroundColor: "white" }}>
            <Row className={"text-20 text-color-gray-light fw-medium"} style={{ height: "50px", margin: "0" }}>
              <Col xs={3} className={"align-content-center"}>
                <Row className={"justify-content-center"}>Tarih</Row>
              </Col>
              <Col className={"align-content-center"}>
                <Row>
                  <Col>
                    <Row className={"justify-content-center"}>Değerleme</Row>
                  </Col>

                  <Col>
                    <Row className={"justify-content-center"}>Aday Ad-Soyad</Row>
                  </Col>
                  <Col>
                    <Row className={"justify-content-center"}>Aday E-Mail</Row>
                  </Col>
                  <Col xs={"auto"}>
                    <IconSvg icon={"info-box"} width={30} height={30}></IconSvg>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
          <ScrollableCard top={0}>
            {lastTenOrderRecords.map((orderRecord, orderIndex) => {
              const isOnlyItem =
                orderRecord.successfulRegistrations.length + orderRecord.unsuccessfulRegistrations.length === 1;
              return (
                <Card
                  key={orderIndex}
                  className={"mt-4 list-container text-20 fw-medium text-color-gray-dark"}
                  style={{ backgroundColor: "white" }}
                >
                  <Row className={"list-row"} style={{ borderBottomWidth: "0px" }}>
                    <Col
                      xs={3}
                      className={"align-content-center position-relative"}
                      style={{ borderRight: "2px solid #e7ecef" }}
                    >
                      <Row className={"justify-content-center text-color-gray-light"}>
                        {ParseDate(orderRecord.createdAt).date} {ParseDate(orderRecord.createdAt).time}
                      </Row>
                      <div className={"absolute-top-left text-16 text-color-gray-light fw-medium"}>
                        ID {orderRecord.id}
                      </div>
                    </Col>
                    <Col>
                      {orderRecord.successfulRegistrations.length !== 0 &&
                        orderRecord.successfulRegistrations.map((registration, i) => {
                          const lastItem =
                            i === orderRecord.successfulRegistrations.length - 1 &&
                            orderRecord.successfulRegistrations.length > 1;

                          return (
                            <Row
                              key={i}
                              className={"hover-bg-darken rounded-0"}
                              onClick={() => {
                                navigate("/candidate", {
                                  state: {
                                    candidateId: registration.CandidateId,
                                    backUrl: "/project",
                                  },
                                });
                              }}
                              style={{
                                borderBottom: `${!lastItem && !isOnlyItem ? "2px solid #e7ecef" : "none"}`,
                                paddingBottom: "10px",
                                paddingTop: "10px",
                              }}
                            >
                              <Col className={"align-content-center"}>
                                {registration.CreditDataList?.map((creditData, i) => {
                                  return (
                                    <Row key={i} className={"justify-content-center"}>
                                      {MatchAssessmentIdWithName(creditData.AssessmentId)}
                                    </Row>
                                  );
                                })}
                              </Col>
                              <Col className={"align-content-center"}>
                                <Row className={"justify-content-center"}>{registration.Name}</Row>
                              </Col>
                              <Col className={"align-content-center text-18"}>
                                <Row className={"justify-content-center"}>{registration.UserEmail}</Row>
                              </Col>
                              <Col xs={"auto"} className={"align-content-center"}>
                                <IconSvg icon={"tick-box"} width={30} height={30}></IconSvg>
                              </Col>
                            </Row>
                          );
                        })}
                      {orderRecord.unsuccessfulRegistrations.length !== 0 &&
                        orderRecord.unsuccessfulRegistrations.map((registration, i) => {
                          const firstItem = i === 0;
                          return (
                            <Row
                              key={i}
                              className={"hover-bg-darken rounded-0"}
                              onClick={() => {
                                navigate("/candidate", {
                                  state: {
                                    candidateId: registration.CandidateId,
                                    backUrl: "/project",
                                  },
                                });
                              }}
                              style={{
                                borderTop: `${!firstItem && !isOnlyItem ? "2px solid #e7ecef" : "none"}`,
                                paddingBottom: "10px",
                                paddingTop: "10px",
                              }}
                            >
                              <Col className={"align-content-center"}>
                                {registration.CreditDataList?.map((creditData, i) => {
                                  return (
                                    <Row key={i} className={"justify-content-center"}>
                                      {MatchAssessmentIdWithName(creditData.AssessmentId)}
                                    </Row>
                                  );
                                })}
                              </Col>
                              <Col className={"align-content-center"}>
                                <Row className={"justify-content-center"}>{registration.Name}</Row>
                              </Col>
                              <Col className={"align-content-center text-18"}>
                                <Row className={"justify-content-center"}>{registration.UserEmail}</Row>
                              </Col>
                              <Col xs={"auto"} className={"align-content-center"}>
                                <IconSvg icon={"cross-box"} width={30} height={30}></IconSvg>
                              </Col>
                            </Row>
                          );
                        })}
                    </Col>
                  </Row>
                </Card>
              );
            })}
          </ScrollableCard>
        </Card>
      </Col>
    </Row>
  );
};
export default ProjectHistory;
