import { Card } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React from "react";
import IconSvg from "../../../AppMenu/Components/IconSvg";
import { useDispatch, useSelector } from "react-redux";
import { RemoveFromProjectList } from "../Store";
import ScrollableCard from "../../../SharedComponents/ScrollableCard";

const ProjectList = ({ isInModal }) => {
  const projectList = useSelector((state) => state.projects.projectList);
  const dispatch = useDispatch();
  return (
    <Card className={"default-list"}>
      <Card className={"header mb-0"}>
        <Row
          className={"justify-content-between ms-5 text-20 text-color-gray-light fw-medium"}
          style={{ height: "50px" }}
        >
          <Col xs={2} className={"align-content-center"}>
            <Row>Ad - Soyad</Row>
          </Col>
          <Col xs={3} className={"align-content-center"}>
            <Row>E - Mail</Row>
          </Col>

          <Col xs={2} className={"align-content-center"}>
            <Row>Pozisyon</Row>
          </Col>
          <Col xs={4} className={"align-content-center"}>
            <Row className={"justify-content-center"}>Değerleme</Row>
          </Col>
          <Col xs={1} className={"align-content-center"}>
            <Row className={"justify-content-end"}></Row>
          </Col>
        </Row>
      </Card>

      <ScrollableCard bottom={80} top={0}>
        <Card className={"list-container"} style={{ backgroundColor: isInModal ? "#f8f9fa" : "white" }}>
          {projectList &&
            projectList.map((project, i) => (
              <Card className={"list-row position-relative"} key={i}>
                <Row
                  className={"justify-content-between ms-5 text-16 text-color-gray-dark fw-medium"}
                  style={{ minHeight: "30px" }}
                >
                  {project.candidate.isNew && (
                    <div className={"absolute-top-left corner text-10 text-color-gray-light"}> (new)</div>
                  )}
                  <Col xs={2} className={"align-content-end"}>
                    <Row>{project.candidate.candidateName}</Row>
                  </Col>
                  <Col xs={3} className={"align-content-end"}>
                    <Row>{project.candidate.email} </Row>
                  </Col>
                  <Col xs={2} className={"text-color-gray-light align-content-end"}>
                    <Row>{project.position?.name}</Row>
                  </Col>
                  <Col xs={4} className={"text-color-gray-light align-content-end"}>
                    <Row className={"justify-content-center"}>
                      {project.credit.map((credit, i) => {
                        return (
                          <Col key={i} xs={"auto"} className={"justify-content-center"}>
                            {credit.assessment.name} {i !== project.credit.length - 1 && ","}
                          </Col>
                        );
                      })}
                    </Row>
                  </Col>

                  <Col xs={1} className={"align-content-center"}>
                    <Row className={"justify-content-center"}>
                      <Col
                        xs={"auto"}
                        className={"hover-bg-darken align-content-center"}
                        onClick={() => dispatch(RemoveFromProjectList({ candidateId: project.candidate.candidateId }))}
                      >
                        <IconSvg icon={"cross"}></IconSvg>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            ))}
        </Card>
      </ScrollableCard>
    </Card>
  );
};
export default ProjectList;
