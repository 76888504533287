export const DemographyFormApiUrls = {
  getDemographyByCandidateId: "candidate/GetDemographyByCandidateId",
  updateDemography: "candidate/UpdateDemography",
};

export default class DemographyFormAPI {
  constructor(httpsClient, cache) {
    this.httpsClient = httpsClient;
    this.cache = cache;
  }

  async getDemographyByCandidateId(data, token) {
    return this.get(DemographyFormApiUrls.getDemographyByCandidateId, data, token);
  }

  async updateDemography(data, token, eAlert, sAlert) {
    return this.post(DemographyFormApiUrls.updateDemography, data, token, eAlert, sAlert);
  }

  async get(url, data, token) {
    return await this.httpsClient.get(url, data, token);
  }

  async post(url, data, token) {
    return await this.httpsClient.post(url, data, token);
  }
}
