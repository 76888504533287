import { createSlice } from "@reduxjs/toolkit";
import { createPosition_, deletePosition, getPositionsByCompanyId, updatePosition } from "./Thunk";

export const createPosition = createSlice({
  name: "createPosition",
  initialState: {
    id: "",
    name: "",
    positionList: [],
    upsertPositionModalData: {
      name: { value: "", isValid: true },
      description: { value: "", isValid: true },
    },
    upsertPositionModalIsShown: false,
    isUpdateOperation: false,
    isLoading: false,
  },
  reducers: {
    UpdateUpsertPositionModalData(state, action) {
      state.upsertPositionModalData = {
        ...action.payload,
        name: { value: action.payload.name, isValid: true },
        description: { value: action.payload.description, isValid: true },
      };
    },

    HandleEditPosition(state, action) {
      if (!state.upsertPositionModalData[action.payload.variableName]) {
        state.upsertPositionModalData[action.payload.variableName] = { value: null, isValid: false }; // Initialize with default structure
      }

      state.upsertPositionModalData[action.payload.variableName].value = action.payload.value;

      switch (action.payload.variableName) {
        case "name": {
          state.upsertPositionModalData[action.payload.variableName].isValid = action.payload.value.length !== 0;
          break;
        }

        default: {
          state.upsertPositionModalData[action.payload.variableName].isValid = true;
        }
      }

      //candidate?.email, candidate?.name, candidate?.phoneNumber, candidate?.positionId,
    },
    ShowUpsertPositionModal(state, action) {
      state.upsertPositionModalIsShown = action.payload.upsertPositionModalIsShown;
    },
    ChangeIsUpdateOperation(state, action) {
      state.isUpdateOperation = action.payload.isUpdateOperation;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createPosition_.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createPosition_.fulfilled, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(updatePosition.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updatePosition.fulfilled, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(deletePosition.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deletePosition.fulfilled, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(getPositionsByCompanyId.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getPositionsByCompanyId.fulfilled, (state, action) => {
      state.positionList = action.payload;
      state.isLoading = false;
    });
  },
});

export const { UpdateUpsertPositionModalData, HandleEditPosition, ChangeIsUpdateOperation, ShowUpsertPositionModal } =
  createPosition.actions;

export default createPosition.reducer;
