import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Row from "react-bootstrap/Row";
import { useLocation, useNavigate } from "react-router-dom";
import CandidateListRow from "./CreateCandidateListRow";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";

const CreateCandidatesList = ({ candidatesList }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();

  useEffect(() => {}, []);

  return (
    <Row className={"mx-0"}>
      <Col>
        <Row className={"text-14 text-color-gray-light fw-medium "}>
          <Col>MEVCUT ADAYLAR</Col>
        </Row>

        {candidatesList.map((candidate, i) => {
          return (
            <Card key={i} className={"default-card mt-3"} style={{ height: "155px" }}>
              <CandidateListRow candidate={candidate}></CandidateListRow>
            </Card>
          );
        })}
      </Col>
    </Row>
  );
};
export default CreateCandidatesList;
