import React, { useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";

export default function Answers(props) {
  const [answer, setAnswer] = useState({
    file: null,
    option: "",
    isCorrect: false,
    value: "",
  });

  let HandleAddingAnswer = () => {
    const newArr = [...props.answers, answer];
    props.setAnswers(newArr);
    setAnswer({
      file: null,
      option: "",
      isCorrect: false,
      value: "",
    });
    props.setAnswers(newArr);
  };
  let HandleDeletingAnswer = (answer) => {
    if (answer.isCorrect)
      setAnswer({
        file: null,
        option: "",
        isCorrect: false,
        value: "",
      });
    let filteredAnswers = props.answers.filter((ans) => ans !== answer);
    props.setAnswers(filteredAnswers);
  };

  let HandleIsCorrectDisabled = () => {
    return props.answers.filter((ans) => ans.isCorrect === true).length;
  };
  return (
    <>
      <Row>
        <Col xs={12} md={6}>
          <InputGroup className="mb-3">
            <Form.Control
              disabled={answer.file}
              value={answer.option}
              placeholder="Cevap metni giriniz"
              onChange={(e) => setAnswer({ ...answer, option: e.target.value })}
            />
          </InputGroup>
        </Col>
        <Col xs={12} md={3} style={{ display: "flex", paddingTop: "5px" }}>
          {answer.file && (
            <Button
              style={{ height: "30px", marginRight: "5px" }}
              variant="danger"
              size="sm"
              onClick={() => setAnswer({ ...answer, file: null, value: "" })}
            >
              X
            </Button>
          )}
          <input
            disabled={answer.option}
            value={answer.value}
            type="file"
            onChange={(e) =>
              setAnswer({
                ...answer,
                file: e.target.files[0],
                value: e.target.value,
              })
            }
          />
        </Col>
        <Col xs={12} md={1}>
          <Form.Check
            checked={answer.isCorrect}
            disabled={HandleIsCorrectDisabled()}
            type="switch"
            style={{ width: "10%", paddingTop: "10px" }}
            onClick={(e) => setAnswer({ ...answer, isCorrect: e.target.checked })}
          />
        </Col>
        <Col xs={12} md={2}>
          <Button
            style={{ width: "100%" }}
            variant="primary"
            disabled={!(answer.value || answer.option)}
            onClick={HandleAddingAnswer}
          >
            +
          </Button>
        </Col>
      </Row>
      <Row style={{ display: "contents" }}>
        {props.answers.length > 0 &&
          props.answers.map((ans, i) => {
            return (
              <Col
                style={{ display: "inline-block" }}
                key={i}
                className={ans.isCorrect ? "created-correct-answer" : "created-false-answer"}
              >
                <Row>
                  <Col xs={11}>
                    {i + 1}) {ans.value} {ans.option}{" "}
                  </Col>
                  <Col xs={1}>
                    <Button
                      style={{ margin: "3px", float: "right" }}
                      variant="danger"
                      size="sm"
                      onClick={() => HandleDeletingAnswer(ans)}
                    >
                      x
                    </Button>
                  </Col>
                </Row>
              </Col>
            );
          })}
      </Row>
    </>
  );
}
