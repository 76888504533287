import { Badge, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function TestListRowInfo({ test }) {
  const { t } = useTranslation();

  // const calculateQuestionNumber = (scope) =>
  //   scope.reduce((acc, s) => {
  //     const subScopeQuestions = s.subScopes.reduce((accSub, ss) => accSub + ss.questions.length, 0);
  //     return acc + s.questions.length + subScopeQuestions;
  //   }, 0);

  return (
    <Col xs="12" md="4">
      <Row xs="auto">
        <Col>
          <h5>{test.id}</h5>
        </Col>
        <Col>
          <h5>{test.name}</h5>
        </Col>
        <Col>
          {test.scopes.length} {t("scope")}
        </Col>
        <Col>{/* {calculateQuestionNumber(test.scopes)} {t("question")} */} soru sayisi</Col>
        <Col>
          {test.duration} {t("minute")}
        </Col>
      </Row>
      <Row>
        {test.assessments &&
          test.assessments.map((assessment, m) => (
            <Col key={"te" + m} xs="auto">
              <Badge bg={assessment.isB2b ? "success" : "secondary"}>{assessment.name}</Badge>
            </Col>
          ))}
      </Row>
    </Col>
  );
}
