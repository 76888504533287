import { createAsyncThunk } from "@reduxjs/toolkit";

import LocalStorage from "../../../../Services/Storage/LocalStorage";
import StorageModel from "../../../../Models/StorageModel";
import HttpsClient from "../../../../Services/HttpsClient";
import ApiHelper from "../../../../Services/ApiHelper";
import Cache from "../../../../Services/Cache";
import ApplicationResultsAPI from "../API/ApplicationResultsAPI";

const storage = new LocalStorage();
const storageModel = new StorageModel(storage);
const cache = new Cache(storageModel);

const httpsClient = new HttpsClient();
const apiHelper = new ApiHelper(httpsClient);
const applicationResultsAPI = new ApplicationResultsAPI(apiHelper, cache);

export const getDemoResults = createAsyncThunk("test/GetDemoResults", async (_, getState) => {
  const state = getState.getState();
  const token = state.auth.user.token;
  const data = await applicationResultsAPI.getDemoResults(token);

  return data.data;
});

export const removeTests = createAsyncThunk("", async (payload, getState) => {
  const state = getState.getState();
  const token = state.auth.user.token;
  const selectedTests = state.applicationResults.selectedTestsId;

  await applicationResultsAPI.removeSelectedTests(selectedTests, token);

  const data = await applicationResultsAPI.getDemoResults(token);

  return data.data;
});
