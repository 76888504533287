import { createAsyncThunk } from "@reduxjs/toolkit";
import AdminDashAPI, { AdminDashApiUrls } from "../API/AdminDashAPI";

import LocalStorage from "../../../../Services/Storage/LocalStorage";
import StorageModel from "../../../../Models/StorageModel";
import HttpsClient from "../../../../Services/HttpsClient";
import ApiHelper from "../../../../Services/ApiHelper";
import Cache from "../../../../Services/Cache";

const storage = new LocalStorage();
const storageModel = new StorageModel(storage);
const cache = new Cache(storageModel);

const httpsClient = new HttpsClient();
const apiHelper = new ApiHelper(httpsClient);
const adminDashAPI = new AdminDashAPI(apiHelper, cache);

export const getCompanyCredits = createAsyncThunk(
  AdminDashApiUrls.getCompanyCredits,
  async (payload, { getState, dispatch }) => {
    const state = getState();
    const token = state.auth.user.token;
    if (!payload) {
      payload = { id: state.auth.user.companyUser.company.companyId };
    }

    return await adminDashAPI.getCompanyCredits(payload, token);
  }
);

export const getCompanyStatistics = createAsyncThunk(
  AdminDashApiUrls.getCompanyStatistics,
  async (payload, { getState, dispatch }) => {
    const state = getState();
    const token = state.auth.user.token;

    return await adminDashAPI.getCompanyStatistics(payload, token);
  }
);

export const getFinishedApplicationsByDaysCount = createAsyncThunk(
  AdminDashApiUrls.getFinishedApplicationsByDaysCount,
  async (payload, { getState, dispatch }) => {
    const state = getState();
    const token = state.auth.user.token;

    return await adminDashAPI.getFinishedApplicationsByDaysCount(payload, token);
  }
);
