import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import React from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import { createTickets } from "../Store/Thunk";
import { ChangeProjectSubmitModalIsShown } from "../Store";
import Table from "react-bootstrap/Table";
import ScrollableCard from "../../../SharedComponents/ScrollableCard";
import { useTranslation } from "react-i18next";
import IconSvg from "../../../AppMenu/Components/IconSvg";
import Overlay from "../../../SharedComponents/Overlay";

const ProjectSubmitModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const resultAccess = useSelector((state) => state.projects.resultAccess);
  const projectList = useSelector((state) => state.projects.projectList);
  const cameraRequirement = useSelector((state) => state.projects.cameraRequirement);
  const isLoading = useSelector((state) => state.projects.isLoading);
  const windowSizeCssClass = useSelector((state) => state.appMenu.screenSize);

  const isShown = useSelector((state) => state.projects.projectSubmitModalIsShown);

  const HandleSendTickets = (e) => {
    e.preventDefault();

    const TargetMailsWithCreditData = projectList.map((project) => ({
      name: project.candidate.candidateName,
      email: project.candidate.email,
      positionId: project?.position?.id,
      creditDataList: project.credit.map((credit) => ({ creditId: credit.creditId })),
    }));

    const postObj = {
      allowPdf: resultAccess,
      openCam: cameraRequirement,
      TargetMailsWithCreditData: TargetMailsWithCreditData,
    };

    dispatch(createTickets(postObj));
  };

  return (
    <Modal
      size={"xl"}
      dialogClassName={`custom-modal-dialog ${windowSizeCssClass}`}
      contentClassName={"custom-modal-content"}
      show={isShown}
      onHide={() => {
        dispatch(ChangeProjectSubmitModalIsShown(false));
      }}
    >
      {isLoading && <Overlay />}
      <div className={"bordered"}>
        <Row>
          <Card className={"default-list"}>
            <Form onSubmit={HandleSendTickets}>
              <Modal.Header closeButton className={"p-0 border-bottom-0"}>
                <Row className={"justify-content-center w-100"}>
                  <Col className={"text-18 text-color-gray-dark fw-semibold"} xs={"auto"}>
                    Değerlendirme Gönderilecek Adaylar
                  </Col>
                </Row>
              </Modal.Header>
              <Modal.Body>
                <ScrollableCard>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>{t("projectModalCandidateNameTableHeader")}</th>
                        <th>{t("projectModalCandidateEmailTableHeader")}</th>
                        <th>{t("projectModalPositionTableHeader")}</th>
                        <th>{t("projectModalAssignmentTableHeader")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {projectList.map((project, index) => {
                        return (
                          <tr key={index}>
                            <td className={"px-4"}>{project.candidate.candidateName}</td>
                            <td>{project.candidate.email}</td>
                            <td>{project.position?.name}</td>
                            <td>
                              {project.credit.map((credit, i) => {
                                return <div key={i}>{credit.assessment.name}</div>;
                              })}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </ScrollableCard>
              </Modal.Body>
              <Modal.Footer>
                <Row className={"h-100 justify-content-between"}>
                  <Col xs={"auto"} className={"text-18 me-3 text-color-gray-dark fw-medium"}>
                    <Row className={"h-100"}>
                      <Col xs={"auto"} className={"align-content-center px-0"}>
                        {resultAccess ? (
                          <IconSvg icon={"tick-box"} width={30} height={30}></IconSvg>
                        ) : (
                          <IconSvg icon={"cross-box"} width={30} height={30}></IconSvg>
                        )}
                      </Col>
                      <Col xs={"auto"} className={"align-content-center"}>
                        Result Access
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={"auto"} className={"text-18 text-color-gray-dark fw-medium"}>
                    <Row className={"h-100"}>
                      <Col xs={"auto"} className={"align-content-center px-0"}>
                        {cameraRequirement ? (
                          <IconSvg icon={"tick-box"} width={30} height={30}></IconSvg>
                        ) : (
                          <IconSvg icon={"cross-box"} width={30} height={30}></IconSvg>
                        )}
                      </Col>
                      <Col xs={"auto"} className={"align-content-center"}>
                        Camera Requirement
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={"auto"}>
                    <Button type={"submit"} className={"button primary"} style={{ height: "50px", width: "200px" }}>
                      submit
                    </Button>
                  </Col>
                </Row>
              </Modal.Footer>
            </Form>
          </Card>
        </Row>
      </div>
    </Modal>
  );
};
export default ProjectSubmitModal;
