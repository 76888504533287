import React from "react";
import { Badge, Button, Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { UpdateSurveyState } from "../Store";
import { deleteSurvey } from "../Store/Thunk";

export default function SurveyList() {
  const dispatch = useDispatch();
  const surveyList = useSelector((state) => state.survey.surveyList);

  const HandleDeleteButton = (id) => {
    dispatch(deleteSurvey({ id }));
  };

  const HandleUpdateButton = (assess) => {
    var obj = {
      id: assess.id,
      name: assess.name,
      isUpdateOperation: true,
    };

    dispatch(UpdateSurveyState(obj));
  };

  return (
    surveyList &&
    surveyList.map((x, i) => (
      <Card className="mb-3" key={"assess" + i}>
        <Card.Body>
          <Row xs="auto">
            <Col xs="12" md="10">
              <Row>
                <Col xs="1">{x.id}</Col>
                <Col xs="12" md="3">
                  {x.name}
                </Col>
              </Row>
              <Row>
                {x.assessments &&
                  x.assessments.map((assessment, m) => (
                    <Col key={"te" + m} xs="auto">
                      <Badge bg={assessment.isB2b ? "success" : "secondary"}>{assessment.name}</Badge>
                    </Col>
                  ))}
              </Row>
            </Col>
            <Col md="2">
              <Row xs="auto" className="justify-content-md-end">
                <Col>
                  <Button size="sm" variant="success" onClick={() => HandleUpdateButton(x)}>
                    Düzenle
                  </Button>
                </Col>
                <Col>
                  <Button
                    style={{ float: "right" }}
                    variant="danger"
                    size="sm"
                    onClick={() => HandleDeleteButton(x.id)}
                  >
                    Sil
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    ))
  );
}
