import React from "react";
import { Col, Row } from "react-bootstrap";
import IconSvg from "../../../../../../AppMenu/Components/IconSvg";

export default function MonoText({ item, isSub }) {
  let hitRange = item.scale.ranges.find((range) => range.isHit === true);

  return (
    <Row className={(!hitRange && "d-none") + " mb-3"} style={{ height: Number(item?.height), marginBottom: "6px" }}>
      <Col>
        <div className={"round-30px p-2 "} style={{ backgroundColor: "#efefef" }}>
          <div className={"position-relative "}>
            <div className={"position-absolute"} style={{ top: -20, left: -4 }}>
              <IconSvg icon={"documentIcon"} height={30} width={30} />
            </div>

            <Row>
              <Col>
                <div className={"round-30px p-2"} style={{ backgroundColor: "#ffffff", fontSize: 12 }}>
                  {item.scale.isHTML ? <div dangerouslySetInnerHTML={{ __html: hitRange.text }} /> : hitRange.text}
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Col>
    </Row>
  );
}
