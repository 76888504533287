export const AnsweringApplicationApiUrls = {
  finishSession: "ticket/FinishSession",
  finishSessionBeacon: "user/FinishSession",
};

export default class AnsweringApplicationAPI {
  constructor(httpsClient, cache) {
    this.httpsClient = httpsClient;
    this.cache = cache;
  }

  async finishSession(data, token, eAlert, sAlert) {
    return this.get(AnsweringApplicationApiUrls.finishSession, data, token, eAlert, sAlert);
  }

  async get(url, data, token) {
    return await this.httpsClient.get(url, data, token);
  }

  async post(url, data, token) {
    return await this.httpsClient.post(url, data, token);
  }
}
