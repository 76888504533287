import { createSlice } from "@reduxjs/toolkit";
import { getCompanyCredits, getCompanyStatistics, getFinishedApplicationsByDaysCount } from "./Thunk";

export const adminDash = createSlice({
  name: "adminDash",
  initialState: {
    id: "",
    name: "",
    companyCredits: undefined,
    companyStatistics: null,
    finishedApplicationsByDaysCount: { daysCount: null, finishedApplications: null },
    isLoading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCompanyCredits.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getCompanyCredits.fulfilled, (state, action) => {
      state.isLoading = false;
      state.companyCredits = action.payload?.credits;
    });
    builder.addCase(getCompanyStatistics.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getCompanyStatistics.fulfilled, (state, action) => {
      state.isLoading = false;
      state.companyStatistics = action.payload;
    });
    builder.addCase(getFinishedApplicationsByDaysCount.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getFinishedApplicationsByDaysCount.fulfilled, (state, action) => {
      state.isLoading = false;
      state.finishedApplicationsByDaysCount = action.payload?.credits;
    });
  },
});

export const {} = adminDash.actions;

export default adminDash.reducer;
