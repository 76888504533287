import React, { useEffect, useRef, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

export default function ScrollableCard({ children, top, bottom, maximumHeight, emptySpaceHeight }) {
  const [maxHeight, setMaxHeight] = useState(0);
  const divRef = useRef(null);
  const observerRef = useRef(null);
  let desiredBottom = bottom !== undefined ? bottom : 20;
  let desiredTop = top !== undefined ? top : 20;
  const [divTop, setDivTop] = useState(0);

  const calculateHeight = () => {
    let topOfDiv = 0;
    if (divRef.current) {
      if (maximumHeight) {
        setMaxHeight(maximumHeight);
      } else {
        const rect = divRef.current.getBoundingClientRect();
        topOfDiv = rect.top;
        setDivTop(topOfDiv);
        setMaxHeight(window.innerHeight - desiredBottom - topOfDiv);
      }
    } else {
      console.error("Error occurred on ScrollableCard: divRef.current is unavailable");
    }
  };

  useEffect(() => {
    // Create a ResizeObserver for the parent or body
    const parentObserver = new ResizeObserver(() => {
      calculateHeight();
    });

    if (divRef.current && divRef.current.parentElement) {
      parentObserver.observe(divRef.current.parentElement); // Watch the parent element
    } else {
      parentObserver.observe(document.body); // Fallback: watch the body
    }

    // Initial calculation
    calculateHeight();

    return () => {
      // Cleanup observer
      parentObserver.disconnect();
    };
  }, [desiredBottom, divTop, maximumHeight]);

  useEffect(() => {
    // Recalculate height on window resize
    const handleResize = () => {
      calculateHeight();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [desiredBottom, divTop]);

  return (
    <div className={"overflow-y-auto"} style={{ marginTop: desiredTop }}>
      <PerfectScrollbar>
        <div ref={divRef} style={{ maxHeight: `${maxHeight}px` }}>
          {children}
          <div style={emptySpaceHeight !== undefined ? { height: emptySpaceHeight } : { height: 150 }}></div>
        </div>
      </PerfectScrollbar>
    </div>
  );
}
