import React, { useEffect, useMemo, useState } from "react";
import Select from "react-select";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";

import { useDispatch, useSelector } from "react-redux";

import { getTestEntity, updateTest } from "../Store/Thunk";
import useFormattedDate from "../Hooks/useFormattedDate";

export default function ExportExcelAllFilter() {
  const dispatch = useDispatch();
  const tests = useSelector((state) => state.exportExcelAllFilter.testsEntity);
  const { endDate, startDate } = useFormattedDate(new Date());

  const [start, setStartDate] = useState(startDate);
  const [end, setEndDate] = useState(endDate);

  useEffect(() => {
    dispatch(getTestEntity());
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);

    dispatch(
      updateTest({
        testId: formData.get("testId"),
        start: formData.get("startDate"),
        startDate: new Date(formData.get("startDate")),
        end: formData.get("endDate"),
        endDate: new Date(formData.get("endDate")),
      })
    );
  };

  const options = useMemo(
    () =>
      tests
        ? tests.map((t) => ({
            value: t.id,
            label: `${t.id}-${t.name}`,
          }))
        : [],
    [tests]
  );

  const handleChangeDate = (rangeType, value) => {
    rangeType === "start" ? setStartDate(value) : setEndDate(value);
  };

  return (
    <Card body className="mb-3" style={{ background: "#2c3034" }}>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col xs={12} md={6} lg={3}>
            <Select required name="testId" style={{ color: "black" }} options={options} placeholder="Envanter Seç" />
          </Col>
          <Col xs={12} md={6} lg={3} style={{ margin: "auto", display: "grid" }}>
            <input
              required
              name="startDate"
              type="date"
              lang="tr"
              value={start}
              max={start}
              onChange={(event) => handleChangeDate("start", event.target.value)}
            />
          </Col>
          <Col xs={12} md={6} lg={3} style={{ margin: "auto", display: "grid" }}>
            <input
              required
              name="endDate"
              type="date"
              lang="tr"
              value={end}
              min={end}
              onChange={(event) => handleChangeDate("end", event.target.value)}
            />
          </Col>
          <Col xs={12} md={6} lg={3}>
            <Button type="submit" style={{ width: "100%" }} variant="primary">
              Tümünü Aktar
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
}
