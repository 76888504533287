import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import IconSvg from "../../../../AppMenu/Components/IconSvg";

const PdfHeader = ({ appName, personName }) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  useEffect(() => {
    setCurrentDate(new Date());
  }, []);
  return (
    <Row
      className={"pdf-header-footer-text align-content-center"}
      style={{ height: "45px", paddingBottom: "10px", paddingTop: "10px" }}
    >
      <Col>
        <IconSvg icon={"cp-logo"} height={25} width={25}></IconSvg>
      </Col>
      <Col className={"align-content-center text-center"}>
        {personName} | {appName}
      </Col>
      <Col className="text-end align-content-center">
        {currentDate.getDate()}.{currentDate.getMonth()}.{currentDate.getFullYear()}
      </Col>
    </Row>
  );
};
export default PdfHeader;
