import { createSlice } from "@reduxjs/toolkit";
import { createAssessment, deleteAssessment, getAssessments, updateAssessment } from "./Thunk";

export const assessment = createSlice({
  name: "assessment",
  initialState: {
    assessmentList: [],
    selectedApplications: [],
    mappedApplications: [],
    price: "",
    isLoading: false,
    name: "",
    isB2b: false,
    isUpdateOperation: false,
    id: "",
  },
  reducers: {
    UpdateName(state, action) {
      state.name = action.payload;
    },
    UpdateIsB2b(state, action) {
      state.isB2b = action.payload;
    },
    UpdatePrice(state, action) {
      state.price = action.payload;
    },
    UpdateAssessmentState(state, action) {
      state.isUpdateOperation = action.payload.isUpdateOperation;
      state.selectedApplications = action.payload.selectedApplications;
      state.name = action.payload.name;
      state.isB2b = action.payload.isB2b;
      state.id = action.payload.id;
      state.price = action.payload.price;
    },
    UpdateSelectedApplications(state, action) {
      state.selectedApplications = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createAssessment.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createAssessment.fulfilled, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(deleteAssessment.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deleteAssessment.fulfilled, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(updateAssessment.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updateAssessment.fulfilled, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(getAssessments.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getAssessments.fulfilled, (state, action) => {
      state.assessmentList = action.payload.assessments;
      state.mappedApplications = HandleMappingApplications(
        action.payload.inventories,
        action.payload.tests,
        action.payload.surveys
      );
      state.isLoading = false;
    });
  },
});

export const { UpdateName, UpdateIsB2b, UpdateAssessmentState, UpdateSelectedApplications, UpdatePrice } =
  assessment.actions;

export default assessment.reducer;

export function HandleMappingApplications(inventories, tests, surveys) {
  let mappedApplications = [];

  inventories?.forEach((inv) => {
    mappedApplications.push({
      name: inv.name + " (E)",
      label: "(E) " + inv.name,
      id: inv.id,
      value: inv.id + "E",
    });
  });
  tests?.forEach((test) => {
    mappedApplications.push({
      name: test.name + " (T)",
      label: "(T) " + test.name,
      id: test.id,
      value: test.id + "T",
    });
  });
  surveys?.forEach((test) => {
    mappedApplications.push({
      name: test.name + " (A)",
      label: "(A)" + test.name,
      id: test.id,
      value: test.id + "A",
    });
  });

  return mappedApplications;
}
