import React from "react";
import { Col, Row } from "react-bootstrap";

export default function Summary_1({ item, isSub }) {
  const isHtml = item.scale.isHtml;
  let elementScore = item.normScore ? Number(item.normScore) : item.score;

  return (
    <Row style={{ height: Number(item.height), marginBottom: "6px" }}>
      <Col style={{ height: "18px" }} xs={4}>
        <Row>
          <Col xs={"auto"} className={!isSub ? "_main-scope-name-pdf-new" : "_sub-scope-names-pdf-new"}>
            {item.name}
          </Col>
          <Col>
            <div className={isSub ? "_sub-scope-score-pdf-new _flex-center" : "_main-scope-score-pdf-new _flex-center"}>
              {elementScore}
            </div>
          </Col>
        </Row>
      </Col>
      <Col xs={8}>
        <Row>
          {item.scale.ranges.map((range, i) => {
            return (
              <Col
                key={i}
                className={
                  range.isHit
                    ? isSub
                      ? "_scale-style-pdf-new _hit-sub-scale-new _flex-center"
                      : "_scale-style-pdf-new _hit-scale-new _flex-center"
                    : "_scale-style-pdf-new _flex-center"
                }
              >
                {isHtml ? <div dangerouslySetInnerHTML={{ __html: item.monoSpaceHtml }} /> : range.text}
              </Col>
            );
          })}
        </Row>
      </Col>
    </Row>
  );
}
