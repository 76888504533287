import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import React from "react";
import AssignedAssessmentsList from "./AssignedAssessmentsList";
import ScrollableCard from "../../../../SharedComponents/ScrollableCard";

const AssignedAssessments = ({ tickets }) => {
  return (
    <>
      <Row className={"justify-content-between mt-3  mx-0"}>
        <Col xs={"auto"} className={"text-14 text-color-gray-light fw-medium"}>
          Assessments
        </Col>
      </Row>
      <ScrollableCard emptySpaceHeight={50} top={0}>
        <AssignedAssessmentsList />
      </ScrollableCard>
    </>
  );
};

export default AssignedAssessments;
