import React, { useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { useSelector } from "react-redux";
import { ComponentToPrint } from "./ComponentToPrint";

import ApiHelper from "../../../Helpers/ApiHelper";
import Overlay from "../../SharedComponents/Overlay";
import WholePdfFile from "./PdfFile/WholePdfFile";
import DownloadButtonUi from "./DownloadButtonUi";
import "../Styles/PdfFile.css";

export default function TestPdfButton({ demoResultId, testResultId, resultData }) {
  const user = useSelector((state) => state.auth.user);

  const [showOverlay, setShowOverlay] = useState(false);
  const [pdfComponent, setPdfComponent] = useState(null);
  const componentRef = useRef(null);

  let StartPdfExportProcess = async () => {
    setShowOverlay(true);
    if (resultData) MapResponse(resultData);
    else if (demoResultId || testResultId) {
      const id = demoResultId ? demoResultId : testResultId;
      const endPoint = demoResultId ? "GetTestDemoCalculation" : "GetTestCalculation";
      const url = "ticket/" + endPoint;
      ApiHelper.getAsync(
        url,
        { id },
        function (response) {
          if (response.data.statusCode) MapResponse(response.data.data);
          else alert("bir hata oluştu"); //todo burada hatayi handle et , guzek bir alert kutusu goster gerekirse sayfayi kapat
        },
        user.token
      );
    } else {
      setShowOverlay(false);
      alert("problem");
    }
  };

  let MapResponse = (result) => {
    let demography = {
      name: result.name,
      email: result.email,
      department: result.department,
      faculty: result.faculty,
      school: result.school,
      birthYear: result.birthYear,
      gender: result.gender,
    };

    let appData = {
      pdfCoverImageName: result.pdfCoverImageName,
      pdfInfoImageName: result.pdfInfoImageName,
      name: result.applicationName,
    };
    const scheme = JSON.parse(result.pdfJson);
    const calculation = JSON.parse(result.calculationJson);

    scheme.forEach((page) => HandleEachPage(page, calculation));
    setPdfComponent(
      <WholePdfFile
        StartDownloadPdf={HandlePrint}
        scheme={scheme}
        appData={appData}
        demography={demography}
        type="test"
        qrURL={CreateQrUrl()}
      />
    );
  };

  let HandleEachPage = (pageReportItems, calculation) => {
    pageReportItems.forEach((reportItem) => {
      if (reportItem.htmlEl) return;
      else if (reportItem.componentName === "TestScaleEntity") reportItem.score = calculation.testScore;
      else reportItem.score = calculation?.calculatedScopes.find((x) => x.scopeId === reportItem.scopeId)?.score;
    });
  };

  const CreateQrUrl = () => {
    let qrURL = "";
    if (demoResultId) qrURL = window.location.origin + "/#/tdemoResult/" + demoResultId;
    else if (testResultId) qrURL = window.location.origin + "/#/tResult/" + testResultId;
    return qrURL;
  };

  const HandlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforePrint: () => {
      setShowOverlay(false);
    },
    onAfterPrint: () => {
      setPdfComponent(null);
    },
  });

  return (
    <>
      {showOverlay && <Overlay text="Pdf Dosyası Hazırlanıyor" />}
      <DownloadButtonUi StartPdfExportProcess={StartPdfExportProcess} />
      <ComponentToPrint ref={componentRef}>
        <div className="onlyPrint" ref={componentRef}>
          {pdfComponent}
        </div>
      </ComponentToPrint>
    </>
  );
}
