import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import ApiHelper from "../../../Helpers/ApiHelper";

import { ChecboxesImage } from "./Questions/ChecboxesImage";
import { PolarLikertSlider } from "./Questions/PolarLikertSlider";
import { ApplicationInfo } from "../SharedInfoPages/ApplicationInfo";
import { CandidateInfo } from "../SharedInfoPages/CandidateInfo";
import Overlay from "../../SharedComponents/Overlay";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import AnsweringPageWrapper from "../SharedComponents/AnsweringPageWrapper";

const MySwal = withReactContent(Swal);

export function DemoInventory(props) {
  const { ticketId } = useParams();
  const { invId } = useParams();

  const user = useSelector((state) => state.auth.user);

  const navigate = useNavigate();
  const [ticket, setTicket] = useState(null);

  const [isOverlayVisible, setIsOverlayVisible] = useState(true);
  const [applicationData, setApplicationData] = useState({
    questions: null,
    checkboxes: null,
    invId: 0,
    answers: null,
    info: null,
  });
  const [isInfoGot, setIsInfoGot] = useState(ticketId ? true : false);
  const [isInfoShowed, setIsInfoShowed] = useState();
  const [isCheckboxesFinished, setIsCheckboxesFinished] = useState(false);

  const [postData, setPostData] = useState({
    demoResultId: null,
    info: {},
    checkboxAnswersJson: [],
    questionAnswersJson: [],
  });

  const [finishedTestData, setFinishedTestData] = useState(false);

  useEffect(() => {
    document.getElementById("login")?.remove();
    if (invId || ticketId) {
      GetInventoryImageB2c();
    } else {
      setIsOverlayVisible(false);
      MySwal.fire({
        title: <strong>Hata</strong>,
        html: <i>Bozuk Link</i>,
        icon: "error",
      });
      return;
    }
  }, []);

  let GetInventoryImageB2c = () => {
    ApiHelper.getAsync(
      "user/GetInventoryImage",
      { invId },
      function (result) {
        setIsOverlayVisible(false);
        if (result.data.statusCode === 1) {
          HandlequestionStatesAndOpenApplication(result.data.data);
        } else {
          MySwal.fire({
            title: <strong>Hata</strong>,
            html: <i>Bozuk Link</i>,
            icon: "error",
          });
        }
      },
      null
    );
  };

  let HandlequestionStatesAndOpenApplication = (inv) => {
    const questions = JSON.parse(inv.questionsImage.questionsJson);
    const checkboxes = JSON.parse(inv.questionsImage.checkboxQuestionsJson);
    const answers = JSON.parse(inv.questionsImage.answersJson);
    const info = inv.info;

    setApplicationData({
      questions,
      checkboxes,
      invId: inv.id,
      answers,
      info,
    });

    if (checkboxes === null) setIsCheckboxesFinished(true);
  };

  let GoBackToAdjQuestions = () => {
    setIsCheckboxesFinished(false);
  };

  let FinishCheckboxes = (checkboxAnswersJson) => {
    setIsCheckboxesFinished(true);
    if (!applicationData?.questions?.length) {
      PostFinishedInventory(null, checkboxAnswersJson);
    } else {
      setPostData((pd) => ({ ...pd, checkboxAnswersJson }));
    }
  };

  let FinishCandidateInfo = (info) => {
    setIsOverlayVisible(true);
    info.inventoryId = applicationData.invId;
    ApiHelper.postAsync(
      "user/CreateDemoResult",
      info,
      function (result) {
        setIsOverlayVisible(false);
        if (result.data.statusCode === 1) {
          setIsInfoGot(true);
          setPostData((pd) => ({
            ...pd,
            info,
            demoResultId: result.data.data.id,
          }));
        } else {
          MySwal.fire({
            title: <strong>Hata</strong>,
            html: <i>Bir hata oluştu</i>,
            icon: "error",
          });
        }
      },
      null
    );
  };

  let CreateTicketResut = () => {
    setIsOverlayVisible(true);
    ApiHelper.postAsync(
      "ticket/CreateTicketResult",
      {
        ticketId: ticketId,
        checkboxAnswersJson: JSON.stringify(applicationData.adjQuestions),
        questionAnswersJson: JSON.stringify(applicationData.questions),
        remainingDuration: applicationData.duration * 60,
      },
      function (result) {
        setIsOverlayVisible(false);
        if (result.data.statusCode === 1) {
          setTicket({ ...ticket, ticketResult: result.data.data });
          setIsInfoShowed(true);
        } else {
          //todo kayi alinmazsa modal goster
        }
      },
      user.token
    );
  };

  let FinishInfo = () => {
    if (props.b2b && !ticket?.ticketResult && ticket?.clickCounter <= 1) CreateTicketResut();
    else setIsInfoShowed(true);
  };

  let DismissFinish = (questionAnswersJson) => {
    setPostData((pd) => ({ ...pd, questionAnswersJson }));
  };

  let FinishAnsweringInventory = (questionAnswersJson) => {
    PostFinishedInventory(questionAnswersJson);
  };

  let PostFinishedInventory = async (questionAnswersJson, checkboxAnswersJson) => {
    setIsOverlayVisible(true);

    let finishingInventoryPostObj = {
      id: postData.demoResultId,
      inventoryId: applicationData.invId,
      checkboxAnswersJson: JSON.stringify(checkboxAnswersJson ? checkboxAnswersJson : postData.checkboxAnswersJson),
      questionAnswersJson: JSON.stringify(questionAnswersJson),
      isFinished: true,
      candidateEmail: user?.userName,
      candidateName: user?.demography?.name,
    };

    ApiHelper.postAsync(
      "user/UpdateDemoResult",
      finishingInventoryPostObj,
      function (result) {
        setIsOverlayVisible(false);
        if (result?.data?.statusCode === 1) {
          setFinishedTestData(result.data.data);
          result.data.data.invId = applicationData.invId;
          navigate("/idemoResult/" + finishingInventoryPostObj.id, { state: result.data.data });
        } else {
          MySwal.fire({
            title: <strong>Hata</strong>,
            html: <i>Bir problem olustu: {result.data.data} </i>,
            icon: "error",
          });
        }
      },
      null
    );
  };

  let HandleFinishButton = () => {
    PostFinishedInventory(postData.questionAnswersJson);
  };

  let HandleShowThanks = () => {
    if (finishedTestData && (!props.b2c || !ticket?.allowPdf)) return true;
    else return false;
  };

  return (
    <AnsweringPageWrapper>
      {isOverlayVisible && <Overlay />}
      {applicationData && applicationData.invId !== null && applicationData.invId !== "" && !isInfoGot && (
        <CandidateInfo Finish={FinishCandidateInfo} />
      )}
      {applicationData &&
        applicationData.invId !== null &&
        applicationData.invId !== "" &&
        isInfoGot &&
        !isInfoShowed && (
          <ApplicationInfo Finish={FinishInfo} info={applicationData.info} clickCounter={ticket?.clickCounter} />
        )}

      {isInfoGot && isInfoShowed && !isCheckboxesFinished && applicationData && applicationData.checkboxes && (
        <ChecboxesImage
          q={postData.checkboxAnswersJson?.length > 0 ? postData.checkboxAnswersJson : applicationData.checkboxes}
          Finish={FinishCheckboxes}
          isOnlyChecklist={!applicationData?.questions?.length}
        />
      )}
      {!finishedTestData &&
        isInfoGot &&
        isInfoShowed &&
        isCheckboxesFinished &&
        applicationData &&
        applicationData.questions?.length && (
          <PolarLikertSlider
            isB2b={false}
            questions={applicationData.questions}
            answers={applicationData.answers}
            openCam={ticket?.openCam}
            finish={FinishAnsweringInventory}
            dismissFinish={DismissFinish}
            goBack={GoBackToAdjQuestions}
            isFinished={!finishedTestData && postData.questionAnswersJson}
            HandleFinishButton={HandleFinishButton}
          />
        )}

      {HandleShowThanks() && <h1 className="thank-message"> Katılımınız için teşekkürler </h1>}
      {window.innerWidth <= 500 && <div style={{ height: "200px", width: "100%" }}></div>}
    </AnsweringPageWrapper>
  );
}
