import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { RemoveFromAlertArrayById } from "../Store";

const AlertItem = ({ id, text, type }) => {
  const dispatch = useDispatch();
  const [remainingTime, setRemainingTime] = useState(10); // 10 seconds countdown

  useEffect(() => {
    const creationTime = Date.now();
    const endTime = creationTime + 10000; // 10 seconds from creation

    const interval = setInterval(() => {
      const now = Date.now();
      const timeLeft = Math.max(0, Math.round((endTime - now) / 1000)); // Calculate remaining time
      setRemainingTime(timeLeft);

      if (timeLeft <= 0) {
        clearInterval(interval);
        dispatch(RemoveFromAlertArrayById(id)); // Dispatch action to remove this alert
      }
    }, 100);

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [dispatch, id]);

  // Calculate the percentage of time passed
  const progressPercentage = ((10 - remainingTime) / 9) * 100; // 9 because it allows for bar to fill completely during the last second

  return (
    <div className={"bg-success default-alert mb-3"}>
      <div style={{ marginBottom: "8px" }}>
        <div>{text}</div>
        <div>Time remaining: {remainingTime} seconds</div>
      </div>
      <div style={{ height: "10px", background: "#e0e0e0", overflow: "hidden" }}>
        <div
          style={{
            width: `${progressPercentage}%`,
            height: "100%",
            background: "green",
            transition: "width 1s linear",
          }}
        ></div>
      </div>
    </div>
  );
};

export default AlertItem;
