import { createSlice } from "@reduxjs/toolkit";
import { createSurvey, deleteSurvey, getSurveys, updateSurvey } from "./Thunk";

export const survey = createSlice({
  name: "survey",
  initialState: {
    id: "",
    name: "",
    surveyList: [],
    isLoading: false,
    isUpdateOperation: false,
  },
  reducers: {
    UpdateName(state, action) {
      state.name = action.payload;
    },
    UpdateSurveyState(state, action) {
      state.isUpdateOperation = action.payload.isUpdateOperation;
      state.name = action.payload.name;
      state.id = action.payload.id;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createSurvey.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createSurvey.fulfilled, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(deleteSurvey.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deleteSurvey.fulfilled, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(updateSurvey.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updateSurvey.fulfilled, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(getSurveys.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getSurveys.fulfilled, (state, action) => {
      state.surveyList = action.payload;
      state.isLoading = false;
    });
  },
});

export const { UpdateName, UpdateSurveyState } = survey.actions;

export default survey.reducer;
