const CreateItems = (scope, subScope, array) => {
  const CreateEntityObj = (item, componentName) => {
    return {
      ...item,
      scopeName: scope.name,
      subScopeName: subScope?.name,
      scopeId: scope.id,
      subScopeId: subScope?.id,
      componentName,
    };
  };

  let needToLoop = subScope ? subScope : scope;

  needToLoop.summaries?.forEach((item, i) => {
    array.push(CreateEntityObj(item, "BasicGraphSummaryEntity"));
  });

  needToLoop.details?.forEach((item, i) => {
    array.push(CreateEntityObj(item, "BasicGraphDetailEntity"));
  });

  needToLoop.monos?.forEach((item, i) => {
    array.push(CreateEntityObj(item, "MonoTextuaryEntity"));
  });

  needToLoop.duals?.forEach((item, i) => {
    array.push(CreateEntityObj(item, "DualTextuaryEntity"));
  });
};

export const GenerateReportItemsArray = (application, role) => {
  //switch case  user Type  gore usetunde calisilacak array i sec

  //isListVisible true gelince sadece isVisible trueları döndür, false gelirse falseları döndür

  let itemsArray = [];

  if (application.hasOwnProperty("scales")) {
    itemsArray.push({
      name: "Test Scale",
      componentName: "TestScaleEntity",
      scales: application.scales,
      designType: 9,
    });
  } else if (application.hasOwnProperty("leanParameters"))
    itemsArray.push({
      name: "Answering Tendency",
      componentName: "AnsweringTendencyEntity",
      leanParameters: application.leanParameters,
      designType: 8,
    });

  application.scopes.forEach((scope) => {
    CreateItems(scope, null, itemsArray);
    scope.subScopes.forEach((subScope) => {
      CreateItems(scope, subScope, itemsArray);
    });
  });
  // *role*WrapUpJson <------{}

  let wrapUpJsonFieldName = `${role.toLowerCase()}WrapUpJson`;
  if (application.reportScheme && application.reportScheme[wrapUpJsonFieldName])
    return SelectAndSortAlreadyOrderedReportItems(itemsArray, application, role);
  else return SelectAndSortReportItemsForFirsttime(itemsArray);
};

const SelectAndSortAlreadyOrderedReportItems = (itemsArray, application, role) => {
  let visibleAndOrderedOnes = [];

  switch (role) {
    case "Admin":
      visibleAndOrderedOnes =
        application?.reportScheme?.adminWrapUpJson && JSON.parse(application.reportScheme.adminWrapUpJson);
      break;
    case "User":
      visibleAndOrderedOnes =
        application?.reportScheme?.userWrapUpJson && JSON.parse(application.reportScheme.userWrapUpJson);
      break;
  }

  const htmlEls = [];

  visibleAndOrderedOnes.forEach((item) => {
    item.orderedBefore = true;
    item.edited = false;
    if (item.htmlEl) {
      htmlEls.push(item);
    }
  });

  let visibleArray = [];
  itemsArray.forEach((item) => {
    let foundItem = visibleAndOrderedOnes.find((alreadyOrderedItem) => {
      if (alreadyOrderedItem.id == item.id && alreadyOrderedItem.componentName == item.componentName) {
        item.height = alreadyOrderedItem.height;
        item.order = alreadyOrderedItem.order;
        item.orderedBefore = true;
        item.edited = false;
        item.isVisible = true;

        //visiblilty durumu redux array ine isle


        return true;
      }
    });

    if (foundItem !== undefined) visibleArray.push(foundItem);
  });


  let nonVisibleArray = [];
  itemsArray.forEach((alreadyOrderedItem) => {
    let foundItem = visibleAndOrderedOnes.find((item) => {
      if (alreadyOrderedItem.id == item.id && alreadyOrderedItem.componentName == item.componentName) {
        return true;
      }
    });
    if (foundItem === undefined) {
      nonVisibleArray.push(alreadyOrderedItem);
    }
  });


  visibleArray = visibleArray.concat(htmlEls);

  visibleArray.sort((a, b) => a.order - b.order);

  visibleArray.forEach((x, i) => {
    x.order = i + 1;
    x.isVisible = true;
    x.orderedBefore = true;
    x.edited = false;
  });


  nonVisibleArray.forEach((x, i) => {
    x.order = i + 1;
    x.isVisible = false;
    x.orderedBefore = true;
    x.edited = false;
  });


  return { visibles: visibleArray, nonVisibles: nonVisibleArray };
};

const SelectAndSortReportItemsForFirsttime = (itemsArray) => {
  itemsArray.forEach((x, i) => {
    x.order = i + 1;
    x.isVisible = true;
  });

  return { visibles: itemsArray, nonVisibles: [] };
};
