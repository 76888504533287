import { createAsyncThunk } from "@reduxjs/toolkit";

import HttpsClient from "../../../../../Services/HttpsClient";
import ApiHelper from "../../../../../Services/ApiHelper";
import EntityListAPI from "../API/EntityListAPI";
import JSONDataManager from "../../../../../Services/JSONDataManager/JSONDataManager";
import LocalStorage from "../../../../../Services/Storage/LocalStorage";
import StorageModel from "../../../../../Models/StorageModel";
import Cache from "../../../../../Services/Cache";

const storage = new LocalStorage();
const storageModel = new StorageModel(storage);
const cache = new Cache(storageModel);

const httpsClient = new HttpsClient();
const apiHelper = new ApiHelper(httpsClient);
const entityListAPI = new EntityListAPI(apiHelper, cache);
const jsonDataManager = new JSONDataManager();

export const getTests = createAsyncThunk("entityList/GetTests", async (payload, getState) => {
  const state = getState.getState();
  const token = state.auth.user.token;

  const data = await entityListAPI.getTests({ isTest: state.testEntityList.isTest }, token);

  return data;
});

export const updateTest = createAsyncThunk("entityList/UpdateTest", async (payload, getState) => {
  const state = getState.getState();
  const token = state.auth.user.token;

  await entityListAPI.updateTest(payload, token);

  const data = await entityListAPI.getTests({ isTest: state.testEntityList.isTest }, token);

  return data;
});

export const deleteTest = createAsyncThunk("entityList/DeleteTest", async (payload, getState) => {
  const state = getState.getState();
  const token = state.auth.user.token;
  payload.isTest = state.testEntityList.isTest;
  await entityListAPI.deleteTest(payload, token);

  const data = await entityListAPI.getTests({ isTest: state.testEntityList.isTest }, token);

  return data;
});

export const getApplicationBackup = createAsyncThunk("entityList/GetApplicationBackup", async (payload, getState) => {
  const state = getState.getState();
  const token = state.auth.user.token;

  const data = await entityListAPI.getApplicationBackup({ id: payload.id }, token);

  jsonDataManager.downloadJSON(data, payload.name);

  return data;
});

export const upsertLeanParameters = createAsyncThunk("entityList/UpsertLeanParameters", async (payload, getState) => {
  const state = getState.getState();
  const token = state.auth.user.token;

  await entityListAPI.upsertLeanParameters(payload, token);

  const data = await entityListAPI.getTests({ isTest: state.testEntityList.isTest }, token);

  return data;
});
