import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { ComponentToPrint } from "./ComponentToPrint";

import ApiHelper from "../../../Helpers/ApiHelper";
import Overlay from "../../SharedComponents/Overlay";
import WholePdfFile from "./PdfFile/WholePdfFile";
import DownloadButtonUi from "./DownloadButtonUi";
import "../Styles/PdfFile.css";

export default function InventoryPdfButton({ demoResultId, inventoryResultId, resultData }) {
  const user = useSelector((state) => state.auth.user);

  const [showOverlay, setShowOverlay] = useState(false);
  const [pdfComponent, setPdfComponent] = useState(null);
  const componentRef = useRef(null);

  let StartPdfExportProcess = async () => {
    setShowOverlay(true);
    if (resultData) MapResponse(resultData);
    else if (demoResultId || inventoryResultId) {
      const id = demoResultId ? demoResultId : inventoryResultId;
      const endPoint = demoResultId ? "GetInventoryDemoCalculation" : "GetInventoryCalculation";
      const url = "ticket/" + endPoint;
      ApiHelper.getAsync(
        url,
        { id },
        function (response) {
          if (response.data.statusCode) MapResponse(response.data.data);
          else alert("bir hata oluştu"); //todo burada hatayi handle et , guzek bir alert kutusu goster gerekirse sayfayi kapat
        },
        user.token
      );
    } else {
      setShowOverlay(false);
      alert("problem");
    }
  };

  let MapResponse = (result) => {
    let demography = {
      name: result.demography.name,
      email: result.demography.email,
      department: result.demography.department,
      faculty: result.demography.faculty,
      school: result.demography.school,
      birthYear: result.demography.birthYear,
      gender: result.demography.gender,
    };

    let appData = {
      pdfCoverImageName: result.pdfCoverImageName,
      pdfInfoImageName: result.pdfInfoImageName,
      name: result.applicationName,
    };

    const scheme = JSON.parse(result.pdfJson);
    const calculation = JSON.parse(result.calculationJson);

    scheme.forEach((page) => HandleEachPage(page, calculation));

    setPdfComponent(
      <WholePdfFile
        StartDownloadPdf={HandlePrint}
        scheme={scheme}
        appData={appData}
        demography={demography}
        type={"inventory"}
        qrURL={CreateQrUrl()}
      />
    );
  };

  let HandleEachPage = (pageReportItems, calculation) => {
    let calculatedScopes = calculation.calculatedScopes;
    let consistencyScore = calculation.consistencyScore.score;
    let inventoryLeanNumbers = calculation.inventoryLeanNumbers;
    pageReportItems.forEach((reportItem) => {
      if (reportItem.htmlEl) return;
      else if (reportItem.componentName === "AnsweringTendencyEntity") {
        reportItem.consistencyScore = consistencyScore;
        reportItem.inventoryLeanNumbers = inventoryLeanNumbers;
      } else if (reportItem.subScopeId)
        reportItem.score = calculatedScopes.find(
          (x) => x.isSub && x.scopeId === reportItem.subScopeId
        )?.calculatedScore;
      else {
        reportItem.score = calculatedScopes.find(
          (x) => x.isSub === false && x.scopeId === reportItem.scopeId
        )?.calculatedScore;
      }
    });
  };

  const CreateQrUrl = () => {
    let qrURL = "";
    if (demoResultId) qrURL = window.location.origin + "/#/idemoResult/" + demoResultId;
    else if (inventoryResultId) qrURL = window.location.origin + "/#/iResult/" + inventoryResultId;
    return qrURL;
  };

  const HandlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforePrint: () => {
      setShowOverlay(false);
    },
    onAfterPrint: () => {
      setPdfComponent(null);
    },
  });

  return (
    <>
      {showOverlay && <Overlay text="Pdf Dosyası Hazırlanıyor" />}
      <DownloadButtonUi StartPdfExportProcess={StartPdfExportProcess} />
      <ComponentToPrint ref={componentRef}>
        <div className="onlyPrint" ref={componentRef}>
          {pdfComponent}
        </div>
      </ComponentToPrint>
    </>
  );
}
