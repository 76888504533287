import React from "react";
import Modal from "react-bootstrap/Modal";
import { Card, Col, Row } from "react-bootstrap";
import { setEntityModalInfo } from "../../../Store";
import { useDispatch } from "react-redux";
import ScaleEntity from "./ScaleEntity";
import Container from "react-bootstrap/Container";
import "../../../../../../WrapUp/Styles/WrapUp.css";
import "../../../../../../PdfDownloadButton/Styles/PdfFile.css";

import { SelectPdfReportItemDesign } from "../../../../../../PdfDownloadButton/Components/PdfReportItems/ReportItems/SelectPdfReportItemDesign";
import SelectWrapUpReportItemDesign from "../../../../../../WrapUp/Components/ReportItems/SelectWrapUpReportItemDesign";
import { HandleTestScore } from "../../../../../../../Helpers/ScoreNormalizationHelper";

const EntityInfoModal = ({ isShown, entity }) => {
  const dispatch = useDispatch();

  const randomIntFromInterval = (min, max) => {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  };
  let type = null;
  let entityCopy = JSON.parse(JSON.stringify(entity));
  let entityCopyWithRandomizedScore = { ...entityCopy, score: randomIntFromInterval(1, 100) };
  if (entityCopyWithRandomizedScore.scale?.ranges) {
    HandleTestScore(entityCopyWithRandomizedScore.scale.ranges, entityCopyWithRandomizedScore.score);
  } else {
    if (entityCopyWithRandomizedScore.scales) {
      HandleTestScore(entityCopyWithRandomizedScore.scales, entityCopyWithRandomizedScore.score);
    } else {
      console.log("NO RANGES FOUND ON entityCopyWithRandomizedScore, CHECK EntityInfoModal");
    }
  }
  if (entity.componentName === "AnsweringTendencyEntity") {
    entityCopyWithRandomizedScore = {
      ...entityCopyWithRandomizedScore,
      inventoryLeanNumbers: {
        edgeLean: randomIntFromInterval(1, 100),
        middleLean: randomIntFromInterval(1, 100),
        highLean: randomIntFromInterval(1, 100),
        lowLean: randomIntFromInterval(1, 100),
      },
      consistencyScore: randomIntFromInterval(1, 100),
    };
  }

  switch (entity.isTest) {
    case true:
      type = "test";
      break;
    case false:
      type = "inventory";
      break;
  }

  const cleanUpEntityModalInfo = () => {
    dispatch(
      setEntityModalInfo({
        entity: {},
        isShown: false,
      })
    );
  };
  const CreateHtmlElement = (val, headerText) => {
    return (
      <Col>
        <Card>
          <Card.Header> {headerText} </Card.Header>
          <Card.Body>
            <div dangerouslySetInnerHTML={{ __html: val }} />
          </Card.Body>
        </Card>
      </Col>
    );
  };
  return (
    <Modal fullscreen={true} centered show={isShown} onHide={cleanUpEntityModalInfo}>
      <Modal.Header closeButton>
        <Modal.Title>{entity.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            {entity.componentName !== "AnsweringTendencyEntity" && (
              <ScaleEntity scale={entity.scale} testScaleRanges={entity.scales}></ScaleEntity>
            )}
          </Row>
          {entity.componentName === "BasicGraphDetailEntity" && (
            <Row className="my-3">
              {CreateHtmlElement(entity?.lowSideText, "Sol ölçek açıklaması")}
              {entity?.generalInformationText && CreateHtmlElement(entity?.generalInformationText, "Genel açıklama")}
              {CreateHtmlElement(entity?.highSideText, "Sağ ölçek açıklaması")}
            </Row>
          )}
          PREVIEW
          <Row className={"justify-content-center my-5"}>
            <div
              className={"justify-content-center shadow-sm rounded"}
              style={{ width: "792px", padding: "0 30px", backgroundColor: "white" }}
            >
              {SelectPdfReportItemDesign(entityCopyWithRandomizedScore, type)}
            </div>
          </Row>
          <Row>
            <Col>
              <SelectWrapUpReportItemDesign
                item={entityCopyWithRandomizedScore}
                type={type}
              ></SelectWrapUpReportItemDesign>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};
export default EntityInfoModal;
