import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import ApiHelper from "../../../Helpers/ApiHelper";

import { QuestionSlider } from "./Questions/QuestionSlider";
import { ApplicationInfo } from "../SharedInfoPages/ApplicationInfo";
import Overlay from "../../SharedComponents/Overlay";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useDispatch, useSelector } from "react-redux";
import AnsweringPageWrapper from "../SharedComponents/AnsweringPageWrapper";
import { SetSessionId } from "../AnswerInventoryApp/Store";

const MySwal = withReactContent(Swal);

export function B2bTest() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);

  const { ticketId } = useParams();
  const { testId } = useParams();

  const [isOverlayVisible, setIsOverlayVisible] = useState(true);

  const [applicationData, setApplicationData] = useState();
  const [isInfoShowed, setIsInfoShowed] = useState();

  const [postData, setPostData] = useState({
    questionAnswersJson: false,
  });

  const [finishedTestData, setFinishedTestData] = useState(false);

  useEffect(() => {
    document.getElementById("login")?.remove();
    if (testId || ticketId) {
      GetTestImageAndHandleClickCounter();
    } else {
      setIsOverlayVisible(false);
      MySwal.fire({
        title: <strong>Hata</strong>,
        html: <i>Bozuk Link</i>,
        icon: "error",
      });
      return;
    }
  }, []);

  let GetTestImageAndHandleClickCounter = () => {
    ApiHelper.getAsync(
      "ticket/GetTestImageAndStartSession",
      { ticketId, testId },
      function (result) {
        setIsOverlayVisible(false);
        if (result.data.statusCode === 1) {
          HandleApplicationData(result.data.data);
        } else {
          MySwal.fire({
            title: <strong>Hata</strong>,
            html: <i>{result.data.data}</i>,
            icon: "error",
          });
        }
      },
      user.token
    );
  };

  let HandleApplicationData = (ticket) => {
    dispatch(SetSessionId(ticket?.currentSessionId));

    setApplicationData({
      testName: ticket?.applicationContent?.name,
      testId: ticket?.applicationContent?.Id,
      testResultId: ticket?.applicationContent?.resultId,
      duration: ticket?.applicationContent?.remainingDuration,
      questions: JSON.parse(ticket?.applicationContent?.questionsJson),
      sessionId: ticket?.currentSessionId,
      ticketId: ticket?.ticketId,
      clickCounter: ticket?.clickCounter,
      allowPdf: ticket?.allowPdf,
      openCam: ticket?.openCam,
      applicationInfo: {
        infoHtml: ticket?.applicationContent?.infoHtml,
        infoTitle: ticket?.applicationContent?.infoTitle,
      },
    });
  };

  let FinishInfo = () => {
    setIsInfoShowed(true);
  };

  let DismissFinish = (questionAnswersJson) => {
    setPostData((pd) => ({ ...pd, questionAnswersJson }));
  };

  let FinishTest = (questionAnswersJson) => {
    PostFinishedTest(questionAnswersJson);
  };

  let UpdateQuestionsJson = async (questionAnswersJson) => {
    ApiHelper.postAsync(
      "ticket/UpdateTestQuestionsJson",
      {
        id: applicationData.testResultId,
        json: JSON.stringify(questionAnswersJson),
      },
      function (result) {},
      user.token
    );
  };

  let UpdateDurationAndPhoto = async (duration, photo) => {
    const formData = new FormData();

    formData.append("testResultId", applicationData.testResultId);
    formData.append("duration", duration);
    photo && formData.append("photo", photo);

    ApiHelper.xmlPostFormData("ticket/UpdateDurationAndPhoto", formData, user.token);
  };

  let PostFinishedTest = async (questionAnswersJson) => {
    setIsOverlayVisible(true);

    let finishingTestPostObj = {
      ticketId: ticketId,
      sessionId: applicationData.sessionId,
      testResultId: applicationData.testResultId,
      questionAnswersJson: questionAnswersJson && JSON.stringify(questionAnswersJson),
    };

    ApiHelper.postAsync(
      "ticket/FinishTestApplication",
      finishingTestPostObj,
      function (result) {
        setIsOverlayVisible(false);
        if (result?.data?.statusCode === 1) {
          if (applicationData?.allowPdf) navigate("/tresult/" + applicationData.testResultId);
          //todo else show thanks message
        } else {
          MySwal.fire({
            title: <strong>Hata</strong>,
            html: <i>Bir problem olustu: {result.data.data} </i>,
            icon: "error",
          });
        }
      },
      user.token
    );
  };

  let HandleFinishButton = () => {
    PostFinishedTest(postData.questionAnswersJson);
  };

  let HandleShowThanks = () => {
    // if (finishedTestData && (!props.b2c || !ticket?.allowPdf)) return true;
    //else return false;
    // todo allowPdf e gore ilgili wrapup a yonlendir
  };
  return (
    <AnsweringPageWrapper>
      {isOverlayVisible && <Overlay />}

      {applicationData && applicationData.testId !== null && applicationData.testId !== "" && !isInfoShowed && (
        <ApplicationInfo
          Finish={FinishInfo}
          clickCounter={applicationData?.inventory?.clickCounter}
          applicationInfo={applicationData.applicationInfo}
        />
      )}

      {!finishedTestData && isInfoShowed && applicationData && applicationData.questions && (
        <QuestionSlider
          isB2b={true}
          questions={applicationData.questions}
          duration={applicationData.duration}
          openCam={applicationData?.openCam}
          finish={FinishTest}
          dismissFinish={DismissFinish}
          Update={UpdateQuestionsJson}
          UpdateDurationAndPhoto={UpdateDurationAndPhoto}
          isFinished={!finishedTestData && postData.questionAnswersJson}
          HandleFinishButton={HandleFinishButton}
        />
      )}

      {HandleShowThanks() && <h1 className="thank-message"> Katılımınız için teşekkürler </h1>}
      {window.innerWidth <= 500 && <div style={{ height: "200px", width: "100%" }}></div>}
    </AnsweringPageWrapper>
  );
}
