import { createAsyncThunk } from "@reduxjs/toolkit";

import HttpsClient from "../../../../../Services/HttpsClient";
import ApiHelper from "../../../../../Services/ApiHelper";
import ReportSchemeAPI, { ReportSchemeApiUrls } from "../API/ReportSchemeAPI";

const httpsClient = new HttpsClient();
const apiHelper = new ApiHelper(httpsClient);
const reportSchemeAPI = new ReportSchemeAPI(apiHelper);

export const getAllReportItems = createAsyncThunk(
  ReportSchemeApiUrls.getAllReportItems,
  async (payload, { getState, dispatch }) => {
    const state = getState();
    const token = state.auth.user.token;
    let data = await reportSchemeAPI.getAllReportItems(payload, token);
    return data;
  }
);

export const createReportScheme = createAsyncThunk(
  ReportSchemeApiUrls.createScheme,
  async (payload, { getState, dispatch }) => {
    const state = getState();
    const token = state.auth.user.token;
    await reportSchemeAPI.createReportScheme(payload, token);
    dispatch(getAllReportItems({ id: state.reportScheme.testId, applicationType: payload.applicationType }, token));
  }
);

export const deleteReportScheme = createAsyncThunk(
  ReportSchemeApiUrls.deleteScheme,
  async (payload, { getState, dispatch }) => {
    const state = getState();
    const token = state.auth.user.token;
    await reportSchemeAPI.deleteReportScheme(payload, token);
    dispatch(getAllReportItems({ id: state.reportScheme.testId }, token));
  }
);

export const updateReportScheme = createAsyncThunk(
  reportSchemeAPI.updateScheme,
  async (payload, { getState, dispatch }) => {
    const state = getState();
    const token = state.auth.user.token;
    await reportSchemeAPI.updateReportScheme(payload, token);
    dispatch(getAllReportItems({ id: state.reportScheme.testId, applicationType: payload.applicationType }, token));
  }
);
