import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import PositionList from "./PositionList";
import UpsertPositionModal from "./UpsertPositionModal";
import { ChangeIsUpdateOperation, ShowUpsertPositionModal } from "../Store";
import Overlay from "../../../SharedComponents/Overlay";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import IconSvg from "../../../AppMenu/Components/IconSvg";
import ScrollableCard from "../../../SharedComponents/ScrollableCard";
import { getPositionsByCompanyId } from "../Store/Thunk";

export default function CreatePosition() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.position.isLoading);
  // const companyId = state?.id;
  const companyId = useSelector((state) => state.auth.user.companyUser.company.companyId);
  const positionList = useSelector((state) => state.createPosition.positionList);

  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    dispatch(getPositionsByCompanyId({ id: companyId }));
  }, []);

  const FilteredPositionList = () => {
    return positionList.filter((position) => {
      const matchesName = searchInput ? position.name.toLowerCase().includes(searchInput.toLowerCase()) : true;
      const matchesId = searchInput ? position.id.toString().includes(searchInput.toLowerCase()) : true;
      return matchesName || matchesId;
    });
  };

  const HandleCreateClick = () => {
    dispatch(ChangeIsUpdateOperation({ isUpdateOperation: false }));
    dispatch(ShowUpsertPositionModal({ upsertPositionModalIsShown: true }));
  };
  return (
    <div>
      {isLoading && <Overlay />}

      <Form>
        <Row className={"justify-content-between mx-0"} style={{ height: "42px" }}>
          <Col className={"align-content-center"}>
            {/*<FloatingLabel controlId={"floatingPositionDescription"} label={"ara"}>*/}
            <Form.Control
              placeholder={t("searchPositionLabel")}
              className={"h-100 default-text-input white-bg"}
              onChange={(e) => {
                setSearchInput(e.target.value);
              }}
            ></Form.Control>
          </Col>
          <Col xs={"auto"} className={"align-content-center"}>
            <Button className={"button secondary"} onClick={HandleCreateClick}>
              <Row>
                <Col xs={"auto"} className={"align-content-center"}>
                  <IconSvg icon={"plusV2"} height={22} width={22}></IconSvg>
                </Col>
                <Col xs={"auto"} className={"text-20 fw-medium"}>
                  {t("createNewPositionButtonLabel")}
                </Col>
              </Row>
            </Button>
          </Col>
        </Row>
      </Form>

      <ScrollableCard>
        <PositionList positionList={FilteredPositionList()}></PositionList>
      </ScrollableCard>
      <UpsertPositionModal companyId={companyId}></UpsertPositionModal>
    </div>
  );
}
