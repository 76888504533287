import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import Table from "react-bootstrap/Table";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";

import DemoResultItem from "./DemoResultItem";
import { getDemoResults, removeTests } from "../../Store/ApplicationResultsThunk";
import { selectTests, uncheckAllTests } from "../../Store";
import usePagination from "../../../../../Hooks/usePagination";
import UIPagination from "../../../../../UI/UIPagination/UIPagination";
import IconSvg from "../../../../AppMenu/Components/IconSvg";

export default function DemoResultsTable() {
  const dispatch = useDispatch();
  const demoResults = useSelector((state) => state.applicationResults.filteredDemoResults);

  const itemsPerPage = 10;

  const { currentItems } = usePagination(demoResults || [], itemsPerPage);

  useEffect(() => {
    dispatch(getDemoResults());
  }, []);

  const handleSelectAllItems = (event) => {
    if (event.target.checked) {
      const preparedValue = currentItems.map((item) => item.id);

      dispatch(selectTests(preparedValue));
    } else {
      dispatch(uncheckAllTests());
    }
  };

  const handleDeleteTests = () => {
    dispatch(removeTests());
  };

  return (
    <div>
      <div className="table-control">
        <div className="table-select-all">
          <InputGroup.Checkbox className="select-all-checkbox" onChange={handleSelectAllItems} />
          <p>Hepsini Seç</p>
        </div>
        <Button className="table-remove-items-button" variant="danger" onClick={handleDeleteTests}>
          <IconSvg icon="trash-can" fill="#fff" />
        </Button>
      </div>
      <Table striped bordered hover variant="dark">
        <thead>
          <tr>
            <th>Seç</th>
            <th>Id</th>
            <th>İsim</th>
            <th>Mail</th>
            <th>Cinsiyet</th>
            <th>TestId</th>
            <th>Başlangıç</th>
            <th>Bitiş</th>
            <th>Süre</th>
            <th>Excel</th>
            <th>Pdf</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((test, id) => (
            <DemoResultItem test={test} key={id} />
          ))}
        </tbody>
      </Table>
      <UIPagination items={demoResults} itemsPerPage={10} />
    </div>
  );
}
