import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import ApiHelper from "../../../Helpers/ApiHelper";

import { ChecboxesImage } from "./Questions/ChecboxesImage";
import { PolarLikertSlider } from "./Questions/PolarLikertSlider";
import { ApplicationInfo } from "../SharedInfoPages/ApplicationInfo";
import Overlay from "../../SharedComponents/Overlay";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import AnsweringPageWrapper from "../SharedComponents/AnsweringPageWrapper";
import { SetSessionId } from "./Store";

const MySwal = withReactContent(Swal);

export function B2bInventory(props) {
  const user = useSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { ticketId } = useParams();
  const { invId } = useParams();

  const [isOverlayVisible, setIsOverlayVisible] = useState(true);

  const [isInfoShowed, setIsInfoShowed] = useState();
  const [isCheckboxesFinished, setIsCheckboxesFinished] = useState(false);
  const [finishedTestData, setFinishedTestData] = useState(false);

  const [applicationData, setApplicationData] = useState({
    questions: null,
    checkboxes: null,
    invId: 0,
    answers: null,
    applicationInfo: null,
  });

  const [postData, setPostData] = useState({
    checkboxAnswersJson: [],
    questionAnswersJson: [],
  });

  useEffect(() => {
    document.getElementById("login")?.remove();
    if (invId && ticketId) {
      GetInventoryImageAndHandleClickCounter();
    } else {
      setIsOverlayVisible(false);
      MySwal.fire({
        title: <strong>Hata</strong>,
        html: <i>Bozuk Link</i>,
        icon: "error",
      });
      return;
    }
  }, []);

  let GetInventoryImageAndHandleClickCounter = () => {
    ApiHelper.getAsync(
      "ticket/GetInventoryImageAndStartSession",
      { ticketId, invId },
      function (result) {
        setIsOverlayVisible(false);
        if (result.data.statusCode === 1) {
          HandleApplicationData(result.data.data);
        } else {
          MySwal.fire({
            title: <strong>Hata</strong>,
            html: <i>{result.data.data}</i>,
            icon: "error",
          });
        }
      },
      user.token
    );
  };

  let HandleApplicationData = (ticket) => {
    dispatch(SetSessionId(ticket?.currentSessionId));
    const applicationData = {
      invName: ticket?.applicationContent?.name,
      invId: ticket?.applicationContent?.id,
      inventoryResultId: ticket?.applicationContent?.resultId,
      questions: JSON.parse(ticket?.applicationContent?.questionsJson),
      checkboxes: JSON.parse(ticket?.applicationContent?.checkboxQuestionsJson),
      answers: JSON.parse(ticket?.applicationContent?.answersJson),
      sessionId: ticket?.currentSessionId,
      ticketId: ticket?.ticketId,
      clickCounter: ticket?.clickCounter,
      allowPdf: ticket?.allowPdf,
      applicationInfo: {
        infoHtml: ticket?.applicationContent?.infoHtml,
        infoTitle: ticket?.applicationContent?.infoTitle,
      },
    };
    setApplicationData(applicationData);
    if (applicationData.checkboxes === null || ticket?.applicationContent?.isCheckboxFinished)
      setIsCheckboxesFinished(true);
  };

  // let GoBackToCheckboxes = () => {
  //   setIsCheckboxesFinished(false);
  // };

  let FinishCheckboxes = (checkboxAnswersJson) => {
    setIsCheckboxesFinished(true);
    if (!applicationData?.questions?.length) {
      PostFinishedInventory(null, checkboxAnswersJson);
    } else {
      setPostData((pd) => ({ ...pd, checkboxAnswersJson }));
      UpdateCheckboxesJson(checkboxAnswersJson, true);
    }
  };

  let FinishInfo = () => {
    setIsInfoShowed(true);
  };

  let DismissFinish = (questionAnswersJson) => {
    setPostData((pd) => ({ ...pd, questionAnswersJson }));
  };

  let FinishAnsweringInventory = (questionAnswersJson) => {
    PostFinishedInventory(questionAnswersJson);
  };

  let UpdateQuestionsJson = async (questionAnswersJson) => {
    ApiHelper.postAsync(
      "ticket/UpdateInvQuestionsJson",
      {
        id: applicationData.inventoryResultId,
        json: JSON.stringify(questionAnswersJson),
      },
      function (result) {},
      user.token
    );
  };

  let UpdateCheckboxesJson = async (checkboxAnswersJson, isFinished) => {
    let postObj = {
      id: applicationData.inventoryResultId,
      json: JSON.stringify(checkboxAnswersJson),
      isFinished: isFinished,
    };

    ApiHelper.postAsync("ticket/UpdateCheckboxesJson", postObj, null, user.token);
  };

  let PostFinishedInventory = async (questionAnswersJson, checkboxAnswersJson) => {
    setIsOverlayVisible(true);

    let finishingInventoryPostObj = {
      ticketId: ticketId,
      sessionId: applicationData.sessionId,
      InventoryResultId: applicationData.inventoryResultId,
      checkboxAnswersJson: checkboxAnswersJson && JSON.stringify(checkboxAnswersJson),
      questionAnswersJson: questionAnswersJson && JSON.stringify(questionAnswersJson),
    };

    ApiHelper.postAsync(
      "ticket/FinishInventoryApplication",
      finishingInventoryPostObj,
      function (result) {
        setIsOverlayVisible(false);
        if (result?.data?.statusCode === 1) {
          if (applicationData?.allowPdf) navigate("/iresult/" + applicationData.inventoryResultId);
          //todo else show thanks message
        } else {
          MySwal.fire({
            title: <strong>Hata</strong>,
            html: <i>Bir problem olustu: {result.data.data} </i>,
            icon: "error",
          });
        }
      },
      user.token
    );
  };

  let HandleFinishButton = () => {
    PostFinishedInventory(postData.questionAnswersJson);
  };

  let HandleShowThanks = () => {
    if (finishedTestData && (!props.b2c || !applicationData?.allowPdf)) return true;
    else return false;
  };

  return (
    <AnsweringPageWrapper>
      {isOverlayVisible && <Overlay />}

      {applicationData && applicationData.invId !== null && applicationData.invId !== "" && !isInfoShowed && (
        <ApplicationInfo
          Finish={FinishInfo}
          applicationInfo={applicationData.applicationInfo}
          clickCounter={applicationData?.inventory?.clickCounter}
        />
      )}

      {isInfoShowed && !isCheckboxesFinished && applicationData && applicationData.checkboxes && (
        <ChecboxesImage
          q={postData.checkboxAnswersJson?.length > 0 ? postData.checkboxAnswersJson : applicationData.checkboxes}
          Finish={FinishCheckboxes}
          isOnlyChecklist={!applicationData?.questions?.length}
          Update={UpdateCheckboxesJson}
        />
      )}
      {!finishedTestData &&
        isInfoShowed &&
        isCheckboxesFinished &&
        applicationData &&
        applicationData.questions?.length && (
          <PolarLikertSlider
            isB2b={true}
            questions={applicationData.questions}
            answers={applicationData.answers}
            finish={FinishAnsweringInventory}
            dismissFinish={DismissFinish}
            update={UpdateQuestionsJson}
            // goBack={GoBackToCheckboxes}
            isFinished={!finishedTestData && postData.questionAnswersJson}
            HandleFinishButton={HandleFinishButton}
          />
        )}

      {HandleShowThanks() && <h1 className="thank-message"> Katılımınız için teşekkürler </h1>}
      {window.innerWidth <= 500 && <div style={{ height: "200px", width: "100%" }}></div>}
    </AnsweringPageWrapper>
  );
}
