import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { getDashboardData } from "../Store/Thunk";
import Overlay from "../../../SharedComponents/Overlay";
import { AppCard } from "./AppCard/AppCard";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DemographyForm from "../../Demography";

export default function UserDash() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.userDash.isLoading);
  const demographyFormIsShown = useSelector((state) => state.selectedCandidateDemography.isShown);
  useEffect(() => {
    dispatch(getDashboardData());
  }, []);

  return (
    <Row className={"mx-0"}>
      <Col>
        {isLoading && <Overlay />}
        {demographyFormIsShown && <DemographyForm></DemographyForm>}
        <AppCard />
      </Col>
    </Row>
  );
}
