import { createSlice } from "@reduxjs/toolkit";
import { getDashboardData } from "./Thunk";

export const userDash = createSlice({
  name: "UserDash",
  initialState: {
    isLoading: false,
    candidateData: [],
    startInfoModalIsShown: false,
  },
  reducers: {
    SetStartInfoModalIsShown(state, action) {
      state.startInfoModalIsShown = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDashboardData.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getDashboardData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.candidateData = action.payload;
    });
  },
});

export const { SetStartInfoModalIsShown } = userDash.actions;

export default userDash.reducer;
