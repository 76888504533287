import { useEffect, useRef, useState } from "react";
import CautionModal from "./CautionModals/CautionModal";
import { useSelector } from "react-redux";
import { BaseApiUrl } from "../../../Helpers/ApiHelper";
import { AnsweringApplicationApiUrls } from "../AnswerInventoryApp/API/AnsweringApplicationAPI";

const AnsweringPageWrapper = ({ children }) => {
  const sessionId = useSelector((state) => state.answeringApplication.sessionId);
  const sessionIdRef = useRef(null);
  sessionIdRef.current = sessionId;

  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isPageVisible, setIsPageVisible] = useState(false);
  const [tabSwitchCount, setTabSwitchCount] = useState(0);
  const [pageVisibilityWarning, setPageVisibilityWarning] = useState(false);
  const [tabSwitchWarning, setTabSwitchWarning] = useState(false);
  const [mouseOutWarning, setMouseOutWarning] = useState(false);
  const [extraScreens, setExtraScreens] = useState(false);

  const [warningObject, setWarningObject] = useState({});

  useEffect(() => {
    setWarningObject({
      fullscreen: !isFullscreen,
      tabSwitch: tabSwitchWarning,
      mouseOut: mouseOutWarning,
      pageVisibility: pageVisibilityWarning,
      extraScreen: extraScreens,
    });

    const beaconObj = JSON.stringify({
      id: sessionIdRef.current,
    });
    //todo beacon test
    // document.addEventListener("visibilitychange", function logData() {
    //   console.log(BaseApiUrl + AnsweringApplicationApiUrls.finishSessionBeacon);
    //   console.log(beaconObj);
    //   if (document.visibilityState === "hidden") {
    //     navigator.sendBeacon(BaseApiUrl + AnsweringApplicationApiUrls.finishSessionBeacon, beaconObj);
    //   }
    // });
  }, [isFullscreen, tabSwitchWarning, mouseOutWarning, pageVisibilityWarning, extraScreens]);

  useEffect(() => {
    if (tabSwitchCount > 0) setTabSwitchWarning(true);
  }, [tabSwitchCount]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsPageVisible(!document.hidden);
      if (document.hidden) {
        setTabSwitchCount((prevState) => prevState + 1);
        setPageVisibilityWarning(true);
      }
    };

    const checkFullscreenStatus = () => {
      setIsFullscreen(document.fullscreenElement);
    };

    const handleBeforeUnload = (event) => {
      // Standard message for confirmation dialogs
      console.log(sessionIdRef.current);

      const beaconObj = {
        id: sessionIdRef.current,
      };

      if (sessionIdRef.current) {
        navigator.sendBeacon(BaseApiUrl + AnsweringApplicationApiUrls.finishSession, beaconObj); // to reliably send the data while page is closing
      }

      return sessionIdRef.current; // This part triggers the browser alert
    };
    const handleMouseOut = (event) => {
      // Check if the user is exiting the viewport
      if (
        event.clientY <= 0 || // Moving out of the top edge
        event.clientX <= 0 || // Moving out of the left edge
        event.clientX >= window.innerWidth || // Moving out of the right edge
        event.clientY >= window.innerHeight // Moving out of the bottom edge
      ) {
        setMouseOutWarning(true);
      }
    };

    const handleKeyDown = (event) => {
      // Detect F11 keypress (keyCode 122 or "F11")
      if (event.key === "F11" || event.keyCode === 122) {
        event.preventDefault(); // Prevent default F11 browser behavior
        enterFullscreen();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("fullscreenchange", checkFullscreenStatus); // Detect F11
    document.addEventListener("visibilitychange", handleVisibilityChange);
    window.addEventListener("beforeunload", handleBeforeUnload);
    document.addEventListener("mouseout", handleMouseOut);

    checkFullscreenStatus(); //initial check
    handleVisibilityChange();

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("fullscreenchange", checkFullscreenStatus); // Detect F11
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("beforeunload", handleBeforeUnload);
      document.removeEventListener("mouseout", handleMouseOut);
    };
  }, []);

  useEffect(() => {
    // Initial detection
    detectExtraScreens();

    // Poll every 1 second
    const interval = setInterval(detectExtraScreens, 1000);

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  const detectExtraScreens = () => {
    setExtraScreens(window.screen.isExtended);
  };

  const enterFullscreen = () => {
    const element = document.documentElement; // Default to the document if no element is specified
    if (element.requestFullscreen) {
      element.requestFullscreen().catch(() => alert("Lütfen f11 e basın"));
    } else {
      console.error("Fullscreen API is not supported by this browser.");
    }
  };

  const handleDismiss = () => {
    enterFullscreen();
    setTabSwitchWarning(false);
    setMouseOutWarning(false);
    setPageVisibilityWarning(false);
  };

  const shouldShowWarning =
    !isFullscreen || tabSwitchWarning || mouseOutWarning || pageVisibilityWarning || extraScreens;

  const isWarningShown = Object.values(warningObject).some((value) => value === true);

  return (
    <>
      {(!window.isDev || isWarningShown) && (
        <CautionModal
          isShown={isWarningShown}
          warningObject={warningObject}
          handleDismiss={handleDismiss}
          extraScreens={extraScreens}
        ></CautionModal>
      )}

      {children}
    </>
  );
};
export default AnsweringPageWrapper;
