import React from "react";
import Image from "react-bootstrap/Image";
import ApiHelper from "../../../../Helpers/ApiHelper";
import CreateQR from "../../../SharedComponents/CreateQR";

export default function CoverSection({ imageName, demography, appName, qrURL }) {
  //792px X 1120px one page size
  let style = {
    height: "1123px",
    width: "792px",
    margin: 0,
    padding: 0,
  };

  let background = {
    position: "absolute",
    top: "0mm",
    left: "0mm",
    height: "1123px",
    zIndex: "-100",
    width: "792px",
    margin: "0mm",
    padding: "0mm",
  };

  return (
    <div style={style}>
      <Image crossOrigin="anonymous" style={background} src={ApiHelper.baseURL + "wwwroot/info/" + imageName} />
      <div className="_cover-page-candidate-data-container">
        <CreateQR size={128} value={qrURL} />
        <h3> {appName} </h3>
        <p>
          İsim :<span className="_cover-page-candidate-data-span">{demography?.name}</span>
        </p>
        <p>
          E-posta:
          <span className="_cover-page-candidate-data-span">{demography?.email}</span>
        </p>
        <p>
          Doğum yılı:
          <span className="_cover-page-candidate-data-span">{demography?.birthYear}</span>
        </p>
        <p>
          Cinsiyet:
          <span className="_cover-page-candidate-data-span">{demography?.gender}</span>
        </p>
        <p>
          Okul:
          <span className="_cover-page-candidate-data-span">{demography?.school}</span>
        </p>
        <p>
          İş tecrübesi:
          <span className="_cover-page-candidate-data-span">{demography?.experience}</span>
        </p>
      </div>
    </div>
  );
}
