import { createAsyncThunk } from "@reduxjs/toolkit";

import LocalStorage from "../../../../Services/Storage/LocalStorage";
import StorageModel from "../../../../Models/StorageModel";
import HttpsClient from "../../../../Services/HttpsClient";
import ApiHelper from "../../../../Services/ApiHelper";
import Cache from "../../../../Services/Cache";
import DemographyFormAPI, { DemographyFormApiUrls } from "../API/DemographyFormAPI";

const storage = new LocalStorage();
const storageModel = new StorageModel(storage);
const cache = new Cache(storageModel);

const httpsClient = new HttpsClient();
const apiHelper = new ApiHelper(httpsClient);
const demographyFormAPI = new DemographyFormAPI(apiHelper, cache);

export const getDemographyByCandidateId = createAsyncThunk(
  DemographyFormApiUrls.getDemographyByCandidateId,
  async (payload, { getState, dispatch }) => {
    const state = getState();
    const token = state.auth.user.token;

    return await demographyFormAPI.getDemographyByCandidateId(payload, token);
  }
);

export const updateDemography = createAsyncThunk(
  DemographyFormApiUrls.updateDemography,
  async (payload, { getState, dispatch }) => {
    const state = getState();
    const token = state.auth.user.token;
    const response = await demographyFormAPI.updateDemography(payload, token, true, true);

    return await response;
  }
);
