import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import React from "react";

const DemographicInfoColumn = ({ candidate }) => {
  return (
    <Row className={"justify-content-evenly"}>
      <Col xs={5} className={"ms-3 fw-medium"}>
        <Row style={{ height: "27px" }}>
          <Col xs={"auto"} className={"px-0 align-content-center text-16 text-color-gray-light"}>
            Cinsiyet:
          </Col>
          <Col xs={"auto"} className={"px-1 align-content-center text-18 text-color-gray-dark  "}>
            {candidate?.gender}
          </Col>
        </Row>

        <Row style={{ height: "27px" }}>
          <Col xs={"auto"} className={"px-0 align-content-center text-16 text-color-gray-light"}>
            Okul:
          </Col>
          <Col xs={"auto"} className={"px-1 align-content-center text-18 text-color-gray-dark "}>
            {candidate?.school}
          </Col>
        </Row>

        <Row style={{ height: "27px" }}>
          <Col xs={"auto"} className={"px-0 align-content-center text-16 text-color-gray-light"}>
            Doğum tarihi:
          </Col>
          <Col xs={"auto"} className={"px-1 align-content-center text-18 text-color-gray-dark "}>
            {candidate?.birthYear}
          </Col>
        </Row>

        <Row style={{ height: "27px" }}>
          <Col xs={"auto"} className={"px-0 align-content-center text-16 text-color-gray-light"}>
            Tecrübe:
          </Col>
          <Col xs={"auto"} className={"px-1 align-content-center text-18 text-color-gray-dark"}>
            {candidate?.experience}
          </Col>
        </Row>
      </Col>
      <Col xs={5} className={"ms-3 fw-medium"}>
        <Row style={{ height: "27px" }}>
          <Col xs={"auto"} className={"px-0 align-content-center text-16 text-color-gray-light"}>
            Fakülte:
          </Col>
          <Col xs={"auto"} className={"px-1 align-content-center text-18 text-color-gray-dark  "}>
            {candidate?.faculty}
          </Col>
        </Row>

        <Row style={{ height: "27px" }}>
          <Col xs={"auto"} className={"px-0 align-content-center text-16 text-color-gray-light"}>
            Departman:
          </Col>
          <Col xs={"auto"} className={"px-1 align-content-center text-18 text-color-gray-dark "}>
            {candidate?.department}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default DemographicInfoColumn;
