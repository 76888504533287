import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import Overlay from "../../../SharedComponents/Overlay";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ApplicationCardList from "./Applications/ApplicationCardList";
import Notification from "./Notifications/Notification";
import Statistics from "./Statistics/Statistics";
import { getCompanyStatistics } from "../Store/Thunk";
// import Statistics from "./Statistics/Statistics";

const AdminDash = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.auth.user?.companyUser?.company?.companyId);

  const isLoading = useSelector((state) => state.adminDash.isLoading);

  useEffect(() => {
    debugger;
    dispatch(getCompanyStatistics({ companyId }));
  }, []);

  return (
    <>
      {isLoading && <Overlay />}
      <Row style={{ marginTop: "-25px" /* for the inner carousel padding*/ }}>
        <Col className={"px-0"}>
          <ApplicationCardList />
        </Col>
      </Row>

      <Row className={"mx-0"}>
        <Col xs={9}>
          <Row className={"mb-3 text-14 text-color-gray-light"}>
            <Col xs={"auto"} className={"align-content-center"}>
              BİLDİRİMLER
            </Col>
          </Row>
        </Col>
        <Col xs={3}>
          <Row className={"mb-3 text-14 text-color-gray-light justify-content-center"}>
            <Col xs={"auto"} className={"align-content-center"}>
              TAMAMLANAN DEĞERLEMELER
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className={"mx-0"}>
        <Col xs={12} lg={9}>
          <Notification></Notification>
        </Col>
        <Col xs={12} lg={3}>
          <Statistics></Statistics>
        </Col>
      </Row>
    </>
  );
};
export default AdminDash;
