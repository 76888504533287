import { Button, Col, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import React from "react";
import ScrollableCard from "../../../../SharedComponents/ScrollableCard";
import NotificationListRow from "./NotificationListRow";
import { PushToAlertArray } from "../../../../SharedComponents/Alerts/Store";
import { useDispatch, useSelector } from "react-redux";

const Notification = () => {
  const dispatch = useDispatch();
  const last3DaysApplications = useSelector((state) => state.adminDash.companyStatistics?.last3DaysApplications);

  const reversedLast3DaysApplications = last3DaysApplications?.slice().reverse();

  return (
    <Card className={"default-list with-shadow pb-3"}>
      <Card className={"header"}>
        <Row className={" text-20 text-color-gray-light fw-medium"} style={{ height: "50px" }}>
          <Col xs={{ span: 3, offset: 1 }} className={"align-content-center"}>
            Ad - Soyad
          </Col>
          <Col xs={4} className={"align-content-center"}>
            <Row className={"justify-content-center"}>Durum</Row>
          </Col>
          <Col xs={{ span: 3, offset: 1 }} className={"align-content-center"}>
            <Row className={"mx-0 "}>Zaman</Row>
          </Col>
        </Row>
      </Card>
      <ScrollableCard top={0} bottom={40} emptySpaceHeight={50}>
        <Card className={"list-row"}>
          {reversedLast3DaysApplications?.map((notification, i) => {
            return <NotificationListRow key={i} notification={notification}></NotificationListRow>;
          })}
          <Button
            onClick={() => {
              dispatch(PushToAlertArray({ text: "new text to try it out", type: "success" }));
            }}
          ></Button>
        </Card>
      </ScrollableCard>
    </Card>
  );
};
export default Notification;
