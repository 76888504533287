import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Overlay from "../../SharedComponents/Overlay";
import { finishSession } from "../AnswerInventoryApp/Store/Thunk";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

export default function AnsweringPageFooter(props) {
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const sessionId = useSelector((state) => state.answeringApplication.sessionId);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function timeout(delay) {
    return new Promise((res) => setTimeout(res, delay));
  }

  const FinishSession = () => {
    dispatch(finishSession({ id: sessionId }));
    navigate("/userDash");
  };

  return (
    <>
      {isOverlayVisible && <Overlay text="Lütfen Bekleyiniz" />}
      <div
        style={{
          position: !props.isRelative ? "fixed" : "relative",
          bottom: "0",
          right: "0",
          width: "100%",
          height: window.innerWidth > 500 ? "80px" : "60px",
          borderTop: window.innerWidth > 500 && "1px solid #CECECE",
          background: window.innerWidth > 500 && "#FFF",
          borderRadius: props.isBorderRadius && "16px",
        }}
      >
        <Container>
          <Row className={"justify-content-center"} style={{ paddingTop: "16px" }}>
            {props.prevButtonFunction && (
              <>
                <Row className={"justify-content-center"}>
                  <Col>
                    <Row></Row>
                  </Col>
                  <Col xs={"auto"}>
                    <Row>
                      <Col xs={"auto"}>
                        <Button onClick={() => props.prevButtonFunction(false)} className="footer-prev-button">
                          {props.prevButtonText}
                        </Button>
                      </Col>
                      <Col xs={"auto"}>
                        <Button
                          variant="warning"
                          onClick={() => props.nextButtonFunction(false)}
                          className="footer-next-button"
                          disabled={props.isNextDisabled}
                        >
                          {props.nextButtonText}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Row className={"justify-content-end h-100"}>
                      <Col xs={"auto"}>
                        <Button
                          variant="warning"
                          className={"h-100"}
                          onClick={() => {
                            FinishSession();
                          }}
                        >
                          Kaydet ve Çık
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            )}
            {props.onClickFunction && (
              <Col
                md={window.innerWidth > 500 ? { span: 3, offset: 9 } : { span: 4, offset: 4 }}
                onMouseDown={() => props.onMouseDownFunction && props.onMouseDownFunction(true)}
              >
                <Button
                  className={"footer-next-button animate__animated   animate__fadeInUp  " + props.buttonClassName}
                  onClick={() => props.onClickFunction(props.data)}
                  disabled={props.isDisabled}
                  variant="warning"
                >
                  {props.buttonText}
                </Button>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </>
  );
}
