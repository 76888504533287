import React, { useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import BreadCrumb from "../../BreadCrumb";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Card from "react-bootstrap/Card";
import Badge from "react-bootstrap/Badge";

import ApiHelper from "../../../../Helpers/ApiHelper";
import Overlay from "../../../SharedComponents/Overlay";
import Swal from "sweetalert2";

import withReactContent from "sweetalert2-react-content";
import { useTranslation } from "react-i18next";
import ScrollableCard from "../../../SharedComponents/ScrollableCard";
import { useSelector } from "react-redux";

const MySwal = withReactContent(Swal);

export function ReorderCheckboxes() {
  const { t } = useTranslation();
  const { state } = useLocation();
  const [showOverlay, setShowOverlay] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [newOrder, setNewOrder] = useState("");
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    let questionArr = state.checkboxes;

    let unOrderedOnes = questionArr.filter((x) => {
      return x.order === 0;
    });
    let orderedOnes = questionArr.filter((x) => {
      return x.order !== 0;
    });
    orderedOnes.sort(function (a, b) {
      return a.order - b.order;
    });
    const lastOrder = orderedOnes.length !== 0 ? orderedOnes[orderedOnes.length - 1].order : 0;

    for (let i = 0; i < unOrderedOnes.length; i++) {
      unOrderedOnes[i].order = lastOrder + i + 1;
      unOrderedOnes[i].unOrdered = true;
    }
    questionArr.sort(function (a, b) {
      return a.order - b.order;
    });
    setQuestions(questionArr);
  }, []);

  const ChangeOrder = (prevOrder) => {
    var _newOrder = parseInt(newOrder);
    if (_newOrder > questions.length || _newOrder === prevOrder || _newOrder <= 0) return;

    let newList = JSON.parse(JSON.stringify(questions));
    let entity = newList.find((q) => q.order === prevOrder);
    newList = newList.filter((x) => x.order != prevOrder);
    newList.forEach((x, i) => (x.order = i + 1));

    let firstHalf = [];
    let secondHalf = [];
    newList = JSON.parse(JSON.stringify(newList));
    newList.forEach((x, i) => {
      if (i + 1 < _newOrder) firstHalf.push(x);
      else {
        x.unOrdered = true;
        secondHalf.push(x);
      }
    });

    entity.unOrdered = true;
    firstHalf.push(entity);

    firstHalf = firstHalf.concat(secondHalf);
    let resultList = JSON.parse(JSON.stringify(firstHalf));
    resultList.forEach((x, i) => {
      x.order = i + 1;
    });
    setNewOrder("");
    setQuestions(resultList);
  };

  const HandleCheckboxQuestionRow = (question, i) => {
    return (
      <Card key={i}>
        <Card.Body style={{ padding: "5px 15px" }}>
          <Row xs="auto" className="mb-2">
            <Col>Id: Alt{question.id} </Col>
            <Col>{question.scope} </Col>
            {question.subScope && <Col>{question.subScope}</Col>}
            {question.isManipulation && (
              <Col>
                <Badge bg="info">Manipülasyon</Badge>
              </Col>
            )}
            {question.isConsistency && (
              <Col>
                <Badge bg="warning">Tutarlılık</Badge>
              </Col>
            )}
          </Row>
          <Row xs="auto">
            <Col xs={12} md={8}>
              <span style={{ fontWeight: "bold" }}> {question.checkbox.questionText} </span>
            </Col>
            <Col xs={12} md={2}>
              <h3>
                {question.order} {question.unOrdered && <span style={{ color: "red" }}>**</span>}
              </h3>
            </Col>
            <Col xs={12} md={2}>
              <InputGroup size="sm" className="mb-3">
                <Button variant="primary" size="sm" onClick={() => ChangeOrder(question.order)}>
                  Değiştir
                </Button>
                <Form.Control
                  type="number"
                  min="1"
                  max={questions.length}
                  step="1"
                  placeholder={question.order}
                  onChange={(e) => {
                    setNewOrder(e.target.value);
                  }}
                  onClick={(e) => {
                    e.target.value = "";
                  }}
                />
              </InputGroup>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  };

  const SimplifyQuestions = () => {
    let simplifiedArr = [];
    questions.forEach((q) => {
      simplifiedArr.push({
        scopeId: q.scopeId,
        subScopeId: q.subScopeId,
        questionId: q.id,
        order: q.order,
        cboxId: q.checkbox.id,
        text: q.checkbox.questionText,
      });
    });
    return simplifiedArr;
  };

  const CreatePostObjThenSaveToDb = () => {
    setShowOverlay(true);
    let ordersAndIds = [];

    questions.forEach((q) => {
      if (q.unOrdered) {
        ordersAndIds.push({ Id: q.id, Order: q.order });
      }
    });

    PostQuestionsImageJson({
      id: state.inventory.id,
      ordersAndIds,
      CheckboxQuestionsJson: JSON.stringify(SimplifyQuestions(questions)),
      CheckboxQuestionNumber: questions.length,
    });
  };

  const PostQuestionsImageJson = (postObj) => {
    ApiHelper.postAsync(
      "question/SaveCheckboxesImage",
      postObj,
      function (result) {
        setShowOverlay(false);
        if (result.data.statusCode === 1) {
          MySwal.fire({
            title: <strong>{t("Sıralama kaydedildi")}</strong>,
            html: <i>{t("successAlertHeading")}</i>,
            icon: "success",
            confirmButtonText: t("okay"),
            confirmButtonColor: "#0d6efd",
          }).then(function (res) {
            navigate("/reorder", { state: state.inventory });
          });
        } else MySwal.fire(<p>Bir hata olustu </p>);
      },
      user.token
    );
  };

  const DeleteOrders = () => {
    if (!state.inventory?.questionsImage?.id) return alert("bir hata oluştu");
    setShowOverlay(true);
    var ids = questions.map((q) => q.id);

    ApiHelper.postAsync(
      "question/DeleteCheckboxesImage",
      {
        QuestionIds: ids,
        id: state.inventory.questionsImage.id,
      },
      function (result) {
        setShowOverlay(false);
        if (result.data.statusCode === 1) {
          MySwal.fire({
            title: <strong>{t("Sıralama silindi")}</strong>,
            html: <i>{t("successAlertHeading")}</i>,
            icon: "success",
            confirmButtonText: t("okay"),
            confirmButtonColor: "#0d6efd",
          }).then(function (res) {
            navigate("/inventory");
          });
        } else MySwal.fire(<p>Bir hata olustu </p>);
      },
      user.token
    );
  };

  return (
    <div>
      {showOverlay && <Overlay />}
      <BreadCrumb
        pageName="Checkbox Sıralama"
        backPageName="Sıralama"
        backUrl="/reorder"
        backState={{ state: state.inventory }}
      >
        <Row xs="auto">
          <Col xs="12" md="5">
            <h4>Envanter adı : {state.inventory.name}</h4>
          </Col>
          <Col xs="12" md="3">
            <h4>{questions.length} adet soru</h4>
          </Col>
          <Col xs="4">
            <Row xs="auto" className="justify-content-md-end">
              <Col>
                <Button variant="primary" onClick={CreatePostObjThenSaveToDb}>
                  Kaydet
                </Button>
              </Col>
              {state.inventory.questionsImage && (
                <Col>
                  <Button variant="danger" onClick={DeleteOrders}>
                    Mevut Kaydı Sil
                  </Button>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </BreadCrumb>
      <ScrollableCard>{questions && questions.map((x, i) => HandleCheckboxQuestionRow(x, i))}</ScrollableCard>
    </div>
  );
}
