import { createSlice } from "@reduxjs/toolkit";

export const alerts = createSlice({
  name: "alerts",
  initialState: {
    totalAlertCount: 0,

    defaultAlertData: {
      isShown: false,
      text: "",
    },

    alertArray: [],
  },
  reducers: {
    UpdateDefaultAlertData(state, action) {
      state.defaultAlertData = action.payload;
    },
    PushToAlertArray(state, action) {
      state.alertArray.push({ ...action.payload, id: state.totalAlertCount }); //type, text,
      state.totalAlertCount += 1; // surely this is safe ?
    },
    RemoveFromAlertArrayById(state, action) {
      const alertId = action.payload;
      state.alertArray = state.alertArray.filter((alert) => alert.id !== alertId);
    },
  },
  extraReducers: (builder) => {},
});

export const { UpdateDefaultAlertData, PushToAlertArray, RemoveFromAlertArrayById } = alerts.actions;

export default alerts.reducer;
