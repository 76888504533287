import { createAsyncThunk } from "@reduxjs/toolkit";

import HttpsClient from "../../../../../../Services/HttpsClient";
import ApiHelper from "../../../../../../Services/ApiHelper";
import MonoTextuaryAPI, { MonoTextuaryApiUrls } from "../API/MonoTextuaryAPI";

const httpsClient = new HttpsClient();
const apiHelper = new ApiHelper(httpsClient);
const monoTextuaryAPI = new MonoTextuaryAPI(apiHelper);

export const createMonoTextuary = createAsyncThunk(
  MonoTextuaryApiUrls.createMonoTextuary,
  async (payload, { getState, dispatch }) => {
    const state = getState();
    const token = state.auth.user.token;
    await monoTextuaryAPI.createMonoTextuary(payload, token);
    dispatch(
      getMonoTextuaries(
        {
          id: state.monoTextuary.scopeId,
          isSub: state.monoTextuary.isSub,
        },
        token
      )
    );
  }
);

export const getMonoTextuaries = createAsyncThunk(
  MonoTextuaryApiUrls.getMonoTextuaries,
  async (payload, { getState, dispatch }) => {
    const state = getState();
    const token = state.auth.user.token;

    let data = await monoTextuaryAPI.getMonoTextuaries(payload, token);
    return data;
  }
);

export const deleteMonoTextuary = createAsyncThunk(
  MonoTextuaryApiUrls.deleteMonoTextuary,
  async (payload, { getState, dispatch }) => {
    const state = getState();
    const token = state.auth.user.token;
    await monoTextuaryAPI.deleteMonoTextuary(payload, token);
    dispatch(
      getMonoTextuaries(
        {
          id: state.monoTextuary.scopeId,
          isSub: state.monoTextuary.isSub,
        },
        token
      )
    );
  }
);

export const updateMonoTextuary = createAsyncThunk(
  monoTextuaryAPI.updateMonoTextuary,
  async (payload, { getState, dispatch }) => {
    const state = getState();
    const token = state.auth.user.token;
    await monoTextuaryAPI.updateMonoTextuary(payload, token);
    dispatch(
      getMonoTextuaries(
        {
          id: state.monoTextuary.scopeId,
          isSub: state.monoTextuary.isSub,
        },
        token
      )
    );
  }
);
