import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { deleteCompanyInfo, deleteImageFromCompanyInfo, getCompanyInfo } from "../Store/Thunk";
import { useNavigate } from "react-router-dom";
import { Button, Col, Form, Row } from "react-bootstrap";
import IconSvg from "../../../AppMenu/Components/IconSvg";
import ApiHelper from "../../../../Helpers/ApiHelper";
import { ChangeCompanyInfoState, ClearCompanyInfoState } from "../Store";
import { CompanyInfoApiUrls } from "../API/AdminCompanyInfoAPI";
import Overlay from "../../../SharedComponents/Overlay";
import Card from "react-bootstrap/Card";
import draftToHtml from "draftjs-to-html";
import { convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { GetValueByKey, imageTypeEnum } from "../../../../Helpers/EnumHelper";

export default function AdminCompanyInfo() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.companyInfo.isLoading);
  const companyInfo = useSelector((state) => state.companyInfo);
  const token = useSelector((state) => state.auth.user.token);

  const role = useSelector((state) => state.auth.user.userRole);
  const adminCompany = useSelector((state) => state.auth.user.companyUser.company);
  const companyId = adminCompany.companyId;
  const companyName = adminCompany.companyName;
  const isUpdateOperation = useSelector((state) => state.companyInfo.isUpdateOperation);

  const [logo, setLogo] = useState(null);
  const [previewLogo, setPreviewLogo] = useState("");

  const [icon, setIcon] = useState(null);
  const [previewIcon, setPreviewIcon] = useState("");

  const [editorState, setEditorState] = useState("");

  useEffect(() => {
    if (companyId) {
      dispatch(ClearCompanyInfoState());
      dispatch(getCompanyInfo({ companyId: companyId }));
    } else {
      navigate("/company");
    }
  }, [companyId]);

  const OnEditorStateChange = (state) => {
    setEditorState(state);
  };

  const HandleChangeEvent = (variableName, input) => {
    dispatch(ChangeCompanyInfoState({ [variableName]: input }));
  };

  const HandleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();

    if (logo) {
      formData.append("logo", logo);
    }
    if (icon) {
      formData.append("icon", icon);
    }

    formData.append("companyId", companyId);

    if (editorState) {
      formData.append("mailContent", draftToHtml(convertToRaw(editorState?.getCurrentContent())));
    }

    ApiHelper.xmlPostFormData(CompanyInfoApiUrls.upsertCompanyInfo, formData, token, (res) => {
      dispatch(getCompanyInfo({ companyId: companyId }));
      alert(JSON.parse(res.response).message);
    });
  };

  const DeleteImage = (type, imageName) => {
    const typeEnum = GetValueByKey(type, imageTypeEnum);
    dispatch(deleteImageFromCompanyInfo({ type: typeEnum, imageName: imageName, companyInfoId: companyInfo.id }));
  };

  return (
    <div>
      {isLoading && <Overlay />}
      <Row className={"mx-0"}>
        <Col>
          <Card className={"default-card no-shadow with-border"}>
            <Row className={"justify-content-center text-24 text-color-gray-dark fw-medium"} style={{ height: "42px" }}>
              <Col xs={"auto"} className={"align-content-center"}>
                {companyName}
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      <Form onSubmit={HandleSubmit}>
        <Row className="justify-content-center my-4 mx-0">
          <Col xs={"auto"}>
            <Card className={"default-card no-shadow position-relative h-100"}>
              {(companyInfo.logoPath || previewLogo) && (
                <>
                  <div className="logo-previev-img-container">
                    <img
                      height="60"
                      alt={"Company Icon"}
                      src={previewLogo ? previewLogo : ApiHelper.baseURL + "wwwroot/logo/" + companyInfo.logoPath}
                      width="200"
                    />
                  </div>
                  <Button
                    size="sm"
                    variant="danger"
                    className={"absolute-top-right"}
                    onClick={() => {
                      setPreviewLogo("");
                      DeleteImage("CompanyLogo", companyInfo.logoPath);
                    }}
                  >
                    <IconSvg icon="trash-can" fill="#fff" />
                  </Button>
                </>
              )}
              <label className="admin-setting-page-add-image-label h-100" style={{ width: "350px" }}>
                <input
                  id="admin-excel-input"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    if (file) {
                      setLogo(file);
                      setPreviewLogo(URL.createObjectURL(file));
                    } else {
                      // Reset state if no file is selected
                      setLogo(null);
                      setPreviewLogo(null);
                    }
                  }}
                  type="file"
                  accept=".jpeg, .png, .webp, .jpg"
                />

                <Row className={"mt-auto justify-content-center"}>
                  <Col xs={"auto"} className={"align-content-center pe-0"}>
                    <IconSvg icon="add-image" />
                  </Col>
                  <Col xs={"auto"} className={"align-content-center"}>
                    {t("uploadLogo")} ( 200x60 )
                  </Col>
                </Row>
              </label>
            </Card>
          </Col>
          <Col xs={"auto"}>
            <Card className={"default-card no-shadow h-100"}>
              {(companyInfo.iconPath || previewIcon) && (
                <>
                  <div className="logo-previev-img-container">
                    <img
                      height="50"
                      alt={"Company Logo"}
                      src={previewIcon ? previewIcon : ApiHelper.baseURL + "wwwroot/logo/" + companyInfo.iconPath}
                      width="50"
                    />
                  </div>
                  <Button
                    size="sm"
                    variant="danger"
                    className={"absolute-top-right"}
                    onClick={() => {
                      setPreviewIcon("");
                      DeleteImage("CompanyIcon", companyInfo.iconPath);
                    }}
                  >
                    <IconSvg icon="trash-can" fill="#fff" />
                  </Button>
                </>
              )}
              <label className="admin-setting-page-add-image-label h-100" style={{ width: "350px" }}>
                <input
                  id="admin-excel-input"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    if (file) {
                      setIcon(file);
                      setPreviewIcon(URL.createObjectURL(file));
                    } else {
                      // Reset state if no file is selected
                      setIcon(null);
                      setPreviewIcon(null);
                    }
                  }}
                  type="file"
                  accept=".jpeg, .png, .webp, .jpg"
                />

                <Row className={"mt-auto justify-content-center"}>
                  <Col xs={"auto"} className={"align-content-center pe-0"}>
                    <IconSvg icon="add-image" />
                  </Col>
                  <Col xs={"auto"} className={"align-content-center"}>
                    {t("uploadIcon")} ( 50x50 )
                  </Col>
                </Row>
              </label>
            </Card>
          </Col>
        </Row>

        <Row className="justify-content-center mx-0">
          <Col>
            <Card>
              <Editor
                editorState={editorState ? editorState : companyInfo.mailContent}
                onEditorStateChange={OnEditorStateChange}
              />
            </Card>
          </Col>
        </Row>

        <Row className={"mx-0 justify-content-center"} style={{ marginTop: "40px" }}>
          <Col xs={"auto"}>
            <Button type={"submit"} className={"button primary"}>
              {t("saveChangesButtonLabel")}
            </Button>
          </Col>
          <Col xs={"auto"}>
            <Button
              variant="danger"
              className={"button secondary danger"}
              onClick={() => {
                dispatch(deleteCompanyInfo({ id: companyId }));
                dispatch(ClearCompanyInfoState());
                setPreviewLogo("");
                setPreviewIcon("");
              }}
            >
              {t("deleteButtonLabel")}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
